import React, {useEffect, useState, useRef} from 'react';
import {Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import ToastPopup from 'view/popup/ToastPopup';

const SuccessPage = () => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);
  const toastTimeoutRef = useRef(null); // `setTimeout`을 추적할 ref

  // ✅ 페이지 이동 함수 (개인 결제 내역 탭으로 이동)
  const gotoMypage = () => {
    navigate('/mypage', {
      replace: true,
      state: {fragmentNum: 2, tabStatus: 3}, // ✅ tabStatus 3 → 개인 결제 내역
    });
  };

  // ✅ 결제 결과 알림 및 페이지 이동 처리
  function handlePayResult(text) {
    setToastText(text);
    setToastVisibility(true);

    // 기존 `setTimeout`이 실행 중이라면 취소
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    // 새로운 `setTimeout` 설정 후 페이지 이동
    toastTimeoutRef.current = setTimeout(() => {
      setToastVisibility(false);
      gotoMypage();
    }, 2500);
  }

  // ✅ 아이템 구매 처리 함수
  const purchaseItem = async (
    user_id,
    item_id,
    paymentKey,
    orderId,
    amount,
    institutionName,
  ) => {
    try {
      const response = await APIUtils.PassBuy(
        user_id,
        item_id,
        'toss',
        paymentKey,
        orderId,
        amount,
        institutionName,
      );

      if (response.data.ret_code === 1000) {
        handlePayResult('결제가 완료되었습니다.');
      } else {
        handlePayResult('결제가 실패하였습니다. 고객센터로 문의해 주세요.');
        console.error('구매 실패:', response.data);
      }
    } catch (err) {
      handlePayResult('결제가 실패하였습니다. 고객센터로 문의해 주세요.');
      console.error('구매 처리 중 에러 발생:', err);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const itemId = urlParams.get('itemId');
    const institutionName = urlParams.get('institutionName');
    const paymentKey = urlParams.get('paymentKey');
    const orderId = urlParams.get('orderId');
    const amount = urlParams.get('amount');
    let user_id = urlParams.get('user_id');

    // ✅ `user_id`가 `null`, `undefined`, 빈 문자열(`''`), 또는 `NaN`일 경우 `authReducer.user_id` 사용
    if (!user_id || isNaN(Number(user_id))) {
      console.warn(
        `유효하지 않은 user_id: "${user_id}", authReducer.user_id 사용`,
      );
      user_id = 1;
    } else {
      user_id = Number(user_id); // ✅ `user_id`가 존재하면 숫자로 변환
    }

    if (itemId) {
      purchaseItem(
        itemId === '50000' ? user_id : authReducer.user_id, // ✅ `50000`일 경우 `user_id` 사용
        itemId,
        paymentKey,
        orderId,
        amount,
        institutionName,
      );
    }
  }, [authReducer.user_id]); // ✅ `authReducer.user_id` 변경 시 실행

  // ✅ `setTimeout` 정리 (컴포넌트 언마운트 시 실행)
  useEffect(() => {
    return () => {
      if (toastTimeoutRef.current) {
        clearTimeout(toastTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Box>
      <ToastPopup text={toastText} visibility={toastVisibility} />
    </Box>
  );
};

export default SuccessPage;
