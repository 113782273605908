import {Box, TextField, Select, MenuItem, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import {StringUtils} from 'common/utils/StringUtils';
import SubmitView from 'view/mypage/SubmitView';
import DatePicker from 'react-datepicker';
import {ko} from 'date-fns/esm/locale';
import ApiLoading from 'view/common/ApiLoading2';

export const FragmentView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [taskCount, setTaskCount] = useState(0);
  const [submitList, setSubmitList] = useState([]);
  const [originalSubmitList, setOriginalSubmitList] = useState([]);
  const countPerPage = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [selectItem, setSelectItem] = useState('과제명');
  const [loading, setLoading] = useState(false);
  const todayDate = new Date();
  const today = new Date();
  const firstDayOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1,
  );
  const [startDate, setStartDate] = useState(firstDayOfLastMonth);
  const [endDate, setEndDate] = useState(today);

  function encodeBase64(input) {
    return btoa(input)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  function clickResult(student_id, task_id) {
    // 새 창에 URL과 쿼리 문자열 포함
    function getRandomChar() {
      const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return chars.charAt(Math.floor(Math.random() * chars.length));
    }

    const encodedParams = encodeBase64(`${task_id}:${student_id}`);

    const randomPrefix = getRandomChar();
    const randomSuffix = getRandomChar();
    const secureEncodedParams = randomPrefix + encodedParams + randomSuffix;

    // const url = `/result3?tx=${task_id}&sx=${student_id}`;
    const url = `/result3?gul=${secureEncodedParams}`;
    window.open(url, '_blank', 'noopener, noreferrer');
  }
  // 공통 버튼 스타일
  const buttonStyle = {
    fontSize: '0.875rem',
    borderRadius: '1.925rem',
    padding: '0.5rem 1rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const ExampleCustomInput = React.forwardRef((props, ref) => (
    <button
      style={{
        backgroundColor: '#F7F8FA',
        border: '0px solid black',
        borderRadius: '0.625rem',
        padding: '0.5rem 1rem',
      }}
      className="custom-input"
      onClick={props.onClick}
      ref={ref}
    >
      {'📅  '}
      {props.value}
      {' ⌵ '}
    </button>
  ));
  ExampleCustomInput.displayName = 'CalandarInput';
  ExampleCustomInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
  };

  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
    setCurrentPage(1);
  };

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function getTeacherSubmitInfo(user_id, start_date, end_date) {
    const getInfo = async (user_id, start_date, end_date) => {
      setLoading(true);
      try {
        const response = await APIUtils.TaskTeacherSubmitList(
          user_id,
          StringUtils.getFormattedString0h(start_date),
          StringUtils.getFormattedString24h(end_date),
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo(user_id, start_date, end_date).then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setOriginalSubmitList(res.data.submit_list);
        setTaskCount(res.data.task_id_list.length);
      }
    });
    setLoading(false);
    setSearchText('');
    setCurrentPage(1);
    setSelectItem('과제명');
    setSelectedIndex(0);
    setSearchText('');
  }

  const handleStartDateChange = newDate => {
    setStartDate(newDate);
  };
  const handleEndDateChange = newDate => {
    setEndDate(newDate);
  };

  function filterSubmitTable(item, searchValue) {
    if (originalSubmitList == undefined || originalSubmitList.length == 0) {
      setSubmitList([]);
      setTotalPage(1);
      setCurrentPage(1);
      return;
    }
    let filtered = [...originalSubmitList];
    if (
      searchValue != undefined &&
      item == '과제명' &&
      searchValue.trim() != ''
    ) {
      filtered = filtered.filter(item => {
        return (
          item.task_name != undefined && item.task_name.includes(searchValue)
        );
      });
    }
    if (
      searchValue != undefined &&
      item == '이름' &&
      searchValue.trim() != ''
    ) {
      filtered = filtered.filter(item => {
        return (
          item.student_name != undefined &&
          item.student_name.includes(searchValue)
        );
      });
    }
    setSubmitList(filtered);
    setTotalPage(Math.floor((filtered.length - 1) / countPerPage + 1));
    setCurrentPage(1);
  }

  useEffect(() => {
    filterSubmitTable(selectItem, searchText);
  }, [originalSubmitList, selectItem, searchText]);

  const [teacherNameSortOrder, setTeacherNameSortOrder] = useState(0);
  const [studentNameSortOrder, setStudentNameSortOrder] = useState(0);
  const [accountNameSortOrder, setAccountNameSortOrder] = useState(0);
  const [scoreSortOrder, setScoreSortOrder] = useState(1);
  const [lengthSortOrder, setLengthSortOrder] = useState(1);

  function sortSubmitListBy(key) {
    let sortedList;

    if (key === 'student_name') {
      sortedList = [...submitList].sort((a, b) => {
        if (a.student_name == b.student_name)
          return scoreSortOrder === 1 ? a.score - b.score : b.score - a.score;
        return studentNameSortOrder === 0
          ? a.student_name.localeCompare(b.student_name)
          : b.student_name.localeCompare(a.student_name);
      });
      setStudentNameSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'task_name') {
      sortedList = [...submitList].sort((a, b) => {
        if (a.task_name == b.task_name)
          return scoreSortOrder === 1 ? a.score - b.score : b.score - a.score;
        return accountNameSortOrder === 0
          ? a.task_name.localeCompare(b.task_name)
          : b.task_name.localeCompare(a.task_name);
      });
      setAccountNameSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'score') {
      sortedList = [...submitList].sort((a, b) => {
        return scoreSortOrder === 1 ? a.score - b.score : b.score - a.score;
      });
      setScoreSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'length') {
      sortedList = [...submitList].sort((a, b) => {
        return lengthSortOrder === 1
          ? a.length - b.length
          : b.length - a.length;
      });
      setLengthSortOrder(prev => (prev === 0 ? 1 : 0));
    }

    if (sortedList) {
      setSubmitList(sortedList);
      setTotalPage(Math.floor((sortedList.length - 1) / countPerPage + 1));
      setCurrentPage(1);
      setSelectedIndex(0);
      setSelectItem('과제명');
      setSearchText('');
    }
  }
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const searchItemList = ['과제명', '이름'];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleSelectItem = event => {
    const selectedIndex = event.target.value;
    setSelectedIndex(selectedIndex);
    const selectedValue = searchItemList[selectedIndex];
    setSelectItem(selectedValue);
  };

  useEffect(() => {
    filterSubmitTable(selectItem, searchText);
  }, [originalSubmitList, selectItem, searchText]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box
      style={{
        width: '95%',
        marginTop: '2rem',
        maxWidth: '120rem',
      }}
    >
      <Box
        style={{
          marginTop: '0.5rem',
          color: '#87929D',
          fontSize: '0.875rem',
          flex: 1,
          overflow: 'hidden',
          maxHeight: '10rem',
          marginBottom: '0.5rem',
        }}
      >
        <Box>지정된 기간동안의 과제 활동을 출력합니다.</Box>
        <Box style={{display: 'flex', marginTop: '2rem'}}>
          <Box
            style={{
              display: 'flex',
              fontWeight: '500',
              justifyContent: 'center',
            }}
          >
            <Box>
              기간 :
              <DatePicker
                locale={ko}
                dateFormat={'yyyy-MM-dd'}
                shouldCloseOnSelect
                minDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                }
                maxDate={endDate}
                selected={startDate}
                onChange={handleStartDateChange}
                customInput={<ExampleCustomInput />}
                todayButton="오늘로 가기" // ✅ 오늘 버튼 추가
                style={{width: '20rem'}}
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center', // 수평 중앙 정렬
                alignItems: 'center', // 수직 중앙 정렬
                fontWeight: '500',
                textAlign: 'center',
              }}
            >
              ~
            </Box>
            <Box>
              <DatePicker
                locale={ko}
                dateFormat={'yyyy-MM-dd'}
                shouldCloseOnSelect
                minDate={startDate}
                maxDate={todayDate}
                selected={endDate}
                onChange={handleEndDateChange}
                customInput={<ExampleCustomInput />}
                todayButton="오늘로 가기" // ✅ 오늘 버튼 추가
                style={{width: '20rem'}}
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
              />
            </Box>
            <Box>
              <Button
                style={{
                  color: 'white',
                  marginLeft: '1rem',
                  fontSize: '0.875rem',
                  border: 'none',
                  borderRadius: '1.925rem',
                  padding: '0.5rem 1rem',
                  backgroundColor: '#4FD7C3',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                }}
                onClick={() => {
                  getTeacherSubmitInfo(authReducer.user_id, startDate, endDate);
                }}
              >
                기록 가져오기
              </Button>
            </Box>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 0.2rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                sortSubmitListBy('student_name');
              }}
            >
              {'이름순'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 0.2rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                sortSubmitListBy('task_name');
              }}
            >
              {'과제순'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 0.2rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                sortSubmitListBy('score');
              }}
            >
              {'점수순'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 0.2rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                sortSubmitListBy('length');
              }}
            >
              {'글자수'}
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            fontWeight: '500',
            marginTop: '1rem',
            marginLeft: '2rem',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '0.5rem',
            }}
          >
            <Select
              disableUnderline
              onChange={handleSelectItem}
              value={selectedIndex}
              variant="standard"
              displayEmpty
              style={{
                border: '1px solid #898989',
                height: '2rem',
                borderRadius: '0.9rem',
                color: '#898989',
                fontSize: '0.7rem',
                whiteSpace: 'nowrap',
                padding: '0rem 1rem',
                width: '6rem',
              }}
            >
              {searchItemList.map((value, index) => (
                <MenuItem
                  style={{
                    fontSize: '0.7rem',
                  }}
                  key={index}
                  value={index}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>

            <TextField
              autoComplete="off"
              size="small"
              variant="outlined"
              sx={{
                width: '11rem',
                backgroundColor: 'white',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid black',
                  },
                  '&:hover fieldset': {
                    border: '1px solid black',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid black',
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'rgba(108, 108, 108, 0.721)',
                  fontSize: 'calc(0.3rem + 0.4vw)',
                },
                shrink: datePickerOpen,
              }}
              InputProps={{style: {fontSize: 'calc(0.3rem + 0.5vw)'}}}
              value={searchText}
              onChange={handleSearchText}
            ></TextField>
            <Button
              variant="contained"
              size="small"
              style={{backgroundColor: '#B1B1B1'}}
              onClick={() => filterSubmitTable(selectItem, searchText)}
            >
              검색
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          margin: '2rem 0rem 1rem 0rem',
          backgroundColor: 'white',
          boxShadow: '0px 5px 5px 0px #cdcdcd',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          paddingBottom: '2rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
            color: '#666972',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            margin: '2rem 2rem 0rem 2rem',
          }}
        >
          <Box
            style={{
              marginLeft: '2rem',
            }}
          >
            과제 건수 : {taskCount}
          </Box>
          <Box
            style={{
              marginLeft: '2rem',
            }}
          >
            제출 건수 : {submitList.length}
          </Box>
        </Box>
        <Box
          style={{
            width: '95%',
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '0.3rem',
            textAlign: 'center',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            alignItems: 'center', // 수직 중앙 정렬
            gap: '0.5rem', // 칸 사이 간격 추가
            marginLeft: '2rem',
            maxWidth: '120rem',
          }}
        >
          <Box style={{flex: 1, textAlign: 'center'}}>번호</Box>
          <Box style={{flex: 2, textAlign: 'left'}}>이름</Box>
          <Box style={{flex: 4, textAlign: 'left'}}>과제명</Box>
          <Box style={{flex: 4, textAlign: 'left'}}>제목</Box>
          <Box style={{flex: 2, textAlign: 'left'}}>제출일</Box>
          <Box style={{flex: 1, textAlign: 'left'}}>점수</Box>
          <Box style={{flex: 1, textAlign: 'left'}}>글자수</Box>
        </Box>

        {/* 구분선 */}
        <Box
          style={{
            backgroundColor: 'black',
            width: '100%',
            height: '1px',
            margin: '0.5rem 0',
          }}
        ></Box>

        {/* 리스트 렌더링 */}
        {submitList
          .filter(
            (_, index) => Math.floor(index / countPerPage) + 1 === currentPage,
          )
          .map((submit, index) => (
            <SubmitView
              key={'ticket' + index}
              index={index + 1}
              submit_info={submit}
              startDate={startDate}
              endDate={endDate}
              clickEvent={clickResult}
            />
          ))}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};

export default FragmentView;
