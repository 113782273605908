import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import icon_check1 from 'asset/imageV2/icon_check1.png';
const PopupView = props => {
  if (props.visibility) {
    return (
      <Box
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          backgroundColor: 'white',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          fontSize: 'calc(0.5rem + 0.5vw)',
          borderRadius: '15px',
          boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.25)',
          transform: 'translate(-50%, -50%)',
          zIndex: '3',
        }}
      >
        <img src={icon_check1} alt="iconCert1" />
        {' ' + props.text}
      </Box>
    );
  }
  return <></>;
};
PopupView.propTypes = {
  visibility: PropTypes.bool,
  text: PropTypes.string,
};
export default PopupView;
