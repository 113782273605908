import React, {useState, useRef, useEffect} from 'react';
import {
  Box,
  Tooltip,
  Button,
  IconButton,
  TextField,
  Select,
  Typography,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ko} from 'date-fns/esm/locale';
import writingSelectOption from 'asset/json/writing_select_option1.json';
import HelpOutlineIcon from 'asset/imageV2/icon_exclamation2.png';
import commentJanre from 'asset/json/comment_janre.json';

const ExampleCustomInput = React.forwardRef((props, ref) => (
  <button
    style={{
      backgroundColor: '#F7F8FA',
      border: '0px solid black',
      borderRadius: '0.625rem',
      padding: '0.5rem 1rem',
    }}
    className="custom-input"
    onClick={props.onClick}
    ref={ref}
  >
    {props.value}
  </button>
));
ExampleCustomInput.displayName = 'CalandarInput';
ExampleCustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};
const ItemView = props => {
  const inputRef = useRef(null); // Reference for TextField
  const selectedIndex = 0;
  const selectedGenre = writingSelectOption[selectedIndex]; // 선택된 글의 장르 가져오기
  const commentThisGenre = commentJanre[selectedGenre] || [];
  const ganreName = commentThisGenre[0];
  const newComment =
    commentThisGenre
      .slice(1) // 첫 번째 줄 제외
      .map(line => (line.trim() === '' ? '' : `* ${line}`)) // 빈 줄은 그대로, 나머지에는 * 추가
      .join('\n') + '\n'; // 줄바꿈 처리 및 마지막에 한 줄 띄기

  const [formattedComment, setFormattedComment] = useState(newComment); // 상태 관리
  const [formattedGanreName, setGanreNameComment] = useState(ganreName); // 상태 관리
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the TextField on component mount
    }
  }, []);
  // 장르 선택 시 도움말 업데이트
  const handleWritingSelectHelp = event => {
    const selectedIndex = event.target.value;
    const selectedGenre = writingSelectOption[selectedIndex]; // 선택된 글의 장르 가져오기
    const commentThisGenre = commentJanre[selectedGenre] || [];
    const ganreName = commentThisGenre[0];
    const newComment =
      commentThisGenre
        .slice(1) // 첫 번째 줄 제외
        .map(line => (line.trim() === '' ? '' : `* ${line}`)) // 빈 줄은 그대로, 나머지에는 * 추가
        .join('\n') + '\n'; // 줄바꿈 처리 및 마지막에 한 줄 띄기
    setGanreNameComment(ganreName); // 상태 업데이트
    setFormattedComment(newComment); // 상태 업데이트
    props.handleWritingSelectInput(event); // 기존 핸들러 호출
  };

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '0.5rem',
          border: '0px solid black',
          borderRadius: '0.625rem',
          fontSize: '1.5rem',
          color: '#4FD7C3',
          fontWeight: 'bold',
        }}
      >
        {'과제 생성'}
        <IconButton onClick={props.cancelEvent}>
          <img src={IconCancel} />
        </IconButton>{' '}
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Box style={{fontWeight: '500'}}>과제 기간</Box>
        <DatePicker
          locale={ko}
          dateFormat={'yyyy년 MM월 dd일 HH:mm'}
          shouldCloseOnSelect
          minDate={new Date()}
          maxDate={props.startDateMax}
          selected={props.startDate}
          onChange={props.startDateChange}
          customInput={<ExampleCustomInput />}
          showTimeSelect
          style={{width: '20rem'}}
        />
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>~</Box>
        <DatePicker
          locale={ko}
          dateFormat={'yyyy년 MM월 dd일 HH:mm'}
          shouldCloseOnSelect
          minDate={props.startDate}
          maxDate={props.endDateMax}
          selected={props.endDate}
          onChange={props.endDateChange}
          customInput={<ExampleCustomInput />}
          showTimeSelect
          style={{width: '20rem'}}
        />
      </Box>
      <Box style={{fontSize: '0.75rem', color: '#68737D', marginTop: '0.5rem'}}>
        ※ 과제 기간은 최대 3개월입니다. 과제 종료 후부터 평가 결과 공유 전까지는{' '}
        <font color="red">늦은 제출</font>이 가능합니다.
      </Box>
      <Box
        style={{
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center', // 중간 정렬
          }}
        >
          과제 장르
          <Tooltip
            title={
              <Box style={{padding: '0.5rem', maxWidth: '1200px'}}>
                <Typography
                  style={{fontWeight: 'bold', marginBottom: '0.5rem'}}
                >
                  {formattedGanreName || '장르를 선택해 주세요'}
                </Typography>
                <Typography
                  style={{fontSize: '0.75rem', whiteSpace: 'pre-line'}}
                >
                  {formattedComment}
                </Typography>
              </Box>
            }
            arrow
            sx={{
              '& .MuiTooltip-tooltip': {
                maxWidth: '1200px', // 툴팁 최대 너비를 800px로 설정
                fontSize: '0.875rem', // 툴팁 내용 글씨 크기
              },
            }}
          >
            <img
              src={HelpOutlineIcon}
              alt="tooltip-icon"
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '8px', // 아이콘과 텍스트 간격 추가
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </Box>
        <Box style={{width: '60%'}}>
          <Select
            disableUnderline
            fullWidth
            labelId="select-writing"
            id="wrintingType"
            variant="filled"
            hiddenLabel
            onChange={(props.handleWritingSelectInput, handleWritingSelectHelp)}
            value={props.writingSelect}
            style={{
              backgroundColor: '#F7F8FA',
              border: '0px solid black',
              borderRadius: '0.625rem',
              fontSize: '0.875rem',
            }}
          >
            {writingSelectOption.map((value, index) => {
              return (
                <MenuItem
                  key={'key' + index}
                  value={index}
                  style={{fontSize: '0.875rem'}}
                >
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
      {/* "과제 제목" 입력란을 이 위치로 이동 */}
      <Box style={{fontWeight: '500'}}>과제 제목</Box>
      <Box
        style={{
          marginTop: '0rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          autoComplete="off"
          variant="filled"
          inputRef={inputRef} // Assign ref to TextField
          sx={{
            width: '30rem', // 적당한 고정 크기 (픽셀로 지정)
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderBottom: '1px solid black',
              },
              '&:hover fieldset': {
                borderBottom: '1px solid black',
              },
              '&.Mui-focused fieldset': {
                borderBottom: '1px solid black',
              },
            },
          }}
          InputLabelProps={{
            style: {
              fontWeight: 'bold',
              fontSize: '1.2rem',
              color: '#A8B1B7',
              position: 'absolute', // 라벨 위치를 절대 좌표로 조정
              top: '1.0rem', // 입력 필드 상단으로 이동
              left: '0.1rem',
              backgroundColor: 'white', // 라벨이 필드와 겹치는 경우 가독성을 위해 배경색 추가
              padding: '0 0.25rem', // 배경색이 자연스럽게 보이도록 여백 추가
            },
            shrink: true, // 입력 필드가 비어 있어도 라벨이 위로 올라감
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              fontWeight: 'bold',
              color: 'black',
              backgroundColor: 'white',
              fontSize: '1.2rem',
              borderBottom: '1px solid black',
              display: 'flex',
            },
          }}
          value={props.titleText}
          onChange={props.handleTitle}
        ></TextField>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{fontWeight: '500'}}>과제 내용(문항, 전달 사항)</Box>
        <Box>
          <span style={{color: '#35D2BB'}}>{props.memoText.length}</span>/1000
        </Box>
      </Box>
      <Box style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
        <TextField
          autoComplete="off"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          fullWidth
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          rows={10}
          multiline
          value={props.memoText}
          onChange={props.handleMemo}
        ></TextField>
      </Box>
      <Box style={{marginTop: '2rem', display: 'flex'}}>
        <Button
          disabled={props.titleText == '' || props.memoText == ''}
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            color: 'white',
            backgroundColor:
              props.titleText == '' || props.memoText == ''
                ? '#A8B1B7'
                : '#35D2BB',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            marginLeft: '1rem',
            borderRadius: '6.25rem',
          }}
          onClick={() => props.nextStepEvent()}
        >
          {'step 02. 과제 조건 설정하기'}
        </Button>
      </Box>
    </Box>
  );
};

ItemView.propTypes = {
  titleText: PropTypes.string,
  handleTitle: PropTypes.func,
  memoText: PropTypes.string,
  handleMemo: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  startDateChange: PropTypes.func,
  startDateMax: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  endDateChange: PropTypes.func,
  endDateMax: PropTypes.instanceOf(Date),
  writingSelect: PropTypes.number,
  handleWritingSelectInput: PropTypes.func,
  cancelEvent: PropTypes.func,
  nextStepEvent: PropTypes.func,
};
export default ItemView;
