import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';
import AgeLabelView from 'view/mypage/AgeLabelView';

export const LibraryView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <AgeLabelView ageSelect={props.library.target_age} />
          <Box
            style={{
              border: 'solid 1px #D8DCDE',
              padding: '0.2rem 0.5rem',
              borderRadius: '0.5rem',
              color: '#68737D',
              whiteSpace: 'nowrap',
            }}
          >
            {props.library.task_janre}
          </Box>
        </Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {props.library.task_name}
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem', color: '#636C73'}}>
          <span>{StringUtils.getDateString(props.library.update_at)}</span>
          <span style={{display: props.library.is_share == 0 ? 'none' : ''}}>
            {'|'}
          </span>
          <span style={{display: props.library.is_share == 0 ? 'none' : ''}}>
            {'다운로드 수 ' + props.library.download_count}
          </span>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Button
          style={{
            color: '#B1B1B1',
            fontSize: '0.875rem',
            border: '1.5px solid #E9EBED',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1.5rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            props.deleteEvent(props.library);
          }}
        >
          {'삭제'}
        </Button>
        <Button
          style={{
            color: '#4FD7C3',
            fontSize: '0.875rem',
            border: '1.5px solid #4FD7C3',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1.5rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            props.modifyEvent(props.library);
          }}
        >
          {'수정'}
        </Button>
        <Button
          style={{
            color:
              props.library.is_download == 1
                ? '#B1B1B1'
                : props.library.is_share == 0 &&
                  props.library.share_group == 0 &&
                  props.library.is_keewi == 0
                ? '#35D2BB'
                : '#FF9900',
            fontSize: '0.875rem',
            border:
              props.library.is_download == 1
                ? '1.5px solid #B1B1B1'
                : props.library.is_share == 0 &&
                  props.library.share_group == 0 &&
                  props.library.is_keewi == 0
                ? '1.5px solid #35D2BB'
                : '1.5px solid #FF9900',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1.5rem',
            backgroundColor:
              props.library.is_download == 1 ? '#F6F6F6' : 'white',
            whiteSpace: 'nowrap',
            boxShadow:
              props.library.is_download == 0
                ? '0 4px 6px rgba(0, 0, 0, 0.1)'
                : '',
          }}
          onClick={() => {
            if (props.library.is_download == 1) {
              return;
            }
            props.shareEvent(props.library);
          }}
        >
          {props.library.is_download == 1
            ? '받은 과제'
            : props.library.is_share == 0 &&
              props.library.share_group == 0 &&
              props.library.is_keewi == 0
            ? '과제 공유'
            : '공유 취소'}
        </Button>
        <Button
          style={{
            color: '#262C31',
            fontSize: '0.875rem',
            border: '1.5px solid #262C31',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1.5rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            props.createEvent(props.library);
          }}
        >
          {'과제 내기'}
        </Button>
      </Box>
    </Box>
  );
};
LibraryView.propTypes = {
  library: PropTypes.object,
  modifyEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
  shareEvent: PropTypes.func,
  createEvent: PropTypes.func,
};
export default LibraryView;
