import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from 'asset/image/trash.svg';

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Paper,
} from '@mui/material';

const SampleTable = ({data, setData}) => {
  const [sortedData, setSortedData] = useState(
    () =>
      [...data]
        .map(item => ({...item, deleted: false})) // ✅ `deleted` 추가
        .sort((a, b) => (a.name || '').localeCompare(b.name || '')), // ✅ 이름순 정렬
  );

  const handleDelete = index => {
    // 새로운 배열을 생성하면서 해당 항목의 deleted 상태를 토글
    const newData = sortedData.map((item, i) =>
      i === index ? {...item, deleted: !item.deleted} : item,
    );

    // 상태 업데이트
    setSortedData(newData);
    setData(newData);
  };

  return (
    <Box sx={{marginTop: '1rem', marginLeft: '2rem', width: '100%'}}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {sortedData.map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    textAlign: 'left',
                    textDecoration: item.deleted ? 'line-through' : 'none',
                    color: item.deleted ? '#B1B1B1' : 'inherit',
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'left',
                    width: '20%',
                    textDecoration: item.deleted ? 'line-through' : 'none', // ✅ 삭제 시 취소선 표시
                    color: item.deleted ? '#B1B1B1' : 'inherit', // ✅ 삭제 시 색상 변경
                  }}
                >
                  {item.name || '[이름없음]'}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'left',
                    textDecoration: item.deleted ? 'line-through' : 'none',
                    color: item.deleted ? '#B1B1B1' : 'inherit',
                  }}
                >
                  {item.scores.join(', ')}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'left',
                    textDecoration: item.deleted ? 'line-through' : 'none',
                    color: item.deleted ? '#B1B1B1' : 'inherit',
                  }}
                >
                  {item.at}
                </TableCell>
                <TableCell sx={{textAlign: 'center'}}>
                  <IconButton onClick={() => handleDelete(index)}>
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      style={{
                        width: '20px',
                        height: '20px',
                        opacity: item.deleted ? 0.3 : 1, // ✅ 삭제 시 아이콘 투명도 조절
                      }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

// ✅ PropTypes 추가
SampleTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      at: PropTypes.string,
      scores: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
  ).isRequired,
  setData: PropTypes.func.isRequired, // ✅ 삭제 후 저장 버튼을 위한 함수
};

const PeerEvalComponent = ({
  peerEvalItems,
  peerEvalMaxScore,
  peerEvalChecked,
  peerEvalScores,
  peerEvalReviewerCount,
  peerReviewResultList,
  viewer_id,
}) => {
  if (!peerEvalChecked) return null;

  const [reviewData, setReviewData] = useState(peerReviewResultList);
  const [reviewScores, setReviewScores] = useState(peerEvalScores);
  const [reviewerCount, setReviewerCount] = useState(peerEvalReviewerCount);
  const [finalScore, setFinalScore] = useState(
    Math.min(
      100,
      (reviewScores.reduce((acc, score) => acc + Math.min(score, 100), 0) /
        (peerEvalMaxScore * reviewerCount * peerEvalItems.length)) *
        100,
    ),
  );

  const resetReviewData = data => {
    const newList = data.filter(item => !item.deleted);
    setReviewerCount(newList.length);
    const newScores = peerEvalItems.map((_, index) => {
      const total = newList.reduce(
        (sum, reviewer) => sum + (reviewer.scores?.[index] || 0),
        0,
      );
      return newList.length > 0 ? total / newList.length : 0; // ⚠️ 평가자가 없을 경우 0점 처리
    });
    setReviewScores(newScores);

    // ✅ finalScore: 전체 항목 평균 점수로 총점 계산
    const totalScore = newScores.reduce(
      (acc, score) => acc + Math.min(score, 100),
      0,
    );
    const maxPossibleScore = peerEvalMaxScore * peerEvalItems.length;

    setFinalScore(
      maxPossibleScore > 0
        ? Math.min(100, (totalScore / maxPossibleScore) * 100)
        : 0,
    );
  };
  useEffect(() => {
    const totalScore = reviewScores.reduce(
      (acc, score) => acc + Math.min(score, 100),
      0,
    );
    const maxPossibleScore = peerEvalMaxScore * peerEvalItems.length;

    setFinalScore(
      maxPossibleScore > 0
        ? Math.min(100, (totalScore / maxPossibleScore) * 100)
        : 0,
    );
  }, [reviewScores, peerEvalMaxScore, peerEvalItems]);

  useEffect(() => {
    resetReviewData(peerReviewResultList);
  }, [peerReviewResultList]);

  return (
    <Box
      sx={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
      }}
    >
      {peerEvalItems.map((value, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '1rem',
            justifyContent: 'space-between',
            width: '100%',
            padding: '0.5rem 0.1rem',
            borderBottom: '1px solid #ddd', // 각 항목 구분선 추가
          }}
        >
          {/* 평가 항목 */}
          <Box sx={{flex: 1, textAlign: 'left'}}>{value}</Box>

          {/* 평가 점수 및 리뷰어 수 표시 */}
          <Box
            sx={{
              display: 'flex',
              minWidth: '120px',
              textAlign: 'right',
              fontWeight: 'bold',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '10rem',
            }}
          >
            <Box sx={{fontSize: '1.5rem', fontWeight: 'bold'}}>
              {(reviewScores?.[index] ?? 0).toFixed(1)}
            </Box>
            <Box sx={{marginLeft: '0.5rem'}}>
              {' / '}
              {peerEvalMaxScore} {' (' + (reviewerCount ?? 0) + '명)'}
            </Box>
          </Box>
        </Box>
      ))}

      {/* 총점 표시 */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end', // ✅ 버튼을 오른쪽 끝으로 정렬
          width: '100%', // 부모 컨테이너가 전체 너비를 가지도록 설정
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginTop: '1rem',
            padding: '0.5rem',
          }}
        >
          총점
          <Box
            sx={{fontSize: '1.5rem', fontWeight: 'bold', marginLeft: '1rem'}}
          >
            {reviewerCount === 0
              ? ' - '
              : Number.isInteger(finalScore)
              ? finalScore
              : finalScore.toFixed(1)}
          </Box>
          <Box sx={{marginLeft: '0.5rem'}}>{' / 100'}</Box>
        </Box>
      </Box>
      <Box
        style={{
          display: viewer_id == 0 ? 'block' : 'none',
          marginTop: '1rem',
          paddingBottom: '1rem',
          width: '100%',
        }}
      >
        <SampleTable data={peerReviewResultList} setData={resetReviewData} />
      </Box>
    </Box>
  );
};

// ✅ PropTypes 추가
PeerEvalComponent.propTypes = {
  peerEvalItems: PropTypes.array.isRequired,
  peerEvalMaxScore: PropTypes.number.isRequired,
  peerEvalChecked: PropTypes.bool.isRequired,
  peerEvalScores: PropTypes.arrayOf(PropTypes.number),
  peerEvalReviewerCount: PropTypes.number.isRequired,
  peerReviewResultList: PropTypes.array.isRequired,
  viewer_id: PropTypes.number.isRequired,
};

export default PeerEvalComponent;
