import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';

const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }

  function pdf_score_match_comment() {
    if (props.pdfStatus !== 0 && props.openScore === 0) {
      return (
        <Box style={{color: '#FF9900', textAlign: 'center'}}>
          참고 : 점수가 제거된 평가 리포트가 공유된 상태입니다.
        </Box>
      );
    }
    if (props.pdfStatus !== 0 && props.openScore === 1) {
      return (
        <Box style={{color: '#FF9900', textAlign: 'center'}}>
          참고 : 점수가 포함된 평가 리포트가 공유된 상태입니다.
        </Box>
      );
    }
    if (props.pdfStatus === 0 && props.openScore === 0) {
      return (
        <Box style={{color: '#FF9900', textAlign: 'center'}}>
          참고 : 점수가 제거된 평가 결과가 공유된 상태입니다.
        </Box>
      );
    }
    if (props.pdfStatus === 0 && props.openScore === 1) {
      return (
        <Box style={{color: '#FF9900', textAlign: 'center'}}>
          참고 : 점수가 포함된 평가 결과가 공유된 상태입니다.
        </Box>
      );
    }
    return null;
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{textAlign: 'right'}}>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{color: '#717171', fontSize: '1.5rem'}}
          >
            ✖
          </Button>
        </Box>
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            color: '#35D2BB',
            textAlign: 'center',
          }}
        >
          평가 결과 공유를 취소합니다.
        </Box>
        <Box style={{color: '#68737D', textAlign: 'center'}}>
          과제 결과와 리포트가 학생에게 노출되지 않습니다.
        </Box>
        <Box style={{color: '#68737D', textAlign: 'center'}}>
          결과 공유 취소 후에는 피드백 수정이 가능합니다.
        </Box>
        {pdf_score_match_comment()}

        <Box style={{marginTop: '2rem', display: 'flex'}}>
          <Button
            style={{
              display: props.pdfStatus == 2 ? 'block' : 'none',
              width: '100%',
              whiteSpace: 'nowrap',
              color: '#35D2BB',
              backgroundColor: 'white',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
              border: '1px solid #35D2BB',
            }}
            onClick={() => {
              props.confirmEvent(0, 1);
            }}
          >
            평가지 공유 취소하기
          </Button>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.confirmEvent(1, 0);
            }}
          >
            결과 공유 취소하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  pdfStatus: PropTypes.number,
  taskShare: PropTypes.number,
  openScore: PropTypes.number,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
