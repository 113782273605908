import React, {useState} from 'react';
import {Button, Box, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import HeaderView from 'view/common/Header';
import backgroundMain1 from 'asset/imageV2/background_main1.svg';
import backgroundMain2 from 'asset/imageV2/background_main2.png';
import imageNotebook from 'asset/imageV2/image_notebook.png';
import iconArrowRight from 'asset/imageV2/icon_arrow_right.png';
import iconDownload from 'asset/imageV2/icon_download.png';
import iconCert1 from 'asset/imageV2/icon_cert1.svg';
import iconCert2 from 'asset/imageV2/icon_cert2.png';
import iconCert3 from 'asset/imageV2/icon_cert3.png';
import iconCert4 from 'asset/imageV2/icon_cert4.png';
import iconCert5 from 'asset/imageV2/icon_cert5.png';
import iconCert6 from 'asset/imageV2/icon_cert6.png';
import imgMain1 from 'asset/imageV2/image_main1.png';
import imgMain2 from 'asset/imageV2/image_main2.png';
import imgMain3 from 'asset/imageV2/image_main3.png';
import imgMain5 from 'asset/imageV2/image_main5.png';
import samplePDF from 'asset/pdf/sample.pdf';
import {mainColoredBtn, iconBox} from 'view/main/Styles';
import FooterView from 'view/common/Footer';
import AutoSlider from 'view/common/AutoSlider';
import SideOverlay1 from 'view/common/SideOverlay1';
import SchoolView from 'view/common/SchoolView';
import APIUtils from 'common/utils/APIUtils';
import {GetRunningPopup} from 'common/utils/GraphQLUtils';

const MainPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  const [expand3, setExpand3] = useState(false);
  const [expand4, setExpand4] = useState(false);
  const [expand5, setExpand5] = useState(false);
  const [expand6, setExpand6] = useState(false);
  const [expand7, setExpand7] = useState(false);
  const [expand8, setExpand8] = useState(false);

  function downloadSamplePDF() {
    const pdfBlob = new Blob([samplePDF], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = samplePDF;

    a.download = 'KEEwiT_sample_2025.pdf';
    document.body.appendChild(a);
    a.click();
    setTimeout(_ => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  function showMainButton() {
    if (authReducer.isLogin) {
      return (
        <Button
          variant="standard"
          style={mainColoredBtn}
          onClick={() => {
            navigate('/mypage');
          }}
        >
          들어가기
          <img
            src={iconArrowRight}
            alt="iconArrowRight"
            style={{marginLeft: '1rem', width: '1.5rem'}}
          />
        </Button>
      );
    }
    return (
      <Button
        variant="standard"
        style={mainColoredBtn}
        onClick={() => {
          navigate('/login');
        }}
      >
        {'회원가입/로그인'}
        <img
          src={iconArrowRight}
          alt="iconArrowRight"
          style={{marginLeft: '1rem', width: '1.5rem'}}
        />
      </Button>
    );
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <GetRunningPopup />
      <SideOverlay1 />
      <HeaderView />
      <Box
        style={{
          width: '80%',
          maxWidth: '80rem',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '3rem',
        }}
      >
        <Box style={{flex: 4}}>
          <Box
            style={{
              fontSize: '1rem',
              color: '#49545c',
              marginTop: '5rem',
            }}
          >
            동료 평가부터 논술 첨삭까지
          </Box>
          <Box
            style={{
              fontSize: '1.625rem',
              color: '#2f3941',
              fontWeight: '700',
              marginTop: '1rem',
            }}
          >
            글쓰기 평가
          </Box>
          <Box
            style={{fontSize: '1.625rem', color: '#2f3941', fontWeight: '700'}}
          >
            <span style={{color: '#35d2bb'}}>한번에</span> 해결하세요!
          </Box>
          <Box style={{marginTop: '2rem', marginBottom: '5rem'}}>
            {showMainButton()}
          </Box>
        </Box>
        <Box
          style={{
            flex: 6,
            position: 'relative',
          }}
        >
          <img
            src={imageNotebook}
            alt="imgnotebook"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
            }}
          />
          <img
            src={backgroundMain1}
            alt={'bg1'}
            style={{
              width: '120%',
              height: '100%',
              zIndex: '-1',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
              marginLeft: '-20%',
            }}
          />
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '1rem',
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box style={{color: '#3cd9c2', fontWeight: '700'}}>수상 및 인증</Box>
        <Box
          style={{
            marginTop: '1rem',
            width: '120%',
            maxWidth: '60rem',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: '4rem',
              fontSize: '0.7rem',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert6}
                  alt="iconCert1"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>국가공헌대상</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                교육부 장관상
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert1}
                  alt="iconCert5"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>에듀테크 실증 </Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                KERIS 원장상
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert5}
                  alt="iconCert2"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>이러닝-에듀테크</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                비즈 모델 최우수상
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert4}
                  alt="iconCert3"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>에듀테크 우수기업</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                콘텐츠·서비스 분야
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert3}
                  alt="iconCert4"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>SW 품질 평가</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                Good Software 1등급
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '6rem',
          width: '95%',
          display: 'flex',
          maxWidth: '80rem',
        }}
      >
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.4rem', fontWeight: 'bold'}}>
            <span style={{}}>객관적인 논술 평가</span>
          </Box>
          <Box
            style={{fontSize: '1.4rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            전과정을 함께하는 키위챗
          </Box>
          <Box style={{marginTop: '3.5rem'}}>
            키위 AI는 평가자의{' '}
            <span style={{fontWeight: '700'}}>
              주관성을 배제한 객관적인 평가를 수행
            </span>
            하여 신뢰도를 높입니다. 다수의 논문과 실증을 통해 우수성이 입증된
            키위 AI 평가 모델은 표준화된 기준에 따라 신뢰도 높은 평가를
            제공합니다. 또한, 키위챗은 글쓰기의 전 과정을 학생과 함께하며
            효과적인 글쓰기 학습을 지원합니다
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain1} alt="imgMain1" style={{width: '60%'}} />
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.4rem', fontWeight: 'bold'}}>
            표준화된 15가지 장르
          </Box>
          <Box
            style={{fontSize: '1.4rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            대입 논술 과제 라이브러리
          </Box>
          <Box style={{marginTop: '3.5rem'}}>
            키위티는{' '}
            <span style={{fontWeight: '700'}}>
              15가지 글쓰기 장르에 적합한 피드백
            </span>
            을 제공합니다. 평가 점수와 피드백은 일관성을 가지고 있습니다. 또한,
            과제 라이브러리에는 공유된 과제와 함께 대학 입시 논술과 관련된
            다양한 수준의 과제가 제공됩니다. 대입 논술 평가를 통해 자신의 실력을
            점검하고 부족한 부분을 개선해 나갈 수 있습니다.
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain2} alt="imgMain2" style={{width: '70%'}} />
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          width: '95%',
          display: 'flex',
          maxWidth: '80rem',
        }}
      >
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
            선생님과 학생의 연결
          </Box>
          <Box
            style={{fontSize: '1.5rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            과제 공유와 동료 평가
          </Box>
          <Box style={{marginTop: '3.5rem'}}>
            키위런은 키위티의 학생용 서비스로, 선생님이 학생과 연결되어 글쓰기
            과제를 손쉽게 생성하고 체계적으로 관리할 수 있도록 돕습니다. 또한,
            동료 평가 기능을 활용하면 학생들이 서로의 글을 평가하며 글쓰기
            실력을 더욱 향상시킬 수 있습니다.
          </Box>
          <Box>
            <span style={{fontWeight: '700'}}>효율적인 글쓰기 과제</span> 관리,
            키위티와 함께라면 가능합니다.
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain3} alt="imgMain3" style={{width: '70%'}} />
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Box style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
            한 눈에 보는 분석 결과
          </Box>
          <Box
            style={{fontSize: '1.5rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            전문적인 리포트 제공
          </Box>
          <Box
            style={{
              marginTop: '3.5rem',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                // width: '14rem',
              }}
            >
              <Box>
                키위티 리포트는 분석 결과를 직관적으로 파악할 수 있도록
                설계되었습니다.{' '}
                <span style={{fontWeight: '700'}}>총평과 통계 분석</span>은
                물론,{' '}
                <span style={{fontWeight: '700'}}>
                  평가 지표별 레이더 차트, 워드클라우드, 버블 차트
                </span>{' '}
                등 다양한 시각적 데이터를 제공합니다. 또한, 글 전체에 대한 종합
                피드백뿐만 아니라 문단별 분석과 맞춤형 조언까지 함께 제공하여
                보다 심층적인 글쓰기 개선이 가능합니다.
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '3rem',
                  marginBottom: '2rem',
                }}
              >
                <Button
                  variant="standard"
                  style={mainColoredBtn}
                  onClick={() => {
                    downloadSamplePDF();
                  }}
                >
                  {'리포트 예시'}
                  <img
                    src={iconDownload}
                    alt="iconDownload"
                    style={{marginLeft: '0.5rem', width: '1.5rem'}}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          marginTop: '6rem',
          width: '100%',
          height: '38rem',
          background: 'rgba(240, 251, 250, 0.50)',
          position: 'relative',
        }}
      >
        <img
          src={backgroundMain2}
          alt="backgroundMain2"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center top',
          }}
        />
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.75rem',
            fontWeight: '700',
            paddingTop: '6rem',
          }}
        >
          키위 평가 지표
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          키위 평가 엔진은 1960년대부터 연구된 영미권 글쓰기 평가의 기준을
          한국어 글쓰기 평가에 적용하였습니다.
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          AI의 정량적 평가 루브릭은 ‘글쓰기의 6가지 관점(6 traits)’을 바탕으로
          구성되었습니다.
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          ‘주제의 명확성’, ‘조직과 전개’, ‘어휘의 풍부성’, ‘문장 유창성’,
          ‘의도의 표현’, ‘문법의 정확성’
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          6가지 지표로 피드백을 제공합니다.
        </Box>
        <AutoSlider />
      </Box>
      <Box
        style={{
          width: '90%',
          maxWidth: '80rem',
          marginTop: '7rem',
          marginBottom: '5rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#3cd9c2', fontWeight: '700'}}>FAQ</Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
            }}
          >
            자주 묻는 질문
          </Box>
          <Box style={{display: 'block', minWidth: '19rem'}}>
            <Button
              style={{
                display: 'block',
                marginTop: '2rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand1(prev => !prev);
              }}
            >
              Q. 키위티 & 키위런, 어떻게 다를까요?
            </Button>
            <Box
              style={{
                display: expand1 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              🔹 키위티 (선생님용 서비스) <br />
              ◽ 학생들에게 글쓰기 과제 부여 및 진행 상황 모니터링 <br />
              ◽ AI 기반 평가 리포트 제공 + 피드백 수정 기능 지원 <br />
              ◽ AI 피드백을 활용하여 글을 직접 입력하거나 파일 업로드 가능
              <br />
              <br />
              🔹 키위런 (학생용 서비스) <br />
              ◽ 키위티 선생님 계정과 연결 후 사용 가능 <br />
              ◽ 글쓰기 과제를 수행하고 AI 피드백을 통해 글을 개선 <br />
              ◽ 동료 평가 참여 & 선생님의 피드백 확인 가능 <br />
              <br />
              키위티는 선생님을 위한 서비스이며, 키위런(학생용)과 연결하면 더욱
              효과적으로 활용할 수 있습니다! <br />
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand2(prev => !prev);
              }}
            >
              Q. 혼자서 키위티를 사용할 수 있나요?
            </Button>
            <Box
              style={{
                display: expand2 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              네, 가능합니다!
              <br />
              키위런(학생) 계정 없이도 키위티(선생님용)만으로 글쓰기 평가를
              진행할 수 있습니다.
              <br />
              <br />
              🔹 키위런 없이 키위티 사용하기
              <br />
              ◽ 개별 평가에서 직접 글을 입력하거나 파일을 업로드
              <br />
              ◽ 새 글 평가 / 파일 업로드 / 입력 링크 활용
              <br />
              ◽ AI 조교의 초벌 첨삭 및 피드백 확인
              <br />
              ◽ 피드백을 수정하고 평가지 완성
              <br />
              <br />
              이렇게 혼자서도 AI 기반 글쓰기 평가 및 피드백 제공이 가능합니다!
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand3(prev => !prev);
              }}
            >
              Q. 자녀에게 키위런을 시키고 싶습니다.
            </Button>
            <Box
              style={{
                display: expand3 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티와 키위런은 가정에서도 활용 가능합니다!
              <br />
              부모님은 키위티(선생님용)를, 자녀는 키위런(학생용)을 사용하여,
              효과적인 글쓰기 학습을 경험할 수 있습니다.
              <br />
              <br />
              🔹 부모님(키위티) → 자녀(키위런) 연결 가능
              <br />
              <br />
              글쓰기 과제 출제 및 피드백 제공
              <br />
              AI 글쓰기 연습으로 논술형 평가 대비
              <br />
              글쓰기 대회 참여 지원
              <br />
              <br />
              🔹 자녀(학생)는 키위런을 통해
              <br />
              다양한 글쓰기 과제를 수행하며 논리적 사고력 향상
              <br />
              AI 피드백을 통한 글쓰기 실력 강화
              <br />
              실제 논술형 평가와 유사한 환경에서 연습 가능
              <br />
              이제 가정에서도 키위티와 키위런을 활용하여 논술형 평가 시대를
              대비하세요! <br />
              <br /> <br />
              개인 구매 이용권을 구매해서 지금 바로 시작해보세요. <br />
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand4(prev => !prev);
              }}
            >
              Q. 요금제 선택 기준 알려주세요.
            </Button>
            <Box
              style={{
                display: expand4 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티 요금제는 공교육과 사교육으로 구분됩니다. <br />
              <br />
              🔹 공교육 요금제 <br />
              공교육 기관(학교 등)을 위한 요금제로, 1개월 / 6개월 / 12개월
              단위로 제공됩니다. 학교장터(S2B)를 통해 저렴한 가격으로 구매할 수
              있으며, 다수의 학생이 활용할 수 있도록 맞춤형 요금제가 제공됩니다.{' '}
              <br />
              <br />
              🔹 사교육 요금제 <br />
              사교육 기관과 개인 교습을 위한 요금제로, 1개월 / 3개월 / 6개월
              단위로 이용권을 직접 구매할 수 있습니다. 개인 교습용 요금제와 학원
              및 교육기관 등 다수의 선생님을 위한 기관구매가 별도로 구분되어
              있어, 교육 환경에 맞는 선택이 가능합니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand5(prev => !prev);
              }}
            >
              Q. 무료 체험 신청하고 싶어요.
            </Button>
            <Box
              style={{
                display: expand5 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티 도입 전, 무료로 체험해 보고 싶으신가요?{' '}
              <span
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                onClick={() => {
                  window.open('https://forms.gle/vq3jCb1tyQEywvXq6');
                }}
              >
                여기
              </span>
              를 클릭해서 신청해주세요.
              <br />
              <br /> 1개월 이용권(선생님 1명, 학생 30명)을 제공합니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand6(prev => !prev);
              }}
            >
              Q. 문해력 더하기는 무료인가요?
            </Button>
            <Box
              style={{
                display: expand6 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              네, 무료입니다. AI가 학습하는 방법이 바로 빈칸 채우기
              콘텐츠입니다. 선생님의 좋은 글 학습 자료를 쉽고 빠르게 빈칸
              콘텐츠로 만들어 줍니다. 학생과 공유할 수 있고, 누구에게나 공유할
              수 있습니다. 저장기간은 1년입니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand7(prev => !prev);
              }}
            >
              Q. 글쓰기 대회를 도입하고 싶어요.
            </Button>
            <Box
              style={{
                display: expand7 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티-키위런을 사용하면 글쓰기 대회를 위해서 추가 준비가
              필요없습니다. 다만, 학생과 글쓰기 대회 주최자 선생님의 연결을
              자동화하는 것이 편리합니다. 이때에는 그룹 관리자가 ID/PW를
              자동인증 방식으로 생성해서 사용하시면 됩니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand8(prev => !prev);
              }}
            >
              Q. 글공유 기능이 제공되나요?
            </Button>
            <Box
              style={{
                display: expand8 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티에서는 글 공유 기능을 동료 평가 형식으로 제공합니다.
              학생들은 친구의 글에 자유롭게 댓글을 달며 의견을 나눌 수 있고,
              선생님이 설정한 평가 지표에 따라 상호 채점도 가능합니다. 이를 통해
              학생들은 다양한 시각에서 글을 바라보고, 스스로의 글쓰기 역량을
              향상시킬 수 있습니다. 선생님은 학생들의 평가 결과를 한눈에 확인할
              수 있으며, 필요에 따라 학생들에게 피드백을 공유하여 보다 효과적인
              학습을 지원할 수 있습니다.
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display:
              expand1 ||
              expand2 ||
              expand3 ||
              expand4 ||
              expand5 ||
              expand6 ||
              expand7 ||
              expand8
                ? 'none'
                : 'flex',
            marginTop: 'rem',
            flex: '6',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <img src={imgMain5} alt="imgMain5" style={{width: '80%'}} />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default MainPage;
