import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
import {StringUtils} from 'common/utils/StringUtils';

const ItemView = props => {
  const today = new Date();
  const tomorrow = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    today.getHours(),
    today.getMinutes(),
  );
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            marginTop: '1.5rem',
            textAlign: 'center',
            color: '#279EFF',
          }}
        >
          수정 과제 생성하기
        </Box>
        <Box style={{color: '#68737D', textAlign: 'center'}}>
          과제의 결과가 공유되었습니다. 다음 차수 과제를 여시겠습니까?
        </Box>
        <Box
          style={{color: '#279EFF', marginTop: '0.5rem', textAlign: 'center'}}
        >
          해당 과제는 {StringUtils.getDateTimeKr(tomorrow)}에 시작되도록
          설정되므로 과제 시작 전에 과제 기간 등 내용을 변경하세요.
        </Box>
        <Box style={{marginTop: '3rem', display: 'flex'}}>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: ' #279EFF',
              backgroundColor: 'white',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
              border: '1px solid #279EFF',
            }}
            onClick={() => props.cancelEvent()}
          >
            취소
          </Button>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#279EFF',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => props.confirmEvent()}
          >
            과제 생성하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
