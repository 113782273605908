import React, {useState, useEffect} from 'react';
import {Box, Button, IconButton, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import TaskModifySub1 from 'view/popup/TaskModifySub1';
import TaskModifySub2 from 'view/popup/TaskModifySub2';
import TaskModifySub3 from 'view/popup/TaskModifySub3';
import TaskModifySub4 from 'view/popup/TaskModifySub4';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';
import {StringUtils} from 'common/utils/StringUtils';
import writingSelectOption from 'asset/json/writing_select_option1.json';

const ItemView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [modifyStep, setModifyStep] = useState(1);
  const [titleText, setTitleText] = useState('');
  const handleTitle = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 25) {
      inputVal = inputVal.substring(0, 25);
    }
    setTitleText(inputVal);
  };
  const [memoText, setMemoText] = useState('');
  const handleMemo = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 1000) {
      inputVal = inputVal.substring(0, 1000);
    }
    setMemoText(inputVal);
  };

  const today = new Date();
  const [maxDate, setMaxDate] = useState(new Date());
  const [passEndDate, setPassEndDate] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());
  const handleStartDateChange = newDate => {
    setStartDate(newDate);
    const newMaxDate = StringUtils.getDatePlusMoth(newDate, 3);
    const smallerMax = new Date(Math.min(newMaxDate, passEndDate));
    setMaxDate(smallerMax);
    const newEndDate = StringUtils.getDatePlusDate(newDate, 7);
    if (smallerMax < newEndDate) {
      setEndDate(smallerMax);
    } else {
      setEndDate(newEndDate);
    }
  };

  const [endDate, setEndDate] = useState(new Date());
  const handleEndDateChange = newDate => {
    setEndDate(newDate);
  };

  const [writingSelect, setWritingSelect] = useState(0);

  const handleWritingSelectInput = event => {
    setWritingSelect(event.target.value);
  };
  const [feedbackChecked, setFeedbackChecked] = useState(true);

  const handleFeedbackChange = event => {
    setFeedbackChecked(event.target.checked);
  };
  const [keewichatChecked, setKeewichatChecked] = useState(true);

  const handleKeewichatChange = event => {
    setKeewichatChecked(event.target.checked);
  };

  const [textCountChecked, setTextCountChecked] = useState(false);
  const handleTextCountChange = event => {
    setTextCountChecked(event.target.checked);
  };
  const [textCountMin, setTextCountMin] = useState('0');
  const [textCountMax, setTextCountMax] = useState('10000');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const handleRequiredWordChange = event => {
    setRequiredWordChecked(event.target.checked);
  };
  const [requiredWord, setRequiredWord] = useState('');

  const [totalStudentCount, setTotalStudentCount] = useState([]);
  const [classList, setClassList] = useState([]);
  const [selectedClassList, setSelectedClassList] = useState([]);
  const [originalClassList, setOriginalClassList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudentList, setSelectedStudentList] = useState([]);
  const [originalStudentList, setOriginalStudentList] = useState([]);

  function classCheckHandle(index) {
    const selectedVal = classList[index];
    if (selectedClassList.includes(selectedVal.class_id)) {
      const tempClassList = [...selectedClassList];
      setSelectedClassList(
        tempClassList.filter(val => val != selectedVal.class_id),
      );
    } else {
      const tempClassList = [...selectedClassList];
      tempClassList.push(selectedVal.class_id);
      setSelectedClassList(tempClassList);
    }
  }
  function studentCheckHandle(index) {
    const selectedVal = studentList[index];
    if (selectedStudentList.includes(selectedVal.student_id)) {
      const tempStudentList = [...selectedStudentList];
      setSelectedStudentList(
        tempStudentList.filter(val => val != selectedVal.student_id),
      );
    } else {
      const tempStudentList = [...selectedStudentList];
      tempStudentList.push(selectedVal.student_id);
      setSelectedStudentList(tempStudentList);
    }
  }
  const [peerReviewChecked, setPeerReviewChecked] = useState(false);
  const [peerEvalTimeReserved, setPeerEvalTimeReserved] = useState(false);
  const [peerEvalStartTime, setPeerEvalStartTime] = useState(new Date());
  const [peerEvalEndTime, setPeerEvalEndTime] = useState(new Date());
  const [peerEvalMaxScore, setPeerEvalMaxScore] = useState(3);
  const [peerEvalChecked, setPeerEvalChecked] = useState(false);
  const [peerCommentChecked, setPeerCommentChecked] = useState(true);
  const [maxTryCount, setMaxTryCount] = useState(0);

  const handlePeerEvalTimeReset = event => {
    setPeerEvalTimeReserved(event.target.checked);
  };
  const handlePeerEvalStartTimeChange = newDate => {
    setPeerEvalStartTime(newDate);
  };

  const handlePeerReviewChange = event => {
    setPeerReviewChecked(event.target.checked);
  };
  const handlePeerEvalEndTimeChange = newDate => {
    setPeerEvalEndTime(newDate);
  };

  const handlePeerEvalChange = event => {
    setPeerEvalChecked(event.target.checked);
  };
  const handlePeerCommentChange = event => {
    setPeerCommentChecked(event.target.checked);
  };

  const defaultTeacherComment =
    '동료 평가를 합니다.\n객관적인 평가를 해보세요.';

  const defaultPeerEvalItems = [
    '1. 내용구성 : 서론, 본론, 결론이 명확하고 글의 흐름이 자연스러운가요?',
    '2. 일관성과 문법 : 올바른 맞춤법과 문법을 사용했나요?',
    '3. 창의성 : 독창적인 아이디어와 표현을 사용했나요?',
  ];
  const [peerEvalItems, setPeerEvalItems] = useState(defaultPeerEvalItems);
  const [teacherCommentChecked, setTeacherCommentChecked] = useState(true);

  const [peerEvalTeacherMsg, setTeacherCommnetForPeerEval] = useState(
    defaultTeacherComment,
  );
  const handleTeacherCommentCheckedChange = event => {
    setTeacherCommentChecked(event.target.checked);
  };

  function tryModifyTask() {
    const includeClass = selectedClassList.filter(
      val => !originalClassList.includes(val),
    );
    const excludeClass = originalClassList.filter(
      val => !selectedClassList.includes(val),
    );
    const includeStudent = selectedStudentList.filter(
      val => !originalStudentList.includes(val),
    );
    const excludeStudent = originalStudentList.filter(
      val => !selectedStudentList.includes(val),
    );
    props.confirmEvent(
      titleText,
      memoText,
      writingSelectOption[writingSelect],
      startDate,
      endDate,
      feedbackChecked ? 1 : 2,
      includeClass,
      excludeClass,
      includeStudent,
      excludeStudent,
      textCountChecked ? 1 : 0,
      textCountChecked && textCountMin != '' ? textCountMin : 0,
      textCountChecked && textCountMax != '' ? textCountMax : 0,
      requiredWordChecked ? 1 : 0,
      requiredWordChecked
        ? requiredWord
            .split(',')
            .map(val => val.trim())
            .filter(val => val != '')
            .slice(0, 5)
            .join(',')
        : '',
      keewichatChecked ? 1 : 0,
      peerReviewChecked ? 1 : 0,
      peerCommentChecked ? 1 : 0,
      peerEvalChecked ? 1 : 0,
      peerEvalTeacherMsg,
      peerEvalItems,
      peerEvalMaxScore,
      peerEvalTimeReserved ? 1 : 0,
      peerEvalStartTime,
      peerEvalEndTime,
      maxTryCount,
    );
  }
  function getStudentAndClass() {
    const getStudentApi = async () => {
      try {
        const response = await APIUtils.ClassStudent(authReducer.user_id);
        setStudentList(
          response.data.student_list.sort((a, b) => {
            if (a.student_name > b.student_name) {
              return 1;
            }
            if (a.student_name < b.student_name) {
              return -1;
            }
            return 0;
          }),
        );
      } catch (err) {
        console.log(err);
      }
    };
    getStudentApi();
    const getClassApi = async () => {
      try {
        const response = await APIUtils.ClassView(authReducer.user_id);
        setClassList(
          response.data.class_list.sort((a, b) => {
            if (a.class_name > b.class_name) {
              return 1;
            }
            if (a.class_name < b.class_name) {
              return -1;
            }
            return 0;
          }),
        );
      } catch (err) {
        console.log(err);
      }
    };
    getClassApi();
    if (props.task_id == undefined) {
      return;
    }
    const getTaskDetailApi = async () => {
      try {
        const response = await APIUtils.TaskDetail(
          authReducer.user_id,
          props.task_id,
        );
        setSelectedTask(response.data);
        setSelectedClassList(response.data.class_id_list);
        setOriginalClassList(response.data.class_id_list);
        setSelectedStudentList(response.data.student_id_list);
        setOriginalStudentList(response.data.student_id_list);
        setWritingSelect(writingSelectOption.indexOf(response.data.task_janre));
        setMaxTryCount(response.data.max_try);
      } catch (err) {
        console.log(err);
      }
    };
    getTaskDetailApi();
  }
  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheckShort(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (response.data.status != 'none') {
            const threeMonth = new Date(today.setMonth(today.getMonth() + 3));
            const passEnd = new Date(response.data.end_time);
            if (threeMonth < passEnd) {
              setMaxDate(threeMonth);
            } else {
              setMaxDate(passEnd);
            }
            setPassEndDate(passEnd);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }

  function showModifyPage() {
    if (modifyStep == 1) {
      return (
        <TaskModifySub1
          titleText={titleText}
          handleTitle={handleTitle}
          memoText={memoText}
          handleMemo={handleMemo}
          startEditable={props.startEditable}
          startDate={startDate}
          startDateChange={handleStartDateChange}
          startDateMax={passEndDate}
          endDate={endDate}
          endDateChange={handleEndDateChange}
          endDateMax={maxDate}
          writingSelect={writingSelect}
          handleWritingSelectInput={handleWritingSelectInput}
          cancelEvent={props.cancelEvent}
          nextStepEvent={() => {
            setModifyStep(2);
          }}
        />
      );
    } else if (modifyStep == 2) {
      return (
        <TaskModifySub2
          titleText={titleText}
          feedbackChecked={feedbackChecked}
          handleFeedbackChange={handleFeedbackChange}
          keewichatChecked={keewichatChecked}
          handleKeewichatChange={handleKeewichatChange}
          peerReviewChecked={peerReviewChecked}
          handlePeerReviewChange={handlePeerReviewChange}
          textCountChecked={textCountChecked}
          handleTextCountChange={handleTextCountChange}
          textCountMin={textCountMin}
          handleTextCountMin={event => {
            let inputVal = event.target.value;
            const reg = new RegExp('^[0-9]*$');
            if (reg.test(inputVal)) {
              if (Number(inputVal) > 10000) {
                setTextCountMin('10000');
                if (
                  textCountMax == '' ||
                  Number(textCountMax) < Number(inputVal)
                ) {
                  setTextCountMax('10000');
                }
              } else {
                setTextCountMin(inputVal);
                if (
                  textCountMax == '' ||
                  Number(textCountMax) < Number(inputVal)
                ) {
                  setTextCountMax(inputVal);
                }
              }
            }
          }}
          textCountMax={textCountMax}
          handleTextCountMax={event => {
            let inputVal = event.target.value;
            const reg = new RegExp('^[0-9]*$');
            if (reg.test(inputVal)) {
              if (Number(inputVal) > 10000) {
                setTextCountMax('10000');
                if (
                  textCountMax == '' ||
                  Number(textCountMin) > Number(inputVal)
                ) {
                  setTextCountMin(inputVal);
                }
              } else {
                setTextCountMax(inputVal);
                if (
                  textCountMin == '' ||
                  Number(textCountMin) > Number(inputVal)
                ) {
                  setTextCountMin(inputVal);
                }
              }
            }
          }}
          requiredWordChecked={requiredWordChecked}
          handleRequiredWordChange={handleRequiredWordChange}
          requiredWord={requiredWord}
          handelRequiredWord={event => {
            let inputVal = event.target.value;
            setRequiredWord(inputVal);
          }}
          previousEvent={() => {
            setModifyStep(1);
          }}
          nextEvent={() => {
            setModifyStep(3);
          }}
          cancelEvent={props.cancelEvent}
          maxTryCount={maxTryCount}
          handleMaxTryCount={setMaxTryCount}
        />
      );
    } else if (modifyStep == 3) {
      return (
        <TaskModifySub3
          titleText={titleText}
          totalStudentCount={totalStudentCount}
          classList={classList}
          selectedClassList={selectedClassList}
          classCheckEvent={classCheckHandle}
          studentList={studentList}
          selectedStudentList={selectedStudentList}
          peerReviewChecked={peerReviewChecked}
          nextEvent={() => {
            setModifyStep(4);
          }}
          studentCheckEvent={studentCheckHandle}
          previousEvent={() => {
            setModifyStep(2);
          }}
          cancelEvent={props.cancelEvent}
          confirmEvent={tryModifyTask}
        />
      );
    } else if (modifyStep == 4) {
      return (
        <TaskModifySub4
          titleText={titleText}
          totalStudentCount={totalStudentCount}
          selectedClassList={selectedClassList}
          previousEvent={() => {
            setModifyStep(3);
          }}
          cancelEvent={props.cancelEvent}
          confirmEvent={tryModifyTask}
          peerEvalTimeReserved={peerEvalTimeReserved}
          peerEvalStartTime={peerEvalStartTime}
          peerEvalEndTime={peerEvalEndTime}
          handlePeerEvalTimeReset={handlePeerEvalTimeReset}
          peerEvalChecked={peerEvalChecked}
          handlePeerEvalChange={handlePeerEvalChange}
          peerCommentChecked={peerCommentChecked}
          handlePeerCommentChange={handlePeerCommentChange}
          peerEvalItems={peerEvalItems}
          setPeerEvalItems={setPeerEvalItems}
          teacherCommentChecked={teacherCommentChecked}
          peerEvalTeacherMsg={peerEvalTeacherMsg}
          setTeacherCommnetForPeerEval={setTeacherCommnetForPeerEval}
          handleTeacherCommentCheckedChange={handleTeacherCommentCheckedChange}
          taskStartTime={startDate}
          taskEndTime={endDate}
          handlePeerEvalStartTimeChange={handlePeerEvalStartTimeChange}
          handlePeerEvalEndTimeChange={handlePeerEvalEndTimeChange}
          peerEvalMaxScore={peerEvalMaxScore}
          setPeerEvalMaxScore={setPeerEvalMaxScore}
        />
      );
    }
  }
  useEffect(() => {
    getStudentAndClass();
    getPassInfo();
  }, [props.visibility]);
  useEffect(() => {
    if (selectedTask != undefined) {
      setModifyStep(1);
      setTitleText(selectedTask.task_name);
      setMemoText(selectedTask.task_message);
      setStartDate(new Date(selectedTask.start_time));
      setEndDate(new Date(selectedTask.end_time));
      setFeedbackChecked(selectedTask.task_type == 1 ? true : false);
      setTextCountChecked(selectedTask.length_check == 0 ? false : true);
      setTextCountMin(selectedTask.min_length + '');
      setTextCountMax(
        selectedTask.max_length == 0 ? '10000' : selectedTask.max_length + '',
      );
      setRequiredWordChecked(selectedTask.keyword_check == 0 ? false : true);
      setRequiredWord(selectedTask.keyword);
      setKeewichatChecked(selectedTask.pre_keewichat != 0);
      if (selectedTask.peer_review_status != 0) {
        setPeerReviewChecked(
          selectedTask.peer_eval_info.peerReviewChecked == 0 ? false : true,
        );
        setPeerCommentChecked(
          selectedTask.peer_eval_info.peerCommentChecked == 0 ? false : true,
        );
        setPeerEvalChecked(
          selectedTask.peer_eval_info.peerEvalChecked == 0 ? false : true,
        );
        setTeacherCommnetForPeerEval(
          selectedTask.peer_eval_info.peerEvalTeacherMsg,
        );
        setPeerEvalItems(selectedTask.peer_eval_info.peerEvalItems);
        setPeerEvalMaxScore(selectedTask.peer_eval_info.peerEvalMaxScore);
        setPeerEvalTimeReserved(
          selectedTask.peer_eval_info.peerEvalTimeReserved == 0 ? false : true,
        );
        setPeerEvalStartTime(
          new Date(selectedTask.peer_eval_info.peerEvalStartTime),
        );
        setPeerEvalEndTime(
          new Date(selectedTask.peer_eval_info.peerEvalEndTime),
        );
      }
    }
  }, [selectedTask]);
  useEffect(() => {
    const totalStudentList = classList
      .filter(val => selectedClassList.includes(val.class_id))
      .map(val => {
        return val.student_list.map(item => item.student_id);
      })
      .flat()
      .concat(selectedStudentList);
    const uniqueTotalStudentList = [...new Set(totalStudentList)];
    setTotalStudentCount(uniqueTotalStudentList.length);
  }, [selectedClassList, selectedStudentList]);
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        {showModifyPage()}
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  task_id: PropTypes.number,
  startEditable: PropTypes.bool,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
