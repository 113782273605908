export const loginBox = {
  coloredBox: function (mycolor) {
    return {
      color: 'black',
      width: '100%',
      backgroundColor: mycolor,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      fontSize: 'calc(0.7rem + 0.5vw)',
    };
  },
};
export const wholeLoginPage = {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
};
export const wholeEnterpriseLoginPage = {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
};
export const loginTitle1 = {
  marginTop: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 'normal',
  fontSize: 'calc(0.7rem + 0.7vw)',
};
export const loginTitle2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 'bold',
  fontSize: 'calc(1rem + 2vw)',
};

export const loginKakaoBox = {
  marginTop: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginNaverBox = {
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginGoogleBox = {
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginEnterpriseBox = {
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '4rem',
};

export const EnterpriseLogoBox = {
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: '#516561',
};
export const EnterpriseTextFieldBox1 = {
  marginTop: '4rem',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
};
export const EnterpriseTextFieldBox2 = {
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
};
export const EnterpriseErrorBox1 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  color: '#E8514D',
  fontSize: '0.8rem',
  minHeight: '0.8rem',
  marginTop: '0.2rem',
  marginLeft: '0.2rem',
};
export const EnterpriseErrorBox2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  marginBottom: '2rem',
  color: '#E8514D',
  fontSize: '0.8rem',
  minHeight: '0.8rem',
  marginTop: '0.2rem',
  marginLeft: '0.2rem',
};
export const EnterpriseNoticeBox = {
  marginTop: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  marginBottom: '3rem',
  color: '#445854',
  fontSize: '0.6rem',
};
export const EnterpriseEndBox = {
  marginTop: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '3rem',
};
