import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {StringUtils} from 'common/utils/StringUtils';
import ApiLoading from 'view/common/ApiViewLoading';
import KeewiCloud from 'view/common/KeewiCloud';
import backgroundMain2 from 'asset/imageV2/background_main2.png';
import PeerEvalComponent from 'page/peer/EvalItems';
import CommentList from 'page/peer/CommentList';

const ResultViewPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [studentID, setStudentID] = useState(0);
  const [taskID, setTaskID] = useState(0);
  const [myID, setMyID] = useState(0);
  const [myName, setMyName] = useState('');
  const [taskText, setTaskText] = useState('');
  const [janreText, setJanreText] = useState('');
  const [peerReviewStatus, setPeerReviewStatus] = useState('');
  const [titleText, setTitleText] = useState('');
  const [nameText, setNameText] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [dateText, setDateText] = useState('');
  const [wordCloudData, setWordCloudData] = useState([
    {text: '키위', value: 5},
    {text: '글쓰기', value: 4},
    {text: '평가', value: 3},
    {text: '엔진', value: 2},
    {text: '피드백', value: 1},
  ]);
  const defaultTeacherComment =
    '동료 평가를 합니다.\n객관적인 평가를 해보세요.';

  const defaultPeerEvalItems = [
    '1. 내용구성 : 서론, 본론, 결론이 명확하고 글의 흐름이 자연스러운가요?',
    '2. 일관성과 문법 : 올바른 맞춤법과 문법을 사용했나요?',
    '3. 창의성 : 독창적인 아이디어와 표현을 사용했나요?',
  ];
  const [peerEvalItems, setPeerEvalItems] = useState(defaultPeerEvalItems);
  const [peerEvalMaxScore, setPeerEvalMaxScore] = useState(3);
  const [peerEvalChecked, setPeerEvalChecked] = useState(false);
  const [peerEvalScores, setPeerEvalScores] = useState(null);
  const [peerEvalTeacherMsg, setTeacherCommnetForPeerEval] = useState(
    defaultTeacherComment,
  );
  const [commentList, setCommentList] = useState(null);
  const [peerEvalReviewerCount, setPeerEvalReviewerCount] = useState(0);
  const [peerReviewResultList, setPeerReviewResultList] = useState([]);
  const [goodResult, setGoodResult] = useState(null);
  const [peerEvalTimeReserved, setPeerEvalTimeReserved] = useState(false);
  const [peerEvalStartTime, setPeerEvalStartTime] = useState(new Date());
  const [peerEvalEndTime, setPeerEvalEndTime] = useState(new Date());

  function setPeerReviewScreenFromKeewiApiLoad(res) {
    setTaskText(res.data.task_name);
    setJanreText(res.data.task_janre);
    setPeerReviewStatus(res.data.peer_review_status);
    setTitleText(res.data.eval_result.title);
    setNameText(res.data.eval_result.student_name);
    setDateText(res.data.submit_time);
    const wordCloudData = res.data.eval_result.word_cloud;
    setWordCloudData(wordCloudData);
    setBodyText(
      AnalysisText.createHtmlText(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );
    setTeacherCommnetForPeerEval(res.data.peer_eval_info.peerEvalTeacherMsg);
    setPeerEvalChecked(
      res.data.peer_eval_info.peerEvalChecked === 1 ? true : false,
    );
    if (res.data.peer_eval_info.peerEvalChecked == 1) {
      setPeerEvalItems(res.data.peer_eval_info.peerEvalItems);
      setPeerEvalMaxScore(res.data.peer_eval_info.peerEvalMaxScore);
      setPeerEvalTimeReserved(res.data.peer_eval_info.peerEvalTimeReserved);
      setPeerEvalStartTime(new Date(res.data.peer_eval_info.peerEvalStartTime));
      setPeerEvalEndTime(new Date(res.data.peer_eval_info.peerEvalEndTime));
    }
    setPeerEvalReviewerCount(res.data.review_result.reviewer_count);
    setPeerReviewResultList(res.data.review_result.reviewers);
    setPeerEvalScores(res.data.review_result.scores_sum);
    setGoodResult(res.data.good_result);
    setCommentList(res.data.comment_list ?? []);
    setLoading(false);
  }

  const memoizedWordCloud = useMemo(
    () => <KeewiCloud data={wordCloudData} height={300} />,
    [wordCloudData],
  );

  function encodeBase64(input) {
    try {
      if (typeof window !== 'undefined') {
        // 브라우저 환경
        return window
          .btoa(unescape(encodeURIComponent(input)))
          .replace(/\+/g, '-')
          .replace(/\//g, '_'); // URLSafe 변환
      } else {
        // Node.js 환경
        return Buffer.from(input, 'utf-8')
          .toString('base64')
          .replace(/\+/g, '-')
          .replace(/\//g, '_'); // URLSafe 변환
      }
    } catch (e) {
      return '';
    }
  }

  function clickPeerReviewDo() {
    // 새 창에 URL과 쿼리 문자열 포함
    if (!taskID) {
      return;
    }
    const my_id = 0;
    const my_name = '선생님';
    const task_id = taskID;
    const student_id = studentID;
    function getRandomChar() {
      const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const encodedParams = encodeBase64(
      `${my_name}:${my_id}:${task_id}:${student_id}`,
    );
    const randomPrefix = getRandomChar();
    const randomSuffix = getRandomChar();
    const secureEncodedParams = randomPrefix + encodedParams + randomSuffix;

    let url = `http://192.168.219.39:3333/peer/review?gul=${secureEncodedParams}`;
    if (process.env.REACT_APP_ENV === 'live') {
      url = `https://keewi.korean.ai/peer/review?gul=${secureEncodedParams}`;
    } else if (process.env.REACT_APP_ENV === 'stage') {
      url = `https://keewi.keewi.site/peer/review?gul=${secureEncodedParams}`;
    }
    window.location.replace(url);
  }

  function createOverallComment() {
    return (
      <Box
        style={{
          width: '100%',
          marginTop: '0.5rem',
        }}
      >
        <Box>
          <Box
            style={{
              display: peerEvalTimeReserved ? 'block' : 'none',
              color: '#68737D',
              fontWeight: 'bold',
              marginLeft: '1rem',
              marginTop: '1rem',
              marginBottom: '1rem',
              fontSize: '1rem',
            }}
          >
            기간 :{' '}
            {StringUtils.getDateTimeKrFromDate(peerEvalStartTime) +
              ' ~ ' +
              StringUtils.getDateTimeNoYearKrFromDate(peerEvalEndTime)}
          </Box>
        </Box>
        <Box
          style={{
            display: !peerEvalTimeReserved ? 'block' : 'none',
            color: '#68737D',
            fontWeight: 'bold',
            marginLeft: '1rem',
            marginTop: '1rem',
            marginBottom: '1rem',
            fontSize: '1rem',
          }}
        ></Box>

        <Box
          style={{
            marginLeft: '1rem',
            marginRight: '1rem',
            whiteSpace: 'pre-line',
            backgroundColor: '#EBFBF9',
            fontSize: '0.875rem',
            padding: '1rem',
            borderRadius: '8px',
            lineHeight: '1.5',
          }}
        >
          {peerEvalTeacherMsg}
        </Box>
      </Box>
    );
  }
  const closeWindow = () => {
    window.close(); // 현재 창 닫기
  };
  function createPeerComments() {
    if (commentList.length === 0) return <></>;
    return (
      <Box
        style={{
          width: '100%',
          marginTop: '0.5rem',
        }}
      >
        <Box
          style={{
            marginLeft: '1rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          댓글
        </Box>
        <Box style={{marginLeft: '1rem'}}>
          <CommentList commentData={commentList} student_name={nameText} />
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    const setAnalasisResult = async (myID, studentID, taskID) => {
      try {
        const response = await APIUtils.GetPeerReviewContent(
          myID,
          taskID,
          studentID,
        );
        setPeerReviewScreenFromKeewiApiLoad(response);
      } catch (err) {
        console.log(err);
      }
    };

    // ✅ Base64 디코딩 함수 (브라우저 & Node.js 지원)
    function decodeBase64(input) {
      try {
        if (!input) throw new Error('🚨 디코딩할 문자열이 없습니다.');

        let base64 = input.slice(1, -1).replace(/-/g, '+').replace(/_/g, '/');

        if (typeof window !== 'undefined') {
          // 브라우저 환경
          return decodeURIComponent(escape(window.atob(base64)));
        } else {
          // Node.js 환경
          return Buffer.from(base64, 'base64').toString('utf-8');
        }
      } catch (e) {
        return '';
      }
    }

    // ✅ 데이터 불러오기 함수
    const fetchData = async () => {
      try {
        // URL의 쿼리 문자열 추출
        const query = new URLSearchParams(location.search);
        const encodedData = query.get('gul');

        if (!encodedData)
          throw new Error('🚨 인코딩된 데이터(gul)가 없습니다!');

        // Base64 디코딩
        const decodedString = decodeBase64(encodedData);

        if (!decodedString) throw new Error('🚨 Base64 디코딩 실패!');

        // 디코딩된 데이터를 ':' 기준으로 분리
        const decodedParts = decodedString.split(':');

        if (decodedParts.length < 4)
          throw new Error('🚨 디코딩된 데이터가 올바른 형식이 아닙니다!');

        // URL 인코딩이 포함된 경우, 다시 디코딩
        const decodeMyName = decodeURIComponent(decodedParts[0]);
        const myid = parseInt(decodedParts[1], 10);
        const taskid = parseInt(decodedParts[2], 10);
        const studentid = parseInt(decodedParts[3], 10);

        if (isNaN(myid) || isNaN(taskid) || isNaN(studentid))
          throw new Error('🚨 잘못된 접근');

        // ✅ 상태 업데이트
        setMyName(decodeMyName);
        setMyID(myid);
        setTaskID(taskid);
        setStudentID(studentid);
        setAnalasisResult(myid, studentid, taskid);
      } catch (err) {
        console.error('🚨 잘못된 접근:', err.message);
      }
    };
    fetchData();
  }, []);

  if (loading) return <ApiLoading loading={loading} />;
  return (
    <>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            width: '95%',
            backgroundColor: '#F0FBFA',
            backgroundImage: `url(${backgroundMain2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            display: 'flex',
            flexDirection: 'column',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingTop: '2rem',
            paddingBottom: '2rem',
          }}
        >
          <Box
            style={{fontSize: '1.5rem', marginLeft: '1rem', fontWeight: 'bold'}}
          >
            {titleText}
          </Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '-2rem',
              justifyContent: 'flex-end', // 오른쪽 끝으로 정렬
            }}
          >
            <button
              className="print-hide"
              style={{
                color: '#898989',
                fontWeight: 'bold',
                fontSize: '0.874rem',
                border: 'none',
                borderRadius: '1.925rem',
                padding: '0.5rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                height: '3rem',
                width: '4rem',
                marginRight: '1rem',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={closeWindow}
            >
              닫기
            </button>
            <button
              className="print-hide"
              style={{
                color: 'white',
                marginRight: '2rem',
                fontWeight: 'bold',
                fontSize: '0.874rem',
                border: 'none',
                borderRadius: '1.925rem',
                padding: '0.5rem 1rem',
                backgroundColor: '#4FD7C3',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                height: '3rem',
                width: '4rem',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                const printStyles = `
                      @media print {
                        body {
                          font-size: 10px !important; 
                        }
                        h1, h2, h3, p {
                          font-size: smaller !important;
                        }
                        .print-hide {
                          display: none !important; 
                        }
                      }
                    `;
                const styleSheet = document.createElement('style');
                styleSheet.type = 'text/css';
                styleSheet.innerText = printStyles;
                document.head.appendChild(styleSheet);
                window.print();
                document.head.removeChild(styleSheet);
              }}
            >
              인쇄
            </button>
          </Box>
          <Box
            style={{
              fontSize: '1rem',
              marginLeft: '1rem',
              marginTop: '2rem',
              fontWeight: 'bold',
            }}
          >
            {`과제 : ${taskText} [${janreText}]`}
          </Box>
          <Box
            style={{
              fontSize: '1rem',
              marginLeft: '1rem',

              fontWeight: 'bold',
            }}
          >
            {`이름 : ${nameText} (${dateText.substring(0, 16)})`}
          </Box>
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            paddingBottom: '1rem',
            width: '95%',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            <Box
              style={{
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '1.25rem',
                border: '1px solid var(--01-gray-07, #E9EBED)',
                boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
                padding: '2rem 1%',
                display: 'flex', // Flexbox 적용
                justifyContent: 'center', // 중앙 정렬
                alignItems: 'center', // 세로 중앙 정렬
                gap: '2rem', // 본문과 단어 클라우드 사이 간격 조정
              }}
            >
              <Box
                style={{
                  width: '70%',
                  textAlign: 'left',
                  alignSelf: 'flex-start', // ✅ 위쪽 정렬
                  position: 'relative', // ✅ 상대 위치 설정
                  marginBottom: '1rem',
                }}
              >
                <Box>{bodyText}</Box>
                <Box
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    alignSelf: 'flex-start',
                    width: 'fit-content',
                    paddingLeft: '0',
                    margin: '0',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                  }}
                >
                  <Box
                    style={{
                      display: goodResult === null ? 'none' : 'block',
                      width: '3rem',
                      fontSize: '1rem',
                      color: 'black',
                    }}
                  >
                    {'♥️ ' + goodResult.good_count}
                  </Box>

                  <Box
                    style={{
                      marginLeft: '1rem',
                      width: '10rem',
                    }}
                  >
                    🗨️ {commentList.length}
                  </Box>
                </Box>
              </Box>
              <Box
                className="print-hide"
                style={{
                  width: '30%',
                  minHeight: '20rem',
                  backgroundColor: '#F7F8FA',
                  borderRadius: '1.25rem',
                  padding: '1.25rem 0rem',
                  alignItems: 'center',
                  justifyContent: 'center', // 중앙 정렬
                }}
              >
                {memoizedWordCloud}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            display:
              peerEvalChecked || commentList.length > 0 ? 'block' : 'none',
            marginTop: '1rem',
            paddingBottom: '1rem',
            width: '95%',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            <Box
              style={{
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '1.25rem',
                border: '1px solid var(--01-gray-07, #E9EBED)',
                boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
                padding: '0.5rem 1%',
                display: 'flex', // Flexbox 적용
                justifyContent: 'center', // 중앙 정렬
                alignItems: 'center', // 세로 중앙 정렬
                gap: '2rem', // 본문과 단어 클라우드 사이 간격 조정
              }}
            >
              <Box
                style={{
                  width: '100%',
                  textAlign: 'left',
                  alignSelf: 'flex-start', // ✅ 위쪽 정렬
                  position: 'relative', // ✅ 상대 위치 설정
                  marginBottom: '1rem',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '0.5rem',
                    alignItems: 'center', // 세로 중앙 정렬
                  }}
                >
                  <Box
                    style={{
                      marginLeft: '1rem',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    동료 평가 내용
                  </Box>
                  <Button
                    className="print-hide"
                    style={{
                      display:
                        peerReviewStatus == 2 && myID == 0 ? 'block' : 'none',
                      color: 'white',
                      marginLeft: '10rem',
                      fontWeight: 'bold',
                      fontSize: '0.874rem',
                      border: 'none',
                      borderRadius: '1.925rem',
                      padding: '0.5rem 1rem',
                      backgroundColor: '#4FD7C3',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                      height: '3rem',
                      width: '8rem',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                    }}
                    onClick={() => {
                      clickPeerReviewDo();
                    }}
                  >
                    {'평가 참여하기'}
                  </Button>
                </Box>

                {createOverallComment()}

                {peerEvalReviewerCount > 0 && (
                  <>
                    <Box
                      style={{
                        marginLeft: '1rem',
                        marginTop: '2rem',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        marginBottom: '1rem',
                      }}
                    >
                      동료 평가 결과
                    </Box>

                    <Box
                      sx={{
                        justifyContent: 'center',
                      }}
                    >
                      {/* ✅ 평가 항목들 */}
                      <PeerEvalComponent
                        peerEvalItems={peerEvalItems}
                        peerEvalMaxScore={peerEvalMaxScore}
                        peerEvalChecked={peerEvalChecked}
                        peerEvalScores={peerEvalScores}
                        peerEvalReviewerCount={peerEvalReviewerCount}
                        peerReviewResultList={peerReviewResultList}
                        viewer_id={myID}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {commentList.length > 0 && (
          <Box
            style={{
              display: commentList.length > 0 ? 'block' : 'none',
              marginTop: '1rem',
              paddingBottom: '1rem',
              width: '95%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                flex: 1,
              }}
            >
              <Box
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '1.25rem',
                  border: '1px solid var(--01-gray-07, #E9EBED)',
                  boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
                  padding: '0.5rem 1%',
                  display: 'flex', // Flexbox 적용
                  justifyContent: 'center', // 중앙 정렬
                  alignItems: 'center', // 세로 중앙 정렬
                  gap: '2rem', // 본문과 단어 클라우드 사이 간격 조정
                }}
              >
                <Box
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    alignSelf: 'flex-start', // ✅ 위쪽 정렬
                    position: 'relative', // ✅ 상대 위치 설정
                    marginBottom: '1rem',
                  }}
                >
                  {createPeerComments()}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default ResultViewPage;
