import React, {useState, useEffect} from 'react';
import {Box, Button, FormControl, ToggleButtonGroup} from '@mui/material';
import PropTypes from 'prop-types';
import {
  PurchaseConstant1,
  PurchaseConstant2,
  PurchaseConstant3,
  PurchaseConstant4,
} from 'common/utils/PurchaseConstants';
import {StringUtils} from 'common/utils/StringUtils';
import {PurchaseSelectButton} from 'view/purchase/Styles';
import iconExclamation from 'asset/imageV2/icon_exclamation1.png';
import iconCard from 'asset/imageV2/icon_card1.png';
import iconToss from 'asset/imageV2/icon_toss.png';
import {NumberUtils} from 'common/utils/NumberUtils';

const ItemView = props => {
  if (props.purchaseSectionVisibility == 0) return;
  const purchaseGroups = [
    PurchaseConstant1,
    PurchaseConstant2,
    PurchaseConstant3,
    PurchaseConstant4,
  ];
  const selectedConstant = purchaseGroups[Math.floor(props.clickedCard / 4)];
  if (props.clickedCard == null || props.clickedCard === -1) return <></>;
  const [selectValue, setSelectValue] = useState('카드');
  function handleSelect(evt) {
    setSelectValue(evt.target.value);
  }
  // 🚀 newStartDate를 안전하게 처리 (문자열 & Date 객체 지원)
  const formattedStartDate = StringUtils.getDateOnly(props.newStartDate);
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        style={{
          display: 'flex',
          borderRadius: '0.625rem',
          backgroundColor: 'rgba(255,153,0,0.1)',
          color: '#717171',
          padding: '1rem 2rem',
          width: 'calc(100% - 4rem)',
          alignItems: 'start',
        }}
      >
        <img
          style={{marginRight: '0.8rem'}}
          src={iconExclamation}
          alt="iconExclamation"
        />
        본 이용권을 결제하시면{' '}
        <span
          style={{
            color: '#ed8e00',
            fontWeight: 'bold',
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
          }}
        >
          {' '}
          {formattedStartDate + '일부터 '}
          {StringUtils.getMonthPlusThisDate2(
            props.newStartDate,
            selectedConstant[props.clickedCard % 4].evalDuration,
          )}
          일까지
          {' 선생님 ' + props.teacherCount + '명이 '}
          {'학생 ' + props.studentCount + '명'}
          {props.isDupPass ? '을 추가하여' : '과 함께'}
        </span>
        이용하실 수 있습니다.
      </Box>

      <FormControl style={{marginTop: '1rem', width: '100%'}}>
        <ToggleButtonGroup
          aria-label="select button"
          exclusive
          value={selectValue}
          style={{display: 'flex', justifyContent: 'center', gap: '1rem'}}
        >
          <PurchaseSelectButton
            onClick={() => props.clickEvent('카드')}
            value="카드"
          >
            <img style={{width: '2rem'}} src={iconCard} alt="iconCard" />
            결제하기 (
            {NumberUtils.getThousandComma(
              selectedConstant[props.clickedCard % 4]?.evalCost || 0,
            )}
            원)
          </PurchaseSelectButton>
          <PurchaseSelectButton
            onClick={() => props.clickEvent('토스페이')}
            value="토스페이"
          >
            <img style={{width: '6rem'}} src={iconToss} alt="iconToss" />
            토스페이 (
            {NumberUtils.getThousandComma(
              selectedConstant[props.clickedCard % 4]?.evalCost || 0,
            )}
            원)
          </PurchaseSelectButton>
        </ToggleButtonGroup>
      </FormControl>
    </Box>
  );
};

ItemView.propTypes = {
  clickedCard: PropTypes.number,
  clickEvent: PropTypes.func,
  purchaseSectionVisibility: PropTypes.number,
  studentCount: PropTypes.number,
  teacherCount: PropTypes.number,
  newStartDate: PropTypes.instanceOf(Date),
  isDupPass: PropTypes.number,
  defautPayMethod: PropTypes.string,
};

export default ItemView;
