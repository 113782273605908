export const PurchaseConstants = [
  {
    evalCount: 1,
    evalCost: 9900,
    evalDuration: 1,
  },
  {
    evalCount: 10,
    evalCost: 55000,
    evalDuration: 1,
  },
  {
    evalCount: 50,
    evalCost: 110000,
    evalDuration: 1,
  },
  {
    evalCount: 150,
    evalCost: 220000,
    evalDuration: 1,
  },
];

export const PurchaseConstant1 = [
  {
    studentCount: 1,
    teacherCount: 2,
    classCount: 1,
    evalDuration: 1,
    storageLimit: 10,
    ticketType: '개인',
    evalCost: 19000,
  },
  {
    studentCount: 5,
    teacherCount: 2,
    classCount: 2,
    evalDuration: 1,
    storageLimit: 10,
    ticketType: '개인',
    evalCost: 79000,
  },
  {
    studentCount: 10,
    teacherCount: 2,
    classCount: 3,
    evalDuration: 1,
    storageLimit: 10,
    ticketType: '개인',
    evalCost: 139000,
  },
  {
    studentCount: 30,
    teacherCount: 2,
    classCount: 5,
    evalDuration: 1,
    storageLimit: 30,
    ticketType: '개인',
    evalCost: 299000,
  },
];
export const PurchaseConstant2 = [
  {
    studentCount: 1,
    teacherCount: 2,
    classCount: 1,
    evalDuration: 3,
    storageLimit: 10,
    ticketType: 'personal',
    evalCost: 49000,
  },
  {
    studentCount: 5,
    teacherCount: 2,
    classCount: 1,
    evalDuration: 3,
    storageLimit: 10,
    ticketType: 'personal',
    evalCost: 199000,
  },
  {
    studentCount: 10,
    teacherCount: 2,
    classCount: 3,
    evalDuration: 3,
    storageLimit: 10,
    ticketType: 'personal',
    evalCost: 299000,
  },
  {
    studentCount: 30,
    teacherCount: 2,
    classCount: 5,
    evalDuration: 3,
    storageLimit: 30,
    ticketType: 'personal',
    evalCost: 799000,
  },
];
export const PurchaseConstant3 = [
  {
    studentCount: 25,
    teacherCount: 5,
    classCount: 5,
    evalDuration: 3,
    storageLimit: 10,
    ticketType: 'group',
    evalCost: 792000,
  },
  {
    studentCount: 50,
    teacherCount: 10,
    classCount: 10,
    evalDuration: 3,
    storageLimit: 10,
    ticketType: 'group',
    evalCost: 1188000,
  },
  {
    studentCount: 100,
    teacherCount: 20,
    classCount: 30,
    evalDuration: 3,
    storageLimit: 10,
    ticketType: 'group',
    evalCost: 1584000,
  },
  {
    studentCount: 200,
    teacherCount: 30,
    classCount: 50,
    evalDuration: 3,
    storageLimit: 30,
    ticketType: 'group',
    evalCost: 2394000,
  },
];
export const PurchaseConstant4 = [
  {
    studentCount: 25,
    teacherCount: 5,
    classCount: 5,
    evalDuration: 6,
    storageLimit: 10,
    ticketType: 'group',
    evalCost: 1320000,
  },
  {
    studentCount: 50,
    teacherCount: 10,
    classCount: 10,
    evalDuration: 6,
    storageLimit: 10,
    ticketType: 'group',
    evalCost: 1980000,
  },
  {
    studentCount: 100,
    teacherCount: 20,
    classCount: 30,
    evalDuration: 6,
    storageLimit: 10,
    ticketType: 'group',
    evalCost: 2640000,
  },
  {
    studentCount: 200,
    teacherCount: 30,
    classCount: 50,
    evalDuration: 6,
    storageLimit: 30,
    ticketType: 'group',
    evalCost: 3990000,
  },
];

export const PurchaseConstantX = [
  {
    studentCount: 10000000,
    teacherCount: 10000000,
    classCount: 5,
    evalDuration: 6,
    storageLimit: 10,
    ticketType: 'group',
    evalCost: 1320000,
  },
];
