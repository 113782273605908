import React, {useState} from 'react';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {saveLogin} from 'common/reducer/auth';
import HeaderView from 'view/common/Header';
import {
  wholeEnterpriseLoginPage,
  EnterpriseLogoBox,
  EnterpriseInfoBox,
  EnterpriseTextFieldBox1,
  EnterpriseTextFieldBox2,
  EnterpriseErrorBox1,
  EnterpriseErrorBox2,
  EnterpriseNoticeBox,
  EnterpriseEndBox,
} from 'view/login/Styles';
import APIUtils from 'common/utils/APIUtils';
import FooterView from 'view/common/Footer';
import IconEyeOn from 'asset/imageV2/eye.svg';
import IconEyeOff from 'asset/imageV2/eye-off.svg';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const [IDError, setIDError] = useState(false);
  const [PWError, setPWError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const [idText, setIdText] = useState('');
  const handleIdText = event => {
    let inputVal = event.target.value;
    setIdText(inputVal);
  };
  const [pwText, setPwText] = useState('');
  const handlePwText = event => {
    const inputValue = event.target.value;
    setPwText(inputValue);
  };
  function showIDError() {
    if (IDError) {
      return <Box style={EnterpriseErrorBox1}>아이디를 확인하세요.</Box>;
    }
    return <Box style={EnterpriseErrorBox1}></Box>;
  }
  function showPWError() {
    if (PWError) {
      return <Box style={EnterpriseErrorBox2}>비밀번호를 확인하세요.</Box>;
    }
    return <Box style={EnterpriseErrorBox2}></Box>;
  }
  function callEnterpriseLoginAPI() {
    if (idText == '' && pwText == '') {
      setIDError(true);
      setPWError(true);
      return;
    } else if (idText == '') {
      setIDError(true);
      setPWError(false);
      return;
    } else if (pwText == '') {
      setIDError(false);
      setPWError(true);
      return;
    }
    const loginResult = async (id, pw) => {
      try {
        const response = await APIUtils.AccountLoginEnterprise(id, pw);

        return response;
      } catch (err) {
        console.log(err);
      }
    };
    loginResult(idText, pwText).then(r => {
      if (r.data.ret_code == 1000) {
        if (r.data.ret_code == 1000) {
          dispatch(
            saveLogin({
              user_id: r.data.user_id,
              user_name: r.data.user_name,
              user_email: r.data.email,
              export_name: r.data.export_name,
              export_email: r.data.export_email,
              grade_in_charge: r.data.grade_in_charge,
              path: 'school',
              user_token: idText,
              group_id: r.data.group_id,
              group_name: r.data.group_name,
              is_group_admin: r.data.is_group_admin,
              auth_key: r.data.auth_key,
            }),
          );
        }
        gotoMypage();
      } else if (r.data.ret_code == 2001) {
        setIDError(true);
        setPWError(false);
      } else if (r.data.ret_code == 2004) {
        setIDError(false);
        setPWError(true);
      } else if (r.data.ret_code == 2003) {
        alert('이미 탈퇴한 유저입니다.');
      } else if (r.data.ret_code == 9999) {
        alert('엔진에러');
      } else if (r.data.ret_code == 9998) {
        alert('입력값 에러');
      } else if (r.data.ret_code == 9997) {
        alert('DB 저장 에러');
      }
    });
  }
  function gotoMypage() {
    navigate('/mypage', {replace: true});
  }
  function onKeyPressed(ev) {
    if (ev.keyCode == 13) {
      callEnterpriseLoginAPI();
    }
  }
  return (
    <Box style={wholeEnterpriseLoginPage}>
      <HeaderView />
      <Box
        style={{
          minHeight: '55vh',
          width: '80%',
        }}
      >
        <Box style={EnterpriseLogoBox}>ID/PW 로그인</Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: '1rem',
          }}
        >
          비밀번호를 잊으셨나요? 그룹 관리자에게 문의하세요.
        </Box>
        <Box style={EnterpriseTextFieldBox1}>
          <Box
            style={{
              whiteSpace: 'nowrap',
              fontSize: '2rem',
              marginRight: '2rem',
              width: '10rem',
              color: '#516561',
              fontWeight: 'bold',
            }}
          >
            아이디
          </Box>
          <Box style={{width: '100%', maxWidth: '30rem'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              fullWidth
              sx={{
                maxWidth: '30rem',
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #4FD7C3',
                  },
                  '&:hover fieldset': {
                    border: '2px solid #4FD7C3',
                  },
                  '&.Mui-focused fieldset': {
                    border: '2px solid #4FD7C3',
                  },
                },
              }}
              label={idText == '' ? '아이디를 입력해주세요.' : ''}
              InputLabelProps={{
                style: {
                  color: '#879390',
                  fontSize: 'calc(0.5rem + 0.7vw)',
                },
                shrink: false,
              }}
              InputProps={{style: {fontSize: 'calc(0.5rem + 0.7vw)'}}}
              value={idText}
              onChange={handleIdText}
            ></TextField>
            {showIDError()}
          </Box>
        </Box>
        <Box style={EnterpriseTextFieldBox2}>
          <Box
            style={{
              whiteSpace: 'nowrap',
              fontSize: '2rem',
              marginRight: '2rem',
              width: '10rem',
              color: '#516561',
              fontWeight: 'bold',
            }}
          >
            비밀번호
          </Box>
          <Box style={{width: '100%', maxWidth: '30rem'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              onKeyDown={onKeyPressed}
              sx={{
                maxWidth: '30rem',
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #4FD7C3',
                  },
                  '&:hover fieldset': {
                    border: '2px solid #4FD7C3',
                  },
                  '&.Mui-focused fieldset': {
                    border: '2px solid #4FD7C3',
                  },
                },
              }}
              label={pwText == '' ? '(영어, 숫자 조합 5글자 이상)' : ''}
              InputLabelProps={{
                style: {
                  color: '#879390',
                  fontSize: 'calc(0.5rem + 0.7vw)',
                },
                shrink: false,
              }}
              InputProps={{
                style: {
                  fontSize: 'calc(0.5rem + 0.7vw)',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                      aria-label={
                        showPassword ? '비밀번호 숨기기' : '비밀번호 보기'
                      }
                    >
                      <img
                        src={showPassword ? IconEyeOn : IconEyeOff}
                        alt={
                          showPassword ? '비밀번호 보이기' : '비밀번호 숨기기'
                        }
                        style={{
                          width: '1.2rem',
                          height: '1.2rem',
                          marginRight: '0.5rem',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={pwText}
              onChange={handlePwText}
            />
            {showPWError()}
          </Box>
        </Box>

        <Box style={EnterpriseEndBox}>
          <Button
            style={{
              color: 'white',
              backgroundColor: '#4FD7C3',
              paddingLeft: '4rem',
              paddingRight: '4rem',
              fontSize: '1.2rem',
              borderRadius: '0.7rem',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
            }}
            onClick={() => {
              callEnterpriseLoginAPI();
            }}
          >
            로그인
          </Button>
        </Box>
        <Box style={EnterpriseNoticeBox}>
          <Box>
            <Box>
              ※아이디/비밀번호는 그룹 관리자 또는 고객센터에 문의하세요.
            </Box>
            <Box>※문의 : support@twoblockai.com</Box>
          </Box>
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default LoginPage;
