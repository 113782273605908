import PropTypes from 'prop-types';
import {Box, Button, Typography} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TaskView = props => {
  const dateLeft = StringUtils.getDateDiff(props.task.end_time);
  const startDateLeft = StringUtils.getDateDiff(props.task.start_time);
  const endTimeLeft = StringUtils.getTimeDiff(props.task.end_time);
  function showLabel() {
    if (props.task.status == '진행중') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: 'white',
              backgroundColor: '#4FD7C3',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>
          <Box
            style={{
              color: '#FF9900',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #FF9900',
            }}
          >
            진행중
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #279EFF',
            }}
          >
            {'AI피드백'}
          </Box>
          <Box
            style={{
              display: props.task.peer_review_status == 0 ? 'none' : 'block',
              color: props.task.peer_review_status == 2 ? '#4FD7C3' : '#B1B1B1',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border:
                props.task.peer_review_status == 2
                  ? '1px solid #4FD7C3'
                  : '1px solid #B1B1B1',
            }}
          >
            {'동료평가'}
          </Box>
          <Box
            style={{
              display: props.task.max_try != 0 ? '' : 'none',
              color: '#00C500',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #00C500',
            }}
          >
            {props.task.max_try}회
          </Box>
        </Box>
      );
    } else if (props.task.status == '대기') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#EBEEF4',
              backgroundColor: '#279EFF',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{startDateLeft}
          </Box>
          <Box
            style={{
              color: '#68737D',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #68737D',
            }}
          >
            대기
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #279EFF',
            }}
          >
            {'AI피드백'}
          </Box>
          <Box
            style={{
              display: props.task.peer_review_status == 0 ? 'none' : 'block',
              color: props.task.peer_review_status == 2 ? '#4FD7C3' : '#B1B1B1',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border:
                props.task.peer_review_status == 2
                  ? '1px solid #4FD7C3'
                  : '1px solid #B1B1B1',
            }}
          >
            {'동료평가'}
          </Box>
          <Box
            style={{
              display: props.task.max_try != 0 ? '' : 'none',
              color: '#00C500',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #00C500',
            }}
          >
            {props.task.max_try}회
          </Box>
        </Box>
      );
    } else if (props.task.status == '종료') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#87929D',
              backgroundColor: '#ffe4e4',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            {endTimeLeft < 0 ? `D+${Math.abs(dateLeft)}` : `D-${dateLeft}`}
          </Box>
          <Box
            style={{
              display: props.task.task_share == 1 ? 'none' : '',
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
            }}
          >
            종료
          </Box>
          <Box
            style={{
              display:
                props.task.task_type == 1 && props.task.task_share != 1
                  ? ''
                  : 'none',
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
            }}
          >
            {'AI피드백'}
          </Box>

          <Box
            style={{
              display: [1, 2, 3, 4].includes(props.task.peer_review_status)
                ? ''
                : 'none',
              color: props.task.peer_review_status == 2 ? '#4FD7C3' : '#B1B1B1',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border:
                props.task.peer_review_status == 2
                  ? '1px solid #4FD7C3'
                  : '1px solid #B1B1B1',
            }}
          >
            {'동료평가'}
          </Box>

          <Box
            style={{
              display: props.task.peer_review_status == 5 ? '' : 'none',
              color: '#4FD7C3',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #4FD7C3',
            }}
          >
            {'동료평가'}
          </Box>
          <Box
            style={{
              display: props.task.max_try != 0 ? '' : 'none',
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
            }}
          >
            수정 {props.task.max_try}회
          </Box>
          <Box
            style={{
              display: props.task.task_share == 1 ? '' : 'none',
              color: '#ff9900',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              border: '1px solid #ff9900',
              fontSize: '0.7rem',
            }}
          >
            {'공유 완료'}
          </Box>
          <Box
            style={{
              display: props.task.zip_status == 2 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              border: '1px solid #279EFF',
              fontSize: '0.7rem',
            }}
          >
            {'평가 리포트'}
          </Box>
        </Box>
      );
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '0.5rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
        <Box style={{display: 'flex', gap: '0.5rem'}}>{showLabel()}</Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {props.task.task_name}
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          {props.task.class_name_list
            .sort((a, b) => {
              if (a > b) return 1;
              if (a < b) return -1;
              return 0;
            })
            .map((value, idx) => {
              let classBreak = '';
              if (idx != props.task.class_name_list.length - 1) {
                classBreak = (
                  <Box
                    style={{
                      backgroundColor: '#A8B1B7',
                      width: '1px',
                      height: '0.5rem',
                      marginLeft: '0.5rem',
                    }}
                  />
                );
              }
              return (
                <Box
                  key={'class' + idx}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <Box
                    style={{
                      color: '#636C73',
                      fontSize: '0.875rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {value}
                  </Box>
                  {classBreak}
                </Box>
              );
            })}
        </Box>
        <Box
          style={{
            color: '#636C73',
            fontSize: '0.875rem',
            fontWeight: 'bold',
          }}
        >
          과제 기간: {props.task.start_time.slice(5, -3) + ' ~ '}
          {props.task.end_time.slice(5, -3)}{' '}
          {props.task.tz && props.task.tz !== 'Asia/Seoul'
            ? ' (' + props.task.tz.split('/')[1].replace('_', ' ') + ') '
            : ''}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Button
          style={{
            display:
              props.task.status == '종료' || props.task.status == '대기'
                ? ''
                : 'none',
            color: '#B1B1B1',
            fontSize: '0.875rem',
            border: '1.5px solid #A8B1B7',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1.5rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            props.removeEvent(props.task);
          }}
        >
          {'삭제'}
        </Button>
        <Button
          style={{
            color:
              props.task.status == '종료'
                ? '#35D2BB'
                : props.task.status == '대기'
                ? '#279EFF'
                : '#FF9900',
            fontSize: '0.875rem',
            border:
              props.task.status == '종료'
                ? '1.5px solid var(--gray-2, #35D2BB)'
                : props.task.status == '대기'
                ? '1.5px solid var(--gray-2, #279EFF)'
                : '1.5px solid var(--gray-2, #FF9900)',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            props.detailEvent(props.task);
          }}
        >
          {props.task.status == '종료'
            ? '결과 확인'
            : props.task.status == '대기'
            ? '과제 내용 변경'
            : '진행 상황'}
        </Button>
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  detailEvent: PropTypes.func,
  removeEvent: PropTypes.func,
};
export default TaskView;
