import React, {useEffect, useState, useRef} from 'react';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import ToastPopup from 'view/popup/ToastPopup';

const SuccessPage = () => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const effectCalled = useRef(false); // ✅ `useEffect` 중복 실행 방지
  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);

  // ✅ 결제 성공 시 알림 및 페이지 이동 처리
  function handlePayResult(text) {
    setToastText(text);
    setToastVisibility(true);

    setTimeout(() => {
      setToastVisibility(false);
      gotoMypage(); // ✅ 2.5초 후 마이페이지 이동
    }, 2500);
  }

  // ✅ 마이페이지 (개인 결제 내역)로 이동
  const gotoMypage = () => {
    navigate('/mypage', {
      replace: true,
      state: {
        fragmentNum: 2,
        tabStatus: 3, // ✅ 개인 결제 내역 탭
      },
    });
  };

  // ✅ 결제 정보 전송 (API 호출)
  async function purchaseItem(
    user_id,
    item_id,
    paymentKey,
    orderId,
    amount,
    start_date,
    end_date,
  ) {
    try {
      const response = await APIUtils.PassGroupBuy(
        user_id,
        item_id,
        'toss',
        paymentKey,
        orderId,
        amount,
        start_date,
        end_date,
      );

      if (response.data.ret_code === 1000) {
        handlePayResult(
          '결제가 완료되었습니다. 계정관리 > 이용권 결제 내역에서 영수증을 확인하세요.',
        );
      } else {
        handlePayResult('결제가 실패하였습니다. 고객센터로 문의해 주세요.');
      }
    } catch (err) {
      handlePayResult('결제가 실패하였습니다. 고객센터로 문의해 주세요.');
      console.error('구매 처리 중 에러 발생:', err);
    }
  }

  // ✅ URL에서 결제 정보 파라미터 추출 및 처리
  useEffect(() => {
    if (effectCalled.current) return;
    effectCalled.current = true; // ✅ `useEffect` 중복 실행 방지

    const urlParams = new URLSearchParams(window.location.search);
    const itemId = urlParams.get('itemId');
    const paymentKey = urlParams.get('paymentKey');
    const orderId = urlParams.get('orderId');
    const amount = urlParams.get('amount');
    const start_date = urlParams.get('start_date');
    const end_date = urlParams.get('end_date');

    if (itemId && paymentKey && orderId && amount) {
      purchaseItem(
        authReducer.user_id,
        itemId,
        paymentKey,
        orderId,
        amount,
        start_date,
        end_date,
      );
    }
  }, [authReducer.user_id]); // ✅ `authReducer.user_id` 변경 시 실행

  return (
    <Box>
      <ToastPopup text={toastText} visibility={toastVisibility} />
    </Box>
  );
};

export default SuccessPage;
