import {useState, useEffect} from 'react';
import {Box, MenuItem, Select, FormControl} from '@mui/material';
import PropTypes from 'prop-types';

// 📌 표준 시간대 정보 (도시명 / 표준 시간대 코드 / UTC 오프셋)
const timeZoneMap = {
  '서울, 대한민국 (UTC+9)': 'Asia/Seoul',
  '도쿄, 일본 (UTC+9)': 'Asia/Tokyo',
  '베이징, 중국 (UTC+8)': 'Asia/Shanghai',
  '싱가포르 (UTC+8)': 'Asia/Singapore',
  '방콕, 태국 (UTC+7)': 'Asia/Bangkok',
  '하노이, 베트남 (UTC+7)': 'Asia/Ho_Chi_Minh',
  '자카르타, 인도네시아 (UTC+7)': 'Asia/Jakarta',
  '쿠알라룸푸르, 말레이시아 (UTC+8)': 'Asia/Kuala_Lumpur',
  '마닐라, 필리핀 (UTC+8)': 'Asia/Manila',
  '뉴델리, 인도 (UTC+5:30)': 'Asia/Kolkata',
  '카라치, 파키스탄 (UTC+5)': 'Asia/Karachi',
  '다카, 방글라데시 (UTC+6)': 'Asia/Dhaka',
  '두바이, UAE (UTC+4)': 'Asia/Dubai',
  '이스탄불, 터키 (UTC+3)': 'Europe/Istanbul',
  '요하네스버그, 남아프리카공화국 (UTC+2)': 'Africa/Johannesburg',
  '카이로, 이집트 (UTC+2)': 'Africa/Cairo',
  '런던, 영국 (UTC+0)': 'Europe/London',
  '파리, 프랑스 (UTC+1)': 'Europe/Paris',
  '베를린, 독일 (UTC+1)': 'Europe/Berlin',
  '모스크바, 러시아 (UTC+3)': 'Europe/Moscow',
  '뉴욕, 미국 (UTC-5)': 'America/New_York',
  '로스앤젤레스, 미국 (UTC-8)': 'America/Los_Angeles',
  '리우데자네이루, 브라질 (UTC-3)': 'America/Sao_Paulo',
  '시드니, 호주 (UTC+10)': 'Australia/Sydney',
  '오클랜드, 뉴질랜드 (UTC+12)': 'Pacific/Auckland',
};

const TimeZoneSelector = ({onTimeZoneSelect, defaultValue}) => {
  const getCityByTimeZone = timeZone =>
    Object.keys(timeZoneMap).find(city => timeZoneMap[city] === timeZone) || '';

  const [selectedCity, setSelectedCity] = useState(
    getCityByTimeZone(defaultValue),
  );

  useEffect(() => {
    if (defaultValue) {
      const city = getCityByTimeZone(defaultValue);
      setSelectedCity(city);
    }
  }, [defaultValue]);

  const handleTimeZoneChange = event => {
    const city = event.target.value;
    setSelectedCity(city);
    if (onTimeZoneSelect) {
      onTimeZoneSelect(timeZoneMap[city]);
    }
  };

  return (
    <Box>
      <FormControl
        fullWidth
        variant="outlined"
        style={{minWidth: '15rem', backgroundColor: '#EBFBF9'}}
      >
        <Select
          value={selectedCity}
          onChange={handleTimeZoneChange}
          style={{fontSize: '0.875rem', height: '2rem'}}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '20rem',
              },
            },
          }}
        >
          {Object.keys(timeZoneMap).map(city => (
            <MenuItem
              key={city}
              value={city}
              style={{fontSize: '0.875rem', padding: '0.3rem 1rem'}}
            >
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

TimeZoneSelector.propTypes = {
  onTimeZoneSelect: PropTypes.func.isRequired, //
  defaultValue: PropTypes.string,
};

export default TimeZoneSelector;
