import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Box, Button, TextField, CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import Tagview from 'view/mypage/TagView1';
import APIUtils from 'common/utils/APIUtils';

const ItemView = props => {
  const [loading, setLoading] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [tagText, setTagText] = useState('');
  const authReducer = useSelector(state => state.authReducer);
  const handleTag = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 25) {
      inputVal = inputVal.substring(0, 25);
    }
    setTagText(inputVal);
  };
  function createTag() {
    const currentTag = tagText.trim();
    if (currentTag != '') {
      setTagText('');
      if (tagList.length < 5) {
        tagList.push(currentTag);
      }
    }
  }
  function removeTag(inputTag) {
    let newArray = tagList.filter(item => item != inputTag);
    setTagList(newArray);
  }
  function libraryGPTHashTag() {
    setLoading(true);
    const createLibraryContextApi = async () => {
      try {
        const response = await APIUtils.LibraryHashtag(
          props.library.task_janre,
          props.library.target_age,
          props.library.task_message,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    createLibraryContextApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setTagList(res.data.tags);
      }
      setLoading(false);
    });
  }
  function createGptButton() {
    if (loading) {
      return (
        <Box
          style={{color: '#35D2BB', marginLeft: '1rem', marginTop: '0.5rem'}}
        >
          <CircularProgress size="2.5rem" color="inherit" thickness={5} />
        </Box>
      );
    } else {
      return (
        <Button
          style={{
            backgroundColor: '#35D2BB2E',
            borderRadius: '0.6rem',
            padding: '0.5rem 1rem',
            color: '#35D2BB',
            fontWeight: 'bold',
            fontSize: '0.875rem',
            border: '1px solid #EBEEF4',
          }}
          onClick={() => {
            libraryGPTHashTag();
          }}
        >
          태그 생성
        </Button>
      );
    }
  }
  useEffect(() => {
    setTagText('');
    setTagList([]);
  }, [props.visibility]);
  if (!props.visibility || props.library == undefined) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{display: 'flex', marginTop: '2rem'}}>
          <Box
            style={{
              flex: 1,
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: 'black',
              borderBottom: '1px solid black',
            }}
          >
            {props.library.task_name}
          </Box>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{
              color: '#717171',
              fontSize: '1.5rem',
              height: '2.8rem',
            }}
          >
            ✖
          </Button>
        </Box>
        <Box style={{marginTop: '1.5rem', fontWeight: 'bold'}}>
          관련 태그 (최대 5개)
        </Box>
        <Box style={{marginTop: '0.5rem', color: '#68737D'}}>
          다른 선생님들이 과제를 쉽게 찾을 수 있도록 관련 태그를 입력해 주세요.
        </Box>
        <Box
          style={{
            color: '#68737D',
          }}
        >
          “태그 생성”을 누르면 과제 주제를 고려한 AI가 자동으로 생성해 드려요.
        </Box>

        <Box
          style={{
            display: 'flex',
            alignItems: 'center', // 수직 중앙 정렬
            gap: '1rem', // 요소 간 간격 추가
            marginTop: '1rem',
            width: '90%', // 전체 너비 활용
          }}
        >
          {/* GPT 버튼 */}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '2.5rem', // 높이 균형 맞춤
            }}
          >
            {createGptButton()}
          </Box>

          {/* 태그 입력 필드 (조금 길게 조정) */}
          <TextField
            autoComplete="off"
            variant="outlined"
            label={tagText == '' ? '관련 태그 직접 입력' : ''}
            sx={{
              flex: 1, // 남은 공간을 최대한 활용하여 길이 조정
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #EBEEF4',
                },
                '&:hover fieldset': {
                  border: '1px solid #EBEEF4',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #EBEEF4',
                },
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: '0.8rem',
                width: '10rem',
                color: '#A9A3A3',
                alignItems: 'center', // 수직 중앙 정렬
              },
              shrink: false,
            }}
            InputProps={{
              style: {
                color: 'black',
                backgroundColor: '#F7F8FA',
                fontSize: '0.8rem',
                width: '10rem',
                display: 'flex',
                alignItems: 'center', // 수직 중앙 정렬
              },
            }}
            value={tagText}
            onChange={handleTag}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                createTag();
              }
            }}
          />
        </Box>

        <Box
          style={{
            display: 'flex',
            minHeight: '1.5rem',
            marginTop: '1rem',
            gap: '0.5rem',
            flexWrap: 'wrap',
          }}
        >
          {tagList.map((item, idx) => (
            <Tagview key={'tagView' + idx} tag={item} deleteEvent={removeTag} />
          ))}
        </Box>
        <Box style={{marginTop: '2rem', display: 'flex'}}>
          <Button
            disabled={
              tagList.length === 0 ||
              !authReducer.group_id ||
              authReducer.group_id <= 0
            }
            style={{
              display:
                !authReducer.group_id || authReducer.group_id <= 0
                  ? 'none'
                  : 'block',
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: tagList.length == 0 ? '#B1B1B1' : '#279EFF',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.shareGroupEvent(tagList);
            }}
          >
            그룹에게 공유
          </Button>
          <Button
            disabled={tagList.length == 0}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: tagList.length == 0 ? '#B1B1B1' : '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.shareEvent(tagList);
            }}
          >
            전체에게 공유
          </Button>
          <Button
            disabled={tagList.length == 0}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: tagList.length == 0 ? '#B1B1B1' : '#00C500',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.shareKeewiEvent(tagList);
            }}
          >
            키위런까지 공유
          </Button>
        </Box>
        <Box
          style={{
            color: '#4FD7C3',
            marginTop: '1rem',
            fontSize: '0.875rem',
            textAlign: 'center',
          }}
        >
          키위런까지 공유하면, 키위티의 공유뿐만 아니라 AI 글쓰기 연습에도
          활용됩니다.
        </Box>
        <Box
          style={{
            color: '#4FD7C3',
            marginTop: '0.5rem',
            fontSize: '0.875rem',
            textAlign: 'center',
          }}
        >
          배포가 완료되기까지 약간의 시간이 소요됩니다.
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  library: PropTypes.object,
  cancelEvent: PropTypes.func,
  shareEvent: PropTypes.func,
  shareGroupEvent: PropTypes.func,
  shareKeewiEvent: PropTypes.func,
};
export default ItemView;
