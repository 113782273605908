import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';

const ItemView = props => {
  const [classExist, setClassExist] = useState(false);
  const [classText, setClassText] = useState('');
  const handleClass = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 15) {
      inputVal = inputVal.substring(0, 15);
    }
    setClassText(inputVal);
  };

  const [memoText, setMemoText] = useState('');
  const handleMemo = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 30) {
      inputVal = inputVal.substring(0, 30);
    }
    setMemoText(inputVal);
  };

  function showClassInfo() {
    if (classExist) {
      return (
        <Box style={{color: 'red', fontSize: '0.875rem'}}>
          중복된 class 이름입니다.
        </Box>
      );
    }
    return (
      <Box>
        <Box style={{color: '#68737D', fontSize: '0.875rem'}}>
          학생들을 배정할 class를 생성하세요. 과제를 할당할 때 편리하게 사용할
          수 있습니다.
        </Box>
        <Box style={{color: '#68737D', fontSize: '0.875rem'}}>
          학생은 최대 3개의 class에 동시 소속될 수 있습니다.
        </Box>
      </Box>
    );
  }
  useEffect(() => {
    if (props.classList == undefined || props.classList?.length == 0) {
      return;
    }
    if (props.classList.includes(classText)) {
      setClassExist(true);
    } else if (classExist) {
      setClassExist(false);
    }
  }, [classText]);
  useEffect(() => {
    setClassExist(false);
    setClassText('');
    setMemoText('');
  }, [props.visibility]);
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{display: 'flex', justifyContent: 'end'}}>
          <IconButton onClick={props.cancelEvent}>
            <img src={IconCancel} />
          </IconButton>
        </Box>
        <Box
          style={{
            marginBottom: '0.5rem',
          }}
        >
          <TextField
            autoComplete="off"
            variant="filled"
            fullWidth
            label={classText == '' ? 'Class 이름' : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderBottom: '1px solid black',
                },
                '&:hover fieldset': {
                  borderBottom: '1px solid black',
                },
                '&.Mui-focused fieldset': {
                  borderBottom: '1px solid black',
                },
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: '1.5rem',
                color: '#A8B1B7',
                fontWeight: 'bold',
              },
              shrink: false,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: classExist ? 'red' : 'black',
                backgroundColor: 'white',
                fontSize: '1.5rem',
                borderBottom: classExist ? '1px solid red' : '1px solid black',
                fontWeight: 'bold',
              },
            }}
            value={classText}
            onChange={handleClass}
          ></TextField>
        </Box>
        {showClassInfo()}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <Box style={{marginTop: '1.5rem', fontWeight: 'bold'}}>
            class 메모
          </Box>
          <Box>
            <span style={{color: '#35D2BB'}}>{memoText.length}</span>
            /30
          </Box>
        </Box>
        <Box style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
          <TextField
            autoComplete="off"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
                '&:hover fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
              },
            }}
            variant="outlined"
            fullWidth
            label={memoText == '' ? 'class 정보를 간단하게 작성해 주세요.' : ''}
            InputLabelProps={{
              style: {
                color: '#A8B1B7',
              },
              shrink: false,
            }}
            InputProps={{
              style: {
                backgroundColor: '#F7F8FA',
                fontWeight: 500,
              },
            }}
            multiline
            value={memoText}
            onChange={handleMemo}
            onKeyDown={e => {
              if (e.key == 'Enter') {
                e.preventDefault();
              }
            }}
          ></TextField>
        </Box>
        <Box style={{marginTop: '3rem'}}>
          <Button
            disabled={classExist || classText == ''}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor:
                classExist || classText == '' ? '#49545C' : '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.createEvent(classText, memoText);
            }}
          >
            {'CLASS 생성하기'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  classList: PropTypes.array,
  createEvent: PropTypes.func,
};
export default ItemView;
