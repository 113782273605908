import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {StringUtils} from 'common/utils/StringUtils';
import {useSelector} from 'react-redux';

export const TaskEvalView = props => {
  const authReducer = useSelector(state => state.authReducer);
  function encodeBase64(input) {
    try {
      if (typeof window !== 'undefined') {
        // 브라우저 환경
        return window
          .btoa(unescape(encodeURIComponent(input)))
          .replace(/\+/g, '-')
          .replace(/\//g, '_'); // URLSafe 변환
      } else {
        // Node.js 환경
        return Buffer.from(input, 'utf-8')
          .toString('base64')
          .replace(/\+/g, '-')
          .replace(/\//g, '_'); // URLSafe 변환
      }
    } catch (e) {
      console.error('Base64 인코딩 중 오류 발생:', e);
      return '';
    }
  }

  function clickPeerReviewResult() {
    // 새 창에 URL과 쿼리 문자열 포함
    const my_id = 0;
    const my_name = '선생님';
    const task_id = props.task_id;
    const student_id = props.submitTask.student_id;

    function getRandomChar() {
      const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const encodedParams = encodeBase64(
      `${my_name}:${my_id}:${task_id}:${student_id}`,
    );
    const randomPrefix = getRandomChar();
    const randomSuffix = getRandomChar();
    const secureEncodedParams = randomPrefix + encodedParams + randomSuffix;

    const url = `/peer/review?gul=${secureEncodedParams}`;
    window.open(url, '_blank', 'noopener, noreferrer');
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
        <Box
          style={{
            display: 'flex',
            gap: '0.5rem',
            color: '#636C73',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          {StringUtils.getDateTimeKr(props.submitTask.submit_time)}
          <span
            style={{
              color: 'black',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              alignItems: 'center', // 수직 중앙 정렬
              fontSize: '1rem',
            }}
          >
            {props.submitTask.student_name}
          </span>

          {props.submitTask.class_name_list
            .sort((a, b) => {
              if (a > b) return 1;
              if (a < b) return -1;
              return 0;
            })
            .map((value, idx) => {
              let classBreak = '';
              if (idx != props.submitTask.class_name_list.length - 1) {
                classBreak = <Box style={{marginLeft: '0.5rem'}}>{' | '}</Box>;
              }
              return (
                <Box
                  key={'class' + idx}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <Box>{value}</Box>
                  {classBreak}
                </Box>
              );
            })}
          {props.submitTask.peer_review_count === 0
            ? ''
            : ` 동료평가 ${props.submitTask.peer_review_count}명`}

          <Box
            style={{
              display: props.submitTask.edit_time ? '' : 'none',
              marginLeft: '0.1rem',
              textAlign: 'center',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              color: '#FF9900',
            }}
          >
            {' '}
            평가 저장:{' '}
            {StringUtils.getDateTimeString(props.submitTask.edit_time)}
          </Box>
        </Box>
        <Box
          style={{
            fontSize: '1.125rem',
            fontWeight: 'bold',
            color:
              props.submitTask.requirement_status === 0 ? '#FF9900' : 'black',
          }}
        >
          {props.submitTask.title + ' (' + props.submitTask.score + '점, '}
          {props.submitTask.eval_try - 1 + '번 수정'}
          {props.submitTask.requirement_status === 0 ? ', 미충족)' : ')'}
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Button
            style={{
              color: '#262C31',
              fontSize: '0.875rem',
              border: '1.5px solid var(--gray-2, #B1B1B1)',
              borderRadius: '1.925rem',
              marginTop: '0.5rem',
              padding: '0.5rem 1rem',
              backgroundColor: 'white',
              whiteSpace: 'nowrap',
            }}
            onClick={() => {
              props.resultEvent(props.submitTask.student_id, props.allTasks);
            }}
          >
            {'중간 제출 확인'}
          </Button>
          <Button
            style={{
              display: props.peerReviewChecked ? 'block' : 'none',
              color: '#279EFF',
              fontSize: '0.875rem',
              border: '1.5px solid var(--gray-2, #279EFF)',
              borderRadius: '1.925rem',
              marginTop: '0.5rem',
              padding: '0.5rem 1rem',
              backgroundColor: 'white',
              whiteSpace: 'nowrap',
            }}
            onClick={() => {
              clickPeerReviewResult();
            }}
          >
            {'동료 평가 보기'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
TaskEvalView.propTypes = {
  peerReviewChecked: PropTypes.bool,
  allTasks: PropTypes.array,
  submitTask: PropTypes.object,
  resultEvent: PropTypes.func,
  endTime: PropTypes.string,
  viewEvent: PropTypes.func,
  task_id: PropTypes.number,
};
export default TaskEvalView;
