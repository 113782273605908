import PropTypes from 'prop-types';
import {Box, FormControl, Button, TextField} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import backgroundMain2 from 'asset/imageV2/background_main2.png';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export const MarginBoxComponent = props => {
  return (
    <Box
      style={{
        marginBottom: '5%',
        marginTop: '2%',
        width: '85%',
        maxWidth: '200rem',
      }}
    >
      {props.children}
    </Box>
  );
};
MarginBoxComponent.propTypes = {
  children: PropTypes.element,
};

export const TitleComponent = props => {
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#F0FBFA',
        backgroundImage: `url(${backgroundMain2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '7rem',
        paddingBottom: '10rem',
        marginBottom: '-5rem',
      }}
    >
      <Box style={{fontSize: '2.5rem', fontWeight: 'bold'}}>{props.text1}</Box>
      <Box
        style={{
          fontSize: '1.2rem',
          fontWeight: 'normal',
          marginLeft: '3rem',
          marginRight: '3rem',
          marginBottom: '3rem',
          marginTop: '1rem',
        }}
      >
        {props.text2}
      </Box>
    </Box>
  );
};
TitleComponent.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
};
export const TitleComponent2 = props => {
  const navigate = useNavigate();
  function goBackHome() {
    navigate('/mypage', {
      replace: true,
      state: {
        fragmentNum: 3,
      },
    });
  }
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#F0FBFA',
        backgroundImage: `url(${backgroundMain2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '2rem',
        paddingBottom: '2rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            flex: 1,
          }}
        >
          <Box
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            {props.text1}
          </Box>
          <Box
            style={{
              textAlign: 'center',
              color: '#68737D',
              marginTop: '1rem',
              marginBottom: '3rem',
            }}
          >
            {props.text2}
          </Box>
        </Box>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            right: '2rem',
            transform: 'translateY(-50%)',
            width: 'auto',
            height: 'auto',
            zIndex: 10,
          }}
        >
          <Button
            disabled={false}
            style={{
              color: '#4FD7C3',
              backgroundColor: '#F6F6F6',
              fontSize: '0.875rem',
              border: '1px solid #4FD7C3',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '7rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => goBackHome()}
          >
            돌아가기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

TitleComponent2.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
};
export const TitleComponent2_2 = props => {
  const navigate = useNavigate();
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#F0FBFA',
        backgroundImage: `url(${backgroundMain2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '2rem',
        paddingBottom: '2rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            flex: 1,
          }}
        >
          <Box
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            {props.text1}
          </Box>
          <Box
            style={{
              textAlign: 'center',
              color: '#68737D',
              marginTop: '1rem',
              marginBottom: '3rem',
            }}
          >
            {props.text2}
          </Box>
        </Box>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            right: '2rem',
            transform: 'translateY(-50%)',
            width: 'auto',
            height: 'auto',
            zIndex: 10,
          }}
        ></Box>
      </Box>
    </Box>
  );
};

TitleComponent2_2.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
};
export const TitleComponent3 = props => {
  return (
    <Box
      style={{
        width: '95%',
        backgroundColor: '#F0FBFA',
        backgroundImage: `url(${backgroundMain2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '2rem',
      }}
    >
      <Box
        style={{fontSize: '1.5rem', marginLeft: '2.5rem', fontWeight: 'bold'}}
      >
        {props.text1}
      </Box>
      <Box
        style={{
          fontSize: '1.2rem',
          fontWeight: 'normal',
          marginLeft: '2.5rem',
          marginRight: '3rem',
          marginBottom: '3rem',
          marginTop: '1rem',
        }}
      >
        {props.text2}
      </Box>
    </Box>
  );
};
TitleComponent3.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
};

export const BookComponent = props => {
  return (
    <Box style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <Box
        style={{
          maxWidth: '70rem',
          minWidth: '30rem',
          width: '80%',
          minHeight: '50px',
          backgroundColor: 'white',
          borderRadius: '1.25rem',
          border: '1px solid var(--01-gray-07, #E9EBED)',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
          paddingLeft: '7%',
          paddingRight: '7%',
          paddingTop: '5rem',
          paddingBottom: '5rem',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
BookComponent.propTypes = {
  children: PropTypes.element,
};
export const WhiteBox = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flex: 1,
      }}
    >
      <Box
        style={{
          width: '90%',
          backgroundColor: 'white',
          borderRadius: '1.25rem',
          border: '1px solid var(--01-gray-07, #E9EBED)',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
WhiteBox.propTypes = {
  children: PropTypes.element,
};
export const CommentBox = props => {
  return (
    <Box
      style={{
        width: '90%',
        backgroundColor: 'white',
        borderRadius: '1.25rem',
        border: '1px solid var(--01-gray-07, #E9EBED)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      {props.children}
    </Box>
  );
};
CommentBox.propTypes = {
  children: PropTypes.element,
};
export const LayerBoxComponent = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          fontWeight: 'bold',
          color: 'black',
          marginLeft: '0.5rem',
        }}
      >
        {props.title}
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        {props.children}
      </Box>
    </FormControl>
  );
};
LayerBoxComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const CenteredButton = props => {
  return (
    <Box
      style={{
        marginTop: '3rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="filled"
        style={{
          cursor: props.disabled ? 'default' : 'pointer',
          color: 'white',
          backgroundColor: props.disabled ? '#87929D' : '#35D2BB',
          borderRadius: '6.25rem',
          padding: '0.9375rem 1.5625rem',
          fontWeight: 'bold',
          fontSize: '1.125rem',
          boxShadow: '0px 5px 10px 3px rgba(0, 0, 0, 0.15)',
          zIndex: 2,
        }}
        onClick={() => {
          props.clickEvent(props.disabled);
        }}
      >
        {props.title}
      </Button>
    </Box>
  );
};
CenteredButton.propTypes = {
  title: PropTypes.string,
  clickEvent: PropTypes.func,
  disabled: PropTypes.bool,
};

export const SingleLineComponent = props => {
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        marginTop: '1rem',
      }}
    >
      <Box
        style={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#ff9900',
          padding: '0.3rem 0.75rem',
          borderRadius: '6.25rem',
          marginRight: '0.625rem',
        }}
      >
        {'STEP ' + props.stepNumber}
      </Box>
      <Box style={{fontSize: '1.375rem', fontWeight: 'bold', color: '#2f3941'}}>
        {props.text}
      </Box>
    </Box>
  );
};
SingleLineComponent.propTypes = {
  stepNumber: PropTypes.string,
  text: PropTypes.string,
};
export const SingleLineTitle = props => {
  return (
    <Box style={{margin: '1rem 0rem'}}>
      <Box
        style={{
          marginTop: '2rem',
          display: props.editable ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Box
            style={{
              fontSize: '1.5625rem',
              fontWeight: 'bold',
              color: 'black',
              marginLeft: '0.5rem',
            }}
          >
            {props.title}
          </Box>
        </Box>
        <Box style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#ff9900'}}>
          {'점수 : ' + props.tScore + '점'}
        </Box>
      </Box>
      <Box
        style={{
          display: props.editable ? 'flex' : 'none',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.title}
          onChange={props.handleInput}
        ></TextField>
        <Button
          style={{
            backgroundColor: '#4FD7C3',
            color: 'white',
            fontSize: 'bold',
            whiteSpace: 'nowrap',
            marginLeft: '1rem',
          }}
          onClick={() => {
            props.changeEditStatus(false);
          }}
        >
          변경하기
        </Button>
      </Box>
    </Box>
  );
};
SingleLineTitle.propTypes = {
  title: PropTypes.string,
  tScore: PropTypes.number,
  handleInput: PropTypes.func,
  editable: PropTypes.bool,
  changeEditStatus: PropTypes.func,
};

export const SingleLineTitle2 = props => {
  return (
    <Box style={{margin: '1rem 0rem'}}>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'flex-start',
        }}
      >
        <Box style={{display: 'flex', alignItems: 'flex-start'}}>
          <Box
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              color: 'black',
              marginLeft: '0.5rem',
            }}
          >
            {props.title}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
SingleLineTitle2.propTypes = {
  title: PropTypes.string,
};

export const CustomTextField1 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title}{' '}
          <span
            style={{display: props.isRequired ? '' : 'none', color: '#35D2BB'}}
          >
            *
          </span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            height: '3.3rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField1.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  isRequired: PropTypes.bool,
};
export const CustomTextField2 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#35D2BB'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            zIndex: 3,
            right: '0.5rem',
            bottom: '1rem',
          }}
        >
          <span style={{color: '#35D2BB'}}>{props.inputText.length}</span>
          <span style={{color: '#A8B1B7'}}>/{props.maxCount}</span>
        </Box>
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            height: '3.3rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField2.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  maxCount: PropTypes.string,
};
export const CustomTextField3 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#35D2BB'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            zIndex: 3,
            right: '0.5rem',
            bottom: '1rem',
          }}
        >
          <span style={{color: '#35D2BB'}}>{props.inputText.length}</span>
          <span style={{color: '#A8B1B7'}}>/{props.maxCount}</span>
        </Box>
        <TextField
          autoComplete="off"
          multiline
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },
              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
              whiteSpace: 'pre-wrap',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          rows={20}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField3.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  maxCount: PropTypes.string,
};
