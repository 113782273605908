import React, {useState, useEffect} from 'react';
import {Box, Button, IconButton, Switch} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import {styled} from '@mui/material/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ko} from 'date-fns/esm/locale';
import emojiRegex from 'emoji-regex';

const ExampleCustomInput = React.forwardRef((props, ref) => (
  <button
    style={{
      backgroundColor: '#F7F8FA',
      border: '0px solid black',
      borderRadius: '0.625rem',
      padding: '0.5rem 1rem',
    }}
    className="custom-input"
    onClick={props.onClick}
    ref={ref}
  >
    {props.value}
  </button>
));
ExampleCustomInput.displayName = 'CalendarInput';
ExampleCustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

const CustomSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  width: '2.875rem',
  height: '1.25rem',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(1.5rem)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#35D2BB',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: '1.1rem',
    height: '1.1rem',
    marginTop: '0.1rem',
    marginLeft: '0.1rem',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#A7A7A7',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ItemView = props => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [teacherComment, setTeacherComment] = useState(
    props.peerEvalTeacherMsg || '',
  );

  const defaultPeerEvalItems = [
    '1. 내용구성 : 서론, 본론, 결론이 명확하고 글의 흐름이 자연스러운가요?',
    '2. 일관성과 문법 : 올바른 맞춤법과 문법을 사용했나요?',
    '3. 창의성 : 독창적인 아이디어와 표현을 사용했나요?',
  ];
  const [peerEvalItems, setPeerEvalItems] = useState(defaultPeerEvalItems); // 초기 입력 필드 1개

  const handlePeerEvalItemsChange = (index, event) => {
    const newInputs = [...props.peerEvalItems];
    newInputs[index] = event.target.value; // 입력값 그대로 저장
    const filteredInputs = newInputs.filter(item => item !== '');
    props.setPeerEvalItems(filteredInputs);
  };

  const addPeerEvalItems = () => {
    props.setPeerEvalItems([...props.peerEvalItems, '']); // 새로운 빈 입력 필드 추가
  };

  const resetPeerEvalItems = () => {
    props.setPeerEvalItems(defaultPeerEvalItems); // 새로운 빈 입력 필드 추가
  };

  const removePeerEvalItems = index => {
    if (props.peerEvalItems.length > 1) {
      const newInputs = props.peerEvalItems.filter((_, i) => i !== index);
      props.setPeerEvalItems(newInputs);
    }
  };
  useEffect(() => {
    setTeacherComment(props.peerEvalTeacherMsg || '');
  }, [props.peerEvalTeacherMsg]);

  const handleComment = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();

    if (EMOJI_REGEX.test(inputVal)) {
      alert('이모지는 입력이 불가능합니다.');
      return;
    }
    if (inputVal.length > 500) {
      inputVal = inputVal.substring(0, 500);
    }
    setTeacherComment(inputVal);
    props.setTeacherCommnetForPeerEval(inputVal);
  };

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '1rem',
          gap: '5rem',
        }}
      >
        <Box
          style={{
            flex: 1,
            fontSize: '1.5rem',
            fontWeight: 'bold',
            paddingRight: '1rem',
          }}
        >
          공유 및 동료 평가 설정
        </Box>
        <IconButton onClick={props.cancelEvent}>
          <img src={IconCancel} alt="cancel icon" />
        </IconButton>
      </Box>

      <Box style={{height: '28rem'}}>
        <Box
          style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            borderBottom: '2px solid #A7A7A7',
          }}
        >
          과제명 : {props.titleText}
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            fontWeight: 'bold',
            fontSize: '0.875rem',
            height: '2rem',
          }}
        >
          <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
            <Box style={{width: '4rem', fontWeight: '500'}}>기간 설정</Box>
            <CustomSwitch
              checked={props.peerEvalTimeReserved}
              onChange={props.handlePeerEvalTimeReset}
            />
          </Box>

          {props.peerEvalTimeReserved ? (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                fontSize: '0.75rem',
                color: '#68737D',
              }}
            >
              <DatePicker
                locale={ko}
                dateFormat={'yyyy년 MM월 dd일 HH:mm'}
                shouldCloseOnSelect
                minDate={props.taskStartTime}
                maxDate={props.taskEndTime}
                selected={props.peerEvalStartTime}
                onChange={props.handlePeerEvalStartTimeChange}
                customInput={<ExampleCustomInput />}
                showTimeSelect
                style={{width: '20rem'}}
              />
              <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>~</Box>
              <DatePicker
                locale={ko}
                dateFormat={'yyyy년 MM월 dd일 HH:mm'}
                shouldCloseOnSelect
                minDate={props.peerEvalStartTime}
                maxDate={
                  new Date(
                    new Date(props.taskEndTime).setDate(
                      new Date(props.taskEndTime).getDate() + 7,
                    ),
                  )
                }
                selected={props.peerEvalEndTime}
                onChange={props.handlePeerEvalEndTimeChange}
                customInput={<ExampleCustomInput />}
                showTimeSelect
                style={{width: '20rem'}}
              />
            </Box>
          ) : (
            <Box style={{fontSize: '0.75rem', color: '#68737D'}}>
              ※ 과제 기간 중 직접{' '}
              <font color="red">시작, 중단, 재개, 종료</font> 버튼을 조작할 수
              있습니다.
            </Box>
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            fontWeight: 'bold',
            fontSize: '0.875rem',
            height: '2rem',
          }}
        >
          {/* 기간 설정 & 토글 스위치 */}
          <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
            <Box style={{width: '4rem', fontWeight: '500'}}>댓글 기능</Box>
            <CustomSwitch
              checked={props.peerCommentChecked}
              onChange={props.handlePeerCommentChange}
            />
          </Box>
          <Box
            style={{
              fontSize: '0.75rem',
              color: '#68737D',
              alignItems: 'center',
            }}
          >
            {props.peerCommentChecked
              ? '※ 좋아요 및 댓글 기능이 활성화됩니다.'
              : '※ 댓글 쓰기 기능이 비활성화됩니다.'}
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            fontWeight: 'bold',
            fontSize: '0.875rem',
            height: '2rem',
          }}
        >
          <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
            <Box style={{width: '4rem', fontWeight: '500'}}>평가 기준</Box>
            <CustomSwitch
              checked={props.peerEvalChecked}
              onChange={props.handlePeerEvalChange}
            />
          </Box>
          <Box
            style={{
              display: props.peerEvalChecked ? 'none' : '',
              fontSize: '0.75rem',
              color: '#68737D',
            }}
          >
            ※ 평가 항목을 제시하지 않습니다.
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '0.5rem',
            fontSize: '0.875rem',
            height: '2rem',
          }}
        >
          <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
            <Box style={{width: '4rem', fontWeight: '500'}}>평가 설명</Box>
          </Box>
          <Box
            style={{
              fontSize: '0.75rem',
              fontWeight: 'bold',
              color: '#4FD7C3',
            }}
          >
            {!props.peerEvalChecked && !props.peerCommentChecked
              ? '※ 제출된 친구의 글 읽기만 가능합니다.'
              : props.peerEvalChecked && !props.peerCommentChecked
              ? '※ 동료 평가를 할 수 있지만 댓글과 좋아요는 할 수 없습니다.'
              : !props.peerEvalChecked && props.peerCommentChecked
              ? '※ 동료 평가 없이 댓글과 좋아요를 할 수 있습니다.'
              : '※ 동료 평가, 댓글, 좋아요가 모두 활성화됩니다.'}
          </Box>
        </Box>
        <Box
          style={{
            width: '95%',
            height: props.peerEvalChecked ? '4rem' : '17rem',
            overflow: 'hidden',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            fontSize: '0.875rem',
            color: '#68737D',
            marginBottom: '1rem',
          }}
        >
          <textarea
            value={teacherComment}
            onChange={handleComment}
            placeholder="선생님 코멘트를 입력하세요."
            style={{
              width: '99%',
              height: '95%',
              fontSize: '0.8rem',
              resize: 'none',
              backgroundColor: 'white',
            }}
          />
        </Box>

        <Box
          style={{
            display: props.peerEvalChecked ? '' : 'none',
            width: '95%',
            maxWidth: '500px',
            height: isExpanded ? '10.5rem' : '16.5rem', // 높이 고정
            overflowY: 'auto', // 스크롤 활성화
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            marginBottom: '0.5rem',
          }}
        >
          {props.peerEvalItems.map((input, index) => (
            <Box key={index} style={{display: 'flex', marginBottom: '10px'}}>
              <input
                type="text"
                value={input}
                onChange={event => handlePeerEvalItemsChange(index, event)}
                placeholder={`평가 항목 ${index + 1}`}
                style={{
                  flex: 1,
                  padding: '8px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  fontSize: '0.7rem',
                  width: '100%', // 입력 필드 길게 조정
                }}
              />
              <button
                onClick={() => removePeerEvalItems(index)}
                style={{
                  marginLeft: '5px',
                  fontSize: '0.874rem',
                  coloe: '#B1B1B1',
                  backgroundColor: '#F6F6F6',
                  border: 'none',
                  padding: '8px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                ✖
              </button>
            </Box>
          ))}

          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '90%',
              maxWidth: '500px',
              marginTop: '-0.2rem',
              backgroundColor: '#f9f9f9',
            }}
          >
            {/* ✚ 버튼 (평가 항목 추가) */}
            <button
              onClick={addPeerEvalItems}
              style={{
                color: '#B1B1B1',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                padding: '5px 8px',
                fontSize: '1rem',
                backgroundColor: 'transparent',
              }}
            >
              ✚
            </button>

            {/* ↻ 버튼 (평가 항목 초기화) */}
            <button
              onClick={resetPeerEvalItems}
              style={{
                color: '#B1B1B1',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                padding: '5px 8px',
                fontSize: '1rem',
                backgroundColor: 'transparent',
              }}
            >
              ↻
            </button>

            {/* ✅ 3점, 5점, 10점 선택 (Radio 버튼) */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              {[1, 3, 5, 10].map(score => (
                <label
                  key={score}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.3rem',
                    color: '#555',
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                  }}
                >
                  <input
                    type="radio"
                    value={score}
                    checked={props.peerEvalMaxScore === score}
                    onChange={event =>
                      props.setPeerEvalMaxScore(Number(event.target.value))
                    }
                    style={{cursor: 'pointer'}}
                  />
                  {score === 1 ? '좋아요' : `${score}점 만점`}
                </label>
              ))}
            </div>
          </Box>
        </Box>
      </Box>
      <Box style={{marginTop: '2rem', display: 'flex'}}>
        <Button
          style={{
            flex: '1',
            whiteSpace: 'nowrap',
            color: '#35D2BB',
            backgroundColor: 'white',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            borderRadius: '6.25rem',
            border: '1px solid #35D2BB',
          }}
          onClick={props.previousEvent}
        >
          이전
        </Button>
        <Button
          disabled={
            props.totalStudentCount === 0 &&
            props.selectedClassList?.length == 0
          }
          style={{
            flex: '3',
            whiteSpace: 'nowrap',
            color: 'white',
            backgroundColor:
              props.totalStudentCount === 0 &&
              props.selectedClassList?.length == 0
                ? '#A8B1B7'
                : '#35D2BB',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            marginLeft: '1rem',
            borderRadius: '6.25rem',
          }}
          onClick={props.confirmEvent}
        >
          과제 수정
        </Button>
      </Box>
    </Box>
  );
};

ItemView.propTypes = {
  titleText: PropTypes.string,
  totalStudentCount: PropTypes.number,
  selectedClassList: PropTypes.array,
  previousEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
  peerEvalTimeReserved: PropTypes.bool,
  peerEvalStartTime: PropTypes.instanceOf(Date),
  peerEvalEndTime: PropTypes.instanceOf(Date),
  handlePeerEvalTimeReset: PropTypes.func,
  peerEvalChecked: PropTypes.bool,
  handlePeerEvalChange: PropTypes.func,
  peerCommentChecked: PropTypes.bool,
  handlePeerCommentChange: PropTypes.func,
  peerEvalItems: PropTypes.arrayOf(PropTypes.string),
  setPeerEvalItems: PropTypes.func,
  teacherCommentChecked: PropTypes.bool,
  peerEvalTeacherMsg: PropTypes.string,
  setTeacherCommnetForPeerEval: PropTypes.func,
  handleTeacherCommentCheckedChange: PropTypes.func,
  taskStartTime: PropTypes.instanceOf(Date),
  taskEndTime: PropTypes.instanceOf(Date),
  handlePeerEvalStartTimeChange: PropTypes.func,
  handlePeerEvalEndTimeChange: PropTypes.func,
  peerEvalMaxScore: PropTypes.number,
  setPeerEvalMaxScore: PropTypes.func,
};

export default ItemView;
