import React, {useState, useEffect} from 'react';
import {Box, Button, TextField} from '@mui/material';
import {useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import PropTypes from 'prop-types';
const ItemView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [leaveText, setLeaveText] = useState('');
  const handleLeaveText = event => {
    let inputVal = event.target.value;
    setLeaveText(inputVal);
  };
  useEffect(() => {
    setLeaveText('');
  }, [props.visibility]);
  if (!props.visibility) {
    return <></>;
  }
  function deleteAccount() {
    const deleteAccount = async () => {
      try {
        const response = await APIUtils.AccountLeave(authReducer.user_id);
        if (response.data.ret_code == 1000) {
          props.completeEvent();
        }
      } catch (err) {
        console.log(err);
      }
    };
    deleteAccount();
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{textAlign: 'right'}}>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{color: '#717171', fontSize: '1.5rem'}}
          >
            ✖
          </Button>
        </Box>
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          정말 탈퇴하겠습니까?
        </Box>
        <Box style={{fontSize: '1.1rem'}}>
          탈퇴하면 해당 계정이 삭제되며{' '}
          <span style={{color: 'red', fontWeight: 'bold'}}>
            동일한 SNS 계정으로 재가입은 불가능
          </span>
          합니다. 해당 계정에 저장된 데이터는 전부 삭제 처리되며 복구 또한
          불가능합니다.
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            color: '#4db9b6',
            fontWeight: 'bold',
            fontSize: '1.2rem',
          }}
        >
          {props.sentEmail}
        </Box>
        <Box
          style={{
            borderTop: '2px dotted #717171',
            marginTop: '2rem',
          }}
        ></Box>
        <Box style={{marginTop: '1rem', marginLeft: '0rem'}}>
          {'하단에 '}
          <span style={{color: 'red', fontWeight: 'bold'}}>{'"탈퇴하기"'}</span>
          {'를 입력하면 버튼이 활성화 됩니다.'}
        </Box>
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            marginTop: '0.5rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={leaveText == '' ? '탈퇴하기' : ''}
          InputLabelProps={{
            style: {
              color: '#cdcdcd',
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid #E9EBED',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={leaveText}
          onChange={handleLeaveText}
        ></TextField>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '3rem',
          }}
        >
          <Button
            style={{
              border: '1px solid black',
              width: '47%',
              color: 'black',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            취소
          </Button>
          <Button
            disabled={leaveText != '탈퇴하기'}
            style={{
              backgroundColor: leaveText != '탈퇴하기' ? '#828282' : '#4db9b6',
              width: '47%',
              color: 'white',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              deleteAccount();
            }}
          >
            탈퇴하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  sentEmail: PropTypes.string,
  completeEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
