import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import TaskView3 from './TaskView3';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import ApiLoading from 'view/common/ApiLoading2';
import TaskRemovePopup from 'view/popup/TaskRemovePopup';
import TaskModifyPopup from 'view/popup/TaskModifyPopup';
import {StringUtils} from 'common/utils/StringUtils';
import ToastPopup from 'view/popup/ToastPopup';

export const FragmentView = props => {
  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);

  function reportResult(text) {
    setToastText(text);
    setToastVisibility(true);

    setTimeout(() => {
      setToastVisibility(false);
    }, 2500);
  }

  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [loading, setLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [removingTask, setRemovingTask] = useState(undefined);
  const [taskRemoveVisibility, setTaskRemoveVisibility] = useState(false);

  const [taskModifyVisibility, setTaskModifyVisibility] = useState(false);
  function taskModifyCancel() {
    setTaskModifyVisibility(false);
  }
  function taskModifyConfirm(
    task_name,
    task_message,
    task_janre,
    task_start_time,
    task_end_time,
    task_type,
    include_class_id_list,
    exclude_class_id_list,
    include_student_id_list,
    exclude_student_id_list,
    length_check,
    min_length,
    max_length,
    keyword_check,
    keyword,
    pre_keewichat,
    peerReviewChecked,
    peerCommentChecked,
    peerEvalChecked,
    peerEvalTeacherMsg,
    peerEvalItems,
    peerEvalMaxScore,
    peerEvalTimeReserved,
    peerEvalStartTime,
    peerEvalEndTime,
    maxTryCount,
  ) {
    const modifyTaskApi = async inputTaskID => {
      try {
        const response = await APIUtils.TaskModify(
          authReducer.user_id,
          inputTaskID,
          task_name,
          task_message,
          task_janre,
          StringUtils.getFormattedString(task_start_time),
          StringUtils.getFormattedString(task_end_time),
          task_type,
          include_class_id_list,
          exclude_class_id_list,
          include_student_id_list,
          exclude_student_id_list,
          length_check,
          min_length,
          max_length,
          keyword_check,
          keyword,
          pre_keewichat,
          peerReviewChecked,
          peerCommentChecked,
          peerEvalChecked,
          peerEvalTeacherMsg,
          peerEvalItems,
          peerEvalMaxScore,
          peerEvalTimeReserved,
          StringUtils.getFormattedString(peerEvalStartTime),
          StringUtils.getFormattedString(peerEvalEndTime),
          maxTryCount,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    modifyTaskApi(selectedTask.task_id).then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        reportResult('과제 내용 변경이 완료되었습니다.');
        getTaskList();
      } else {
        reportResult('과제 내용 변경을 실패하였습니다.');
      }
      setTaskModifyVisibility(false);
    });
  }

  function clickTaskDetail(newTask) {
    setSelectedTask(newTask);
    if (newTask.status == '종료') {
      props.finishedTaskClickEvent(newTask.task_id);
      /* 종료후 동료평가 화면 */
    } else if (newTask.status == '대기') {
      setTaskModifyVisibility(true);
    } else {
      // setTaskRunningVisibility(true);
      props.runningTaskClickEvent(newTask.task_id);
      /* 진행중 동료평가 화면 */
    }
  }
  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function getTaskList() {
    const getTask = async () => {
      try {
        const response = await APIUtils.TaskView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getTask().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        const oneWeekFromNow = new Date();
        oneWeekFromNow.setDate(oneWeekFromNow.getDate() - 7); // 현재 날짜로부터 7일 후
        const taskInProgress = res.data.task_list
          .filter(value => {
            if (value.status == '진행중' || value.end_time) {
              const endTime = new Date(value.end_time); // end_time을 Date 객체로 변환
              return endTime >= oneWeekFromNow; // 1주일 이내인지 확인
            }
            return false; // 조건에 맞지 않는 경우 제외
          })
          .sort(function (a, b) {
            if (a.status == '대기' && b.status != '대기') return -1;
            if (a.status != '대기' && b.status == '대기') return 1;
            if (a.status == '진행중' && b.status != '진행중') return -1;
            if (a.status != '진행중' && b.status == '진행중') return 1;

            return new Date(a.end_time) - new Date(b.end_time);
          });
        setTaskList(taskInProgress);
        setTotalPage(
          Math.floor((taskInProgress.length - 1) / countPerPage + 1),
        );
      }
      setLoading(false);
    });
  }
  function removeTask(delTask) {
    setRemovingTask(delTask);
    setTaskRemoveVisibility(true);
  }
  function taskRemoveCancel() {
    setTaskRemoveVisibility(false);
  }
  function taskRemoveConfirm() {
    const deleteTaskApi = async () => {
      try {
        const response = await APIUtils.TaskDelete(
          authReducer.user_id,
          removingTask.task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    deleteTaskApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getTaskList();
      }
      setTaskRemoveVisibility(false);
    });
  }
  useEffect(() => {
    getTaskList();
  }, []);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <TaskRemovePopup
        visibility={taskRemoveVisibility}
        task={removingTask}
        cancelEvent={taskRemoveCancel}
        confirmEvent={taskRemoveConfirm}
      />
      <TaskModifyPopup
        visibility={taskModifyVisibility}
        task_id={selectedTask?.task_id ?? undefined}
        startEditable={true}
        cancelEvent={taskModifyCancel}
        confirmEvent={taskModifyConfirm}
      />
      <ToastPopup visibility={toastVisibility} text={toastText} />
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>최근 과제</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '2.75rem',
        }}
      />
      <Box
        style={{marginBottom: '1rem', color: '#87929D', fontSize: '0.875rem'}}
      >
        최근 1주일 이내에 진행 중인 과제입니다.
      </Box>
      <Box style={{display: taskList.length == 0 ? '' : 'none'}}>
        진행 중 과제가 없습니다.
      </Box>
      <Box>
        {taskList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <TaskView3
                key={'task' + index}
                detailEvent={clickTaskDetail}
                removeEvent={removeTask}
                task={value}
              />
            );
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};
FragmentView.propTypes = {
  detailEvent: PropTypes.func,
  finishedTaskClickEvent: PropTypes.func,
  runningTaskClickEvent: PropTypes.func,
};
export default FragmentView;
