import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';

const CommentList = ({commentData, student_name}) => {
  if (!commentData || commentData.length === 0) return null;

  const [sortedComments, setSortedComments] = useState([]);

  useEffect(() => {
    if (commentData.length > 0) {
      const sorted = [...commentData].sort(
        (a, b) => new Date(a.a_time) - new Date(b.a_time),
      );
      setSortedComments(sorted);
    }
  }, [commentData]);

  return (
    <>
      {sortedComments.map((comment, index) => (
        <Box
          key={index}
          sx={{
            padding: '0.5rem',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            marginBottom: '1rem',
            marginRight: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                {comment.a_name}
                <Box
                  component="span"
                  sx={{
                    fontSize: '0.8rem',
                    color: '#898989',
                    marginLeft: '0.5rem',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  ({comment.a_time})
                </Box>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0.5rem',
                }}
              >
                <Typography>{comment.a_comment}</Typography>
              </Box>
            </Box>
          </Box>
          {comment.b_comment && (
            <Box
              sx={{
                marginTop: '0.5rem',
                marginLeft: '0.5rem',
                paddingLeft: '1.5rem',
                borderLeft: '2px solid #ddd',
              }}
            >
              {/* 🔹 대댓글 - 저자(이름) & 날짜 */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{fontWeight: 'bold', fontSize: '1rem'}}>
                  {student_name}
                  <Box
                    component="span"
                    sx={{
                      fontSize: '0.8rem',
                      color: '#898989',
                      marginLeft: '0.5rem',
                    }}
                  >
                    ({comment.b_time})
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                    marginBottom: '1rem',
                  }}
                >
                  <Typography>{comment.b_comment}</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};

// ✅ props 검증 추가
CommentList.propTypes = {
  commentData: PropTypes.array.isRequired,
  student_name: PropTypes.string,
};

export default CommentList;
