import {Box, TextField, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import {StringUtils} from 'common/utils/StringUtils';
import TeacherView from 'view/admin/TeacherView1';
import emojiRegex from 'emoji-regex';
import DatePicker from 'react-datepicker';
import {ko} from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';

export const FragmentView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [teacherList, setTeacherList] = useState([]);
  const [originalTeacherList, setOriginalTeacherList] = useState([]);
  const countPerPage = 500;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const todayDate = new Date();
  const today = new Date();
  const firstDayOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1,
  );
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
  const [startDate, setStartDate] = useState(firstDayOfLastMonth);
  const [endDate, setEndDate] = useState(today);

  const ExampleCustomInput = React.forwardRef((props, ref) => (
    <button
      style={{
        backgroundColor: '#F7F8FA',
        border: '0px solid black',
        borderRadius: '0.625rem',
        padding: '0.5rem 1rem',
      }}
      className="custom-input"
      onClick={props.onClick}
      ref={ref}
    >
      {'📅  '}
      {props.value}
      {' ⌵ '}
    </button>
  ));
  ExampleCustomInput.displayName = 'CalandarInput';
  ExampleCustomInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
  };
  const handleSearchText = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 20) {
        inputVal = inputVal.substring(0, 20);
      }
      setSearchText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function getTeacherInfo(user_id, start_date, end_date) {
    const getInfo = async (user_id, start_date, end_date) => {
      try {
        const response = await APIUtils.AdminTeacherList(
          user_id,
          StringUtils.getFormattedString0h(start_date),
          StringUtils.getFormattedString24h(end_date),
        );

        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo(user_id, start_date, end_date).then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setOriginalTeacherList(res.data.teacher_list);
      }
    });
  }

  const handleStartDateChange = newDate => {
    setStartDate(newDate);
  };
  const handleEndDateChange = newDate => {
    setEndDate(newDate);
  };

  const [staudentNumSortOrder, setStudentNumSortOrder] = useState(1);
  const [taskCountSortOrder, setTaskCountSortOrder] = useState(1);
  const [evalCountSortOrder, setEvalCountSortOrder] = useState(1);
  const [saveCountSortOrder, saveSaveCountSortOrder] = useState(1);
  const [teacherNameSortOrder, setTeacherNameSortOrder] = useState(0);
  const [loginTimeSortOrder, setLoginTimeSortOrder] = useState(1);

  function sortTeacherListBy(key) {
    let sortedList;

    if (key === 'student_num') {
      sortedList = [...teacherList].sort((a, b) => {
        return staudentNumSortOrder === 0
          ? a.student_num - b.student_num
          : b.student_num - a.student_num;
      });
      setStudentNumSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'task_count') {
      sortedList = [...teacherList].sort((a, b) => {
        return taskCountSortOrder === 0
          ? a.task_count - b.task_count
          : b.task_count - a.task_count;
      });
      setTaskCountSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'eval_count') {
      sortedList = [...teacherList].sort((a, b) => {
        return evalCountSortOrder === 0
          ? a.eval_count - b.eval_count
          : b.eval_count - a.eval_count;
      });
      setEvalCountSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'save_count') {
      sortedList = [...teacherList].sort((a, b) => {
        return saveCountSortOrder === 0
          ? a.save_count - b.save_count
          : b.save_count - a.save_count;
      });
      saveSaveCountSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'name') {
      sortedList = [...teacherList].sort((a, b) => {
        return teacherNameSortOrder === 0
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      });
      setTeacherNameSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'last_login_time') {
      sortedList = [...teacherList].sort((a, b) => {
        return loginTimeSortOrder === 0
          ? a.last_login_time.localeCompare(b.last_login_time)
          : b.last_login_time.localeCompare(a.last_login_time);
      });
      setLoginTimeSortOrder(prev => (prev === 0 ? 1 : 0));
    }

    if (sortedList) {
      setTeacherList(sortedList);
    }
  }

  function getFilterTeacherList() {
    let filteredList = [...originalTeacherList];
    filteredList = filteredList.filter(
      value =>
        value.name.includes(searchText) || value.email.includes(searchText),
    );
    filteredList = filteredList.sort(function (a, b) {
      if (a.user_id == authReducer.user_id) return -1;
      if (b.user_id == authReducer.user_id) return 1;
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setTeacherList(filteredList);
    setTotalPage(Math.floor((filteredList.length - 1) / countPerPage + 1));
  }

  useEffect(() => {
    getFilterTeacherList();
  }, [originalTeacherList, searchText]);
  return (
    <Box style={{width: '95%', marginLeft: '2rem', maxWidth: '120rem'}}>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>
          키위티 (선생님) 계정 관리
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          color: '#87929D',
          fontSize: '0.875rem',
          flex: 1,
          overflow: 'hidden',
          maxHeight: '10rem',
          marginBottom: '0.5rem',
        }}
      >
        그룹에 소속된 선생님을 내보내기 할 수 있습니다. 내보내기한 선생님에게
        연결된 학생은 그룹에 소속된 학생에서 제외됩니다.
        <Box>
          (내보내기 하여도{' '}
          <font color="#ff9900">해당 선생님과 기존 학생의 연결</font>은
          유지됩니다.)
        </Box>
        <Box style={{display: 'flex', marginTop: '2rem'}}>
          <Box
            style={{
              display: 'flex',
              fontWeight: '500',
              justifyContent: 'center',
            }}
          >
            <Box>
              기간 :
              <DatePicker
                locale={ko}
                dateFormat={'yyyy-MM-dd'}
                shouldCloseOnSelect
                minDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                }
                maxDate={endDate}
                selected={startDate}
                onChange={handleStartDateChange}
                customInput={<ExampleCustomInput />}
                todayButton="오늘로 가기" // ✅ 오늘 버튼 추가
                style={{width: '20rem'}}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center', // 수평 중앙 정렬
                alignItems: 'center', // 수직 중앙 정렬
                fontWeight: '500',
                textAlign: 'center',
              }}
            >
              ~
            </Box>
            <Box>
              <DatePicker
                locale={ko}
                dateFormat={'yyyy-MM-dd'}
                shouldCloseOnSelect
                minDate={startDate}
                maxDate={todayDate}
                selected={endDate}
                onChange={handleEndDateChange}
                customInput={<ExampleCustomInput />}
                todayButton="오늘로 가기" // ✅ 오늘 버튼 추가
                style={{width: '20rem'}}
              />
            </Box>
            <Box>
              <Button
                style={{
                  color: 'white',
                  marginLeft: '1rem',
                  fontSize: '0.875rem',
                  border: 'none',
                  borderRadius: '1.925rem',
                  padding: '0.5rem 1rem',
                  backgroundColor: '#4FD7C3',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                }}
                onClick={() => {
                  getTeacherInfo(authReducer.user_id, startDate, endDate);
                }}
              >
                기록 가져오기
              </Button>
            </Box>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '5rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                sortTeacherListBy('name');
              }}
            >
              {'이름'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                sortTeacherListBy('student_num');
              }}
            >
              {'학생수'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                sortTeacherListBy('task_count');
              }}
            >
              {'과제수'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                sortTeacherListBy('eval_count');
              }}
            >
              {'개별 평가'}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          margin: '2rem 0rem 1rem 0rem',
          backgroundColor: 'white',
          boxShadow: '0px 5px 5px 0px #cdcdcd',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          paddingBottom: '2rem',
        }}
      >
        <Box
          style={{
            color: '#666972',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            margin: '2rem 2rem 0rem 2rem',
          }}
        >
          키위티(선생님) : 총 {teacherList.length}명
        </Box>
        <Box
          style={{
            width: '95%',
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '0.3rem',
            textAlign: 'center',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            alignItems: 'center', // 수직 중앙 정렬
            gap: '0.5rem', // 칸 사이 간격 추가
            marginLeft: '2rem',
            maxWidth: '120rem',
          }}
        >
          <Box style={{flex: 1, textAlign: 'center'}}> </Box>
          <Box style={{flex: 2, textAlign: 'left'}}>이름</Box>
          <Box style={{flex: 3, textAlign: 'left'}}>계정</Box>
          <Box style={{flex: 2, textAlign: 'center'}}>인증 번호</Box>
          <Box style={{flex: 1, textAlign: 'center'}}>학생수</Box>
          <Box style={{flex: 1, textAlign: 'center'}}>과제수</Box>
          <Box style={{flex: 2, textAlign: 'center'}}>학생 삭제</Box>
          <Box style={{flex: 3, textAlign: 'center'}}>최근 로그인</Box>
          <Box style={{flex: 1, textAlign: 'center'}}>개별 평가</Box>
          <Box style={{flex: 1, textAlign: 'center'}}>개별 발행</Box>

          <Box style={{flex: 2, textAlign: 'center'}}>선생님 삭제</Box>
        </Box>

        {/* 구분선 */}
        <Box
          style={{
            backgroundColor: 'black',
            width: '100%',
            height: '1px',
            margin: '0.5rem 0',
          }}
        ></Box>

        {/* 리스트 렌더링 */}
        {teacherList
          .filter(
            (_, index) => Math.floor(index / countPerPage) + 1 === currentPage,
          )
          .map((teacher, index) => (
            <TeacherView
              key={'ticket' + index}
              index={index + 1}
              teacher_info={teacher}
              getTeacherInfo={getTeacherInfo}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};

export default FragmentView;
