import PropTypes from 'prop-types';
import {Box, IconButton} from '@mui/material';
import React, {useState} from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TicketView = props => {
  const usable = StringUtils.activeToday(
    props.ticket_info.group_pass_start_time,
    props.ticket_info.group_pass_end_time,
  );
  const beforeActive = StringUtils.beforeToday(
    props.ticket_info.group_pass_start_time,
  );
  function showTicketStatus() {
    if (usable) {
      return (
        <Box
          style={{
            display: 'inline',
            color: '#30C0AB',
            border: '2px solid #30C0AB',
            borderRadius: '1rem',
            fontSize: '0.875rem',
            fontWeight: '500',
            padding: '0.2rem 0.5rem',
          }}
        >
          사용중
        </Box>
      );
    } else if (beforeActive) {
      return (
        <Box
          style={{
            display: 'inline',
            color: '#666972',
            border: '2px solid #666972',
            borderRadius: '1rem',
            fontSize: '0.875rem',
            fontWeight: '500',
            padding: '0.2rem 0.8rem',
          }}
        >
          대기
        </Box>
      );
    } else {
      return (
        <Box
          style={{
            display: 'inline',
            color: '#666972',
            border: '2px solid #666972',
            borderRadius: '1rem',
            fontSize: '0.875rem',
            fontWeight: '500',
            padding: '0.2rem 0.8rem',
          }}
        >
          종료
        </Box>
      );
    }
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        paddingBottom: '0.7rem',
        textAlign: 'center',
        width: '80%',
      }}
    >
      <Box style={{flex: 1}}>
        {StringUtils.getDateString(props.ticket_info.group_pass_log_time)}
      </Box>
      <Box style={{flex: 2}}>
        {StringUtils.getDateString(props.ticket_info.group_pass_start_time) +
          ' ~ ' +
          StringUtils.getDateString(props.ticket_info.group_pass_end_time)}
      </Box>
      <Box style={{flex: 1}}>
        {props.ticket_info.group_pass_student_num + '명'}
      </Box>
      <Box style={{flex: 1}}>
        {props.ticket_info.group_pass_teacher_num + '명'}
      </Box>

      <Box style={{flex: 1}}>{showTicketStatus()}</Box>
    </Box>
  );
};
TicketView.propTypes = {
  ticket_info: PropTypes.object,
};
export default TicketView;
