import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState} from 'react';
import {StringUtils} from 'common/utils/StringUtils';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';
import TeacherDeletePopup from 'view/admin/TeacherDeletePopup';
import StudentDeletePopup from 'view/admin/StudentDeletePopup';
import StudentDeleteFailPopup from 'view/admin/StudentsCannotRemovedPopup';

export const TeacherView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [teacherDeleteVisibility, setTeacherDeleteVisibility] = useState(false);
  const [studentDeleteVisibility, setStudentDeleteVisibility] = useState(false);
  const [studentRemoveDeniedVisibility, setStudentRemoveDeniedVisibility] =
    useState(false);
  const [deniedStudentList, setDeniedStudentList] = useState([]);
  function cancelStudentRemoveDenied() {
    setStudentRemoveDeniedVisibility(false);
  }
  const teacherDelete = async (admin_id, teacher_id) => {
    setTeacherDeleteVisibility(false);
    try {
      const response = await APIUtils.AdminTeacherDelete(admin_id, teacher_id);
      if (response.status === 200 && response.data.ret_code === 1000) {
        const now = new Date();
        const startDate =
          props.startDate ?? StringUtils.getFormattedString(now);
        const endDate = props.endDate ?? StringUtils.getFormattedString(now);
        props.getTeacherInfo(admin_id, startDate, endDate);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const teacherDeleteCancel = () => {
    setTeacherDeleteVisibility(false);
  };

  const studentDelete = async (admin_id, teacher_id) => {
    setStudentDeleteVisibility(false);
    try {
      const response = await APIUtils.AdminStudentDelete(admin_id, teacher_id);
      if (response.status === 200 && response.data.ret_code === 1000) {
        const now = new Date();
        const startDate =
          props.startDate ?? StringUtils.getFormattedString(now);
        const endDate = props.endDate ?? StringUtils.getFormattedString(now);
        props.getTeacherInfo(admin_id, startDate, endDate);
      } else if (response.data.ret_code == 2601) {
        setDeniedStudentList(response.data.task_include_student_list);
        setStudentRemoveDeniedVisibility(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const studentDeleteCancel = () => {
    setStudentDeleteVisibility(false);
  };

  const ID =
    props.teacher_info.login_id === null
      ? props.teacher_info.email
      : `${props.teacher_info.login_id} / ${props.teacher_info.login_password}`;

  return (
    <Box style={{width: '95%', marginLeft: '2rem', maxWidth: '120rem'}}>
      <TeacherDeletePopup
        visibility={teacherDeleteVisibility}
        admin_id={authReducer.user_id}
        teacher_id={props.teacher_info.user_id}
        confirmEvent={teacherDelete}
        cancelEvent={teacherDeleteCancel}
      />
      <StudentDeletePopup
        visibility={studentDeleteVisibility}
        admin_id={authReducer.user_id}
        teacher_id={props.teacher_info.user_id}
        confirmEvent={studentDelete}
        cancelEvent={studentDeleteCancel}
      />
      <StudentDeleteFailPopup
        visibility={studentRemoveDeniedVisibility}
        cancelEvent={cancelStudentRemoveDenied}
        deniedStudentList={deniedStudentList}
      />
      <Box
        style={{
          display: 'flex',
          background:
            authReducer.user_id === props.teacher_info.user_id
              ? '#EBFBF9'
              : 'white',
          fontSize: '0.875rem',
          alignItems: 'center',
          padding: '0.5rem 0',
          width: '100%',
          borderBottom: '1px solid #ddd',
        }}
      >
        <Box style={{flex: 1, textAlign: 'center'}}>{props.index}</Box>
        <Box style={{flex: 2, textAlign: 'left', fontWeight: '500'}}>
          {props.teacher_info.name}
        </Box>
        <Box style={{flex: 3, fontSize: '0.7rem', textAlign: 'left'}}>{ID}</Box>
        <Box style={{flex: 2, textAlign: 'center'}}>
          {props.teacher_info.auth_key.slice(0, 4)}-
          {props.teacher_info.auth_key.slice(4, 8)}
        </Box>
        <Box style={{flex: 1, textAlign: 'center'}}>
          {props.teacher_info.student_num}명
        </Box>
        <Box style={{flex: 1, textAlign: 'center'}}>
          {props.teacher_info.task_count !== -1
            ? props.teacher_info.task_count + '개'
            : ''}
        </Box>
        <Box style={{flex: 2, textAlign: 'center'}}>
          <Button
            style={{
              display: 'inline-flex',
              color: '#4b4d4c',
              border: '1px solid #4b4d4c',
              borderRadius: '1rem',
              fontSize: '0.825rem',
              fontWeight: '500',
              padding: '0.3rem 0.3rem',
              minWidth: '80px',
            }}
            onClick={() => setStudentDeleteVisibility(true)}
          >
            연결해제
          </Button>
        </Box>
        <Box style={{flex: 3, textAlign: 'center'}}>
          {StringUtils.getDateTimeString(props.teacher_info.last_login_time)}
        </Box>
        <Box style={{flex: 1, textAlign: 'center'}}>
          {props.teacher_info.eval_count !== -1
            ? props.teacher_info.eval_count
            : ''}
        </Box>
        <Box style={{flex: 1, textAlign: 'center'}}>
          {props.teacher_info.save_count !== -1
            ? props.teacher_info.save_count
            : ''}
        </Box>

        <Box style={{flex: 2, textAlign: 'center'}}>
          <Button
            style={{
              display:
                authReducer.user_id === props.teacher_info.user_id
                  ? 'none'
                  : 'inline-flex',
              color: '#4b4d4c',
              border: '1px solid #4b4d4c',
              borderRadius: '1rem',
              fontSize: '0.825rem',
              fontWeight: '500',
              padding: '0.3rem 0.3rem',
              minWidth: '80px',
            }}
            onClick={() => setTeacherDeleteVisibility(true)}
          >
            내보내기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

TeacherView.propTypes = {
  index: PropTypes.number,
  getTeacherInfo: PropTypes.func.isRequired,
  teacher_info: PropTypes.object.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

export default TeacherView;
