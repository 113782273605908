import PropTypes from 'prop-types';
import {Box, IconButton} from '@mui/material';
import React, {useState} from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TicketView = props => {
  const [expanded, setExpanded] = useState(false);
  const beforeActive = StringUtils.beforeToday(props.start_time);
  const usable = StringUtils.activeToday(props.start_time, props.end_time);
  if (beforeActive) {
    return (
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1.5rem',
            paddingBottom: '0.7rem',
            borderBottom: '2px solid #A8B1B7',
          }}
        >
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            <Box
              style={{
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#279EFF',
                borderRadius: '0.3rem',
                padding: '0.125rem 0.375rem',
                marginRight: '1rem',
                whiteSpace: 'nowrap',
              }}
            >
              예정
            </Box>
            {props.group_name}
          </Box>
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            학생(키위런) : 최대 {props.pass_student_num}명
          </Box>
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            선생님(키위티) : 최대 {props.pass_teacher_num}명
          </Box>

          <Box style={{display: 'flex'}}>
            {StringUtils.getDateString(props.start_time) +
              '~' +
              StringUtils.getDateString(props.end_time)}
          </Box>
        </Box>
      </Box>
    );
  } else if (usable) {
    return (
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1.5rem',
            paddingBottom: '0.7rem',
            borderBottom: '2px solid #A8B1B7',
          }}
        >
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            <Box
              style={{
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#ff9900',
                borderRadius: '0.3rem',
                padding: '0.125rem 0.375rem',
                marginRight: '1rem',
                whiteSpace: 'nowrap',
              }}
            >
              이용
            </Box>
            {props.group_name}
          </Box>
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            학생(키위런) : {props.student_count}/{props.pass_student_num}명
          </Box>
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            선생님(키위티) : {props.teacher_count}/{props.pass_teacher_num}명
          </Box>

          <Box style={{display: 'flex'}}>
            {StringUtils.getDateString(props.start_time) +
              '~' +
              StringUtils.getDateString(props.end_time)}
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1.5rem',
            paddingBottom: '0.7rem',
            borderBottom: '2px solid #A8B1B7',
          }}
        >
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            <Box
              style={{
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#68737D',
                borderRadius: '0.3rem',
                padding: '0.125rem 0.375rem',
                marginRight: '1rem',
                whiteSpace: 'nowrap',
              }}
            >
              만료
            </Box>
            {props.group_name}
          </Box>
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            학생(키위런) : 최대 {props.pass_student_num}명
          </Box>
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            선생님(키위티) : 최대 {props.pass_teacher_num}명
          </Box>

          <Box style={{display: 'flex'}}>
            {StringUtils.getDateString(props.start_time) +
              '~' +
              StringUtils.getDateString(props.end_time)}
          </Box>
        </Box>
      </Box>
    );
  }
};
TicketView.propTypes = {
  group_name: PropTypes.string,
  student_count: PropTypes.number,
  pass_student_num: PropTypes.number,
  teacher_count: PropTypes.number,
  pass_teacher_num: PropTypes.number,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
};
export default TicketView;
