import React, {useState} from 'react';
import PropTypes from 'prop-types';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Rating,
  TextField,
} from '@mui/material';

const PeerEvalComponent = ({
  peerEvalItems,
  peerEvalMaxScore,
  peerEvalChecked,
}) => {
  if (!peerEvalChecked) return null;

  const [peerEvalScores, setPeerEvalScores] = useState(
    Array(peerEvalItems.length).fill(0),
  );

  const handleScoreChange = (index, value) => {
    setPeerEvalScores(prevScores => {
      const newScores = [...prevScores];
      newScores[index] = value;
      return newScores;
    });
  };

  return (
    <Box
      sx={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
      }}
    >
      {peerEvalItems.map((value, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '0.5rem 1rem',
            borderBottom: '1px solid #ddd',
          }}
        >
          {/* 평가 항목 */}
          <Box sx={{flex: 1, textAlign: 'left'}}>{value}</Box>

          {/* 평가 점수 선택 */}
          <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
            {/* 1점: 하트 ON/OFF */}
            {peerEvalMaxScore === 1 && (
              <Box
                onClick={() =>
                  handleScoreChange(index, peerEvalScores[index] ? 0 : 1)
                }
                sx={{cursor: 'pointer', fontSize: '1rem'}}
              >
                {peerEvalScores[index] ? '❤️' : '🤍'}
              </Box>
            )}

            {/* 3점: 🙂😐🙁 선택 */}
            {peerEvalMaxScore === 3 && (
              <ToggleButtonGroup
                value={peerEvalScores[index] ?? 2}
                exclusive
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    handleScoreChange(index, newValue);
                  }
                }}
                sx={{justifyContent: 'flex-start'}}
              >
                <ToggleButton
                  value={1}
                  sx={toggleButtonStyle(peerEvalScores[index], 1)}
                >
                  🙁 부족
                </ToggleButton>
                <ToggleButton
                  value={2}
                  sx={toggleButtonStyle(peerEvalScores[index], 2)}
                >
                  😐 보통
                </ToggleButton>
                <ToggleButton
                  value={3}
                  sx={toggleButtonStyle(peerEvalScores[index], 3)}
                >
                  🙂 좋음
                </ToggleButton>
              </ToggleButtonGroup>
            )}

            {/* 5점 이하일 때 별점 사용 */}
            {peerEvalMaxScore === 5 && (
              <Rating
                name={`peer-rating-${index}`}
                value={
                  peerEvalScores[index] ?? Math.round(peerEvalMaxScore / 2)
                }
                max={peerEvalMaxScore}
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    handleScoreChange(index, newValue);
                  }
                }}
                icon={<StarIcon sx={{fontSize: '2rem', color: '#00C500'}} />}
                emptyIcon={
                  <StarBorderIcon sx={{fontSize: '2rem', color: '#DDD'}} />
                }
              />
            )}
            {peerEvalMaxScore > 5 && (
              <Box sx={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    min: 0,
                    max: peerEvalMaxScore,
                    step: 0.1, // ✅ 0.1 단위 입력 가능
                  }}
                  value={peerEvalScores[index]}
                  onChange={e => {
                    let value = parseFloat(e.target.value);
                    if (isNaN(value)) {
                      handleScoreChange(index, '');
                      return;
                    }
                    if (value < 0) value = 0;
                    if (value > peerEvalMaxScore) value = peerEvalMaxScore;

                    handleScoreChange(index, parseFloat(value.toFixed(1))); // ✅ 소수점 한 자리까지 반올림
                  }}
                  onBlur={() => {
                    if (
                      peerEvalScores[index] === '' ||
                      peerEvalScores[index] === undefined
                    ) {
                      handleScoreChange(index, Math.ceil(peerEvalMaxScore / 2));
                    }
                  }}
                  sx={{width: '4rem', textAlign: 'center'}}
                />
                <Box sx={{fontSize: '0.875rem', color: '#555'}}>
                  / {peerEvalMaxScore}
                </Box>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              fontSize: '1rem',
              marginLeft: '1rem',
              color: '#007AFF',
              width: '3rem',
              textAlign: 'right',
            }}
          >
            {peerEvalScores[index] ? `${peerEvalScores[index]}점` : '0점'}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

// ✅ 스타일 함수 (토글 버튼의 배경색 변경)
const toggleButtonStyle = (selectedValue, buttonValue) => ({
  backgroundColor: selectedValue === buttonValue ? '#00C500' : 'transparent',
  color: selectedValue === buttonValue ? 'white' : 'black',
  '&:hover': {backgroundColor: '#00C500', color: 'white'},
});

// ✅ `peerEvalScores`를 `arrayOf(PropTypes.number)`로 변경
PeerEvalComponent.propTypes = {
  peerEvalItems: PropTypes.array.isRequired,
  peerEvalMaxScore: PropTypes.number.isRequired,
  peerEvalChecked: PropTypes.bool.isRequired,
};

export default PeerEvalComponent;
