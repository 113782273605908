import React, {useState} from 'react';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import SidebarView from 'view/admin/SidebarView';
import FragmentHome from 'view/admin/FragmentHome';
import FragmentTeacher from 'view/admin/FragmentTeacher';
import FragmentIDGen from 'view/admin/FragmentIDGen';
import FragmentStudent from 'view/admin/FragmentStudent';
import APIUtils from 'common/utils/APIUtils';

const MyPage = () => {
  const [fragmentStatus, setFragmentStatus] = useState(1);
  const [passStatus, setPassStatus] = useState('none');
  const [passEndTime, setPassEndTime] = useState('');
  const [passStudentCount, setPassStudentCount] = useState(0);
  const authReducer = useSelector(state => state.authReducer);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };
  function SidebarClickEvent(option) {
    setFragmentStatus(option);
  }
  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheckShort(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setPassStatus(response.data.status);
          setPassEndTime(response.data.end_time);
          setPassStudentCount(response.data.max_student_count);
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function showFragment() {
    switch (fragmentStatus) {
      case 1:
        return <FragmentHome />;
      case 2:
        return <FragmentTeacher />;
      case 3:
        return (
          <FragmentIDGen
            getPassInfo={getPassInfo}
            passStatus="none"
            group_name="미상"
            auth_key="0000000"
            user_id="1002"
          />
        );
      case 4:
        return <FragmentStudent />;

      default:
        return <FragmentHome />;
    }
  }

  return (
    <Box
      style={{
        width: '100%',
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeaderView />
      <Box
        style={{
          marginBottom: '5%',
          marginTop: '1%',
          width: '96%',
          flex: 1,
          display: 'flex',
        }}
      >
        <Box
          className={`
            bg-white shadow-lg transition-all duration-300 ease-in-out relative
            ${isSidebarOpen ? 'w-64' : 'w-12'}
          `}
          style={{
            position: 'relative', // 버튼 위치 기준 설정
            paddingTop: '20px', // 버튼과 상단 컨테이너 간 여백 추가
          }}
        >
          {/* 화살표 버튼 */}
          <button
            onClick={toggleSidebar}
            style={{
              position: 'absolute',
              top: '0px', // 화살표 위치 아래로 조정
              left: '0px',
              backgroundColor: 'rgba(53, 210, 187, 0.10)',
              color: '#35D2BB',
              border: 'none',
              borderRadius: '30%',
              fontWeight: 'bold',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            aria-label={isSidebarOpen ? '사이드바 닫기' : '사이드바 열기'}
          >
            {isSidebarOpen ? '<' : '>'}
          </button>

          {/* 사이드바 내용 */}
          {isSidebarOpen && (
            <SidebarView
              currentFragment={fragmentStatus}
              clickEvent={SidebarClickEvent}
            />
          )}
        </Box>

        {/* Main content */}
        <Box
          style={{
            flex: 1,
            marginLeft: '5%',
            marginRight: '1%',
          }}
        >
          {showFragment()}
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};

export default MyPage;
