import React, {useEffect, useState} from 'react';
import {Box, Button} from '@mui/material';
import HeaderView from 'view/common/Header';
import PurchaseCard from 'view/purchase/PurchaseCard';
import {useSelector} from 'react-redux';
import {
  PurchaseConstant1,
  PurchaseConstant2,
  PurchaseConstant3,
  PurchaseConstant4,
  PurchaseConstantX,
} from 'common/utils/PurchaseConstants';
import {
  MarginBoxComponent,
  WhiteBox,
  TitleComponent3,
  SingleLineTitle2,
} from 'view/common/Components';
import APIUtils from 'common/utils/APIUtils';
import {loadTossPayments} from '@tosspayments/payment-sdk';
import FooterView from 'view/common/Footer';
import PurchaseSelectGroup from 'view/purchase/PurchaseSelectGroup';
import DirectPayPopup from 'view/popup/DirectPayPopup';
import iconToss from 'asset/imageV2/icon_toss.png';
import SideOverlay from 'view/common/SideOverlay2';
import DatePicker from 'react-datepicker';
import {ko} from 'date-fns/esm/locale';
import PropTypes from 'prop-types';
import {StringUtils} from 'common/utils/StringUtils';
import iconExclamation from 'asset/imageV2/icon_exclamation1.png';
import iconCard from 'asset/imageV2/icon_card1.png';

const clientKey = process.env.REACT_APP_TOSS_CLIENT_ID;
const tossPerchaseSuccessURL = process.env.REACT_APP_TOSS_BUY_SUCCESS_URI;
const tossPaySuccessURL = process.env.REACT_APP_TOSS_SUCCESS_URI;
const tossFailURL = process.env.REACT_APP_TOSS_FAIL_URI;

const PurchaseConstants = [
  ...PurchaseConstant1,
  ...PurchaseConstant2,
  ...PurchaseConstant3,
  ...PurchaseConstant4,
  ...PurchaseConstantX,
];

const PurchasePage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [clickedCard, setClickedCard] = useState(0);
  const [groupInfoLoadingDone, setGroupInfoLoadingDone] = useState(false);
  const [purchaseDisabled, setPurchaseDisabled] = useState(1);
  const [currentTeacherCount, setCurrentTeacherCount] = useState(1);
  const [currentStudentCount, setCurrentStudentCount] = useState(0);
  const [groupName, setGroupName] = useState('');

  const [directPayVisibility, setDirectPayVisibility] = useState(false);
  function directPayCancel() {
    setDirectPayVisibility(false);
  }
  function directPayConfirm(payAmount, customerName) {
    setDirectPayVisibility(false);
    tossPay(authReducer.user_id, payAmount, customerName);
  }
  const now = new Date();
  const [lastPassDate, setLastPassDate] = useState(null);
  const [buyableStartDate, setBuyableStartDate] = useState(now);
  const [passStartDate, setPassStartDate] = useState(now);
  const [passEndDate, setPassEndDate] = useState(() => {
    const nextMonthToday = new Date(); // 현재 날짜
    nextMonthToday.setMonth(now.getMonth() + PurchaseConstants[0].evalDuration);
    return nextMonthToday;
  });
  const [buyableEndDate, setBuyableEndDate] = useState(() => {
    const nextMonthToday = new Date(); // 현재 날짜
    nextMonthToday.setDate(now.getDate() + 31);
    return nextMonthToday;
  });

  const [groupPassStudentNumber, setGroupPassStudentNumber] = useState(0);
  const [groupPassTeacherNumber, setGroupPassTeacherNumber] = useState(0);
  const [activePassCount, setActivePassCount] = useState(0);

  const handleStartDateChange = newDate => {
    setPassStartDate(newDate); // ✅ passStartDate를 업데이트

    let newEndDate = new Date(newDate); // ✅ newDate를 기준으로 설정
    newEndDate.setMonth(
      newDate.getMonth() + PurchaseConstants[clickedCard].evalDuration,
    );

    setPassEndDate(newEndDate); // ✅ passEndDate 업데이트
    return newEndDate;
  };

  function resetclickedCard(studentCount, teacherCount) {
    for (let i = 0; i < 15; i++) {
      if (
        studentCount <= PurchaseConstants[i].studentCount &&
        teacherCount <= PurchaseConstants[i].teacherCount
      ) {
        setClickedCard(i);
        checkPurchasable(i);
        const newEndDate = new Date(); // 현재 날짜
        newEndDate.setMonth(
          passStartDate.getMonth() + PurchaseConstants[i].evalDuration,
        );
        setPassEndDate(newEndDate);
        break;
      }
    }
  }

  const shouldShowPurchaseSelectGroup1 = () => {
    return purchaseDisabled === 0 && clickedCard >= 0 && clickedCard < 8;
  };
  const shouldShowPurchaseSelectGroup2 = () => {
    return purchaseDisabled === 0 && clickedCard >= 8 && clickedCard < 16;
  };

  function checkPurchasable(num) {
    let newDisabled = 0;

    if (authReducer.user_id <= 0) {
      newDisabled = 1; // 로그인 안됨
    } else if (
      currentStudentCount >
        PurchaseConstants[num]?.studentCount + groupPassStudentNumber ||
      currentTeacherCount >
        Math.max(PurchaseConstants[num]?.teacherCount, groupPassTeacherNumber)
    ) {
      newDisabled = 2; // 이용 한도 초과
    }

    if (clickedCard >= 0 && clickedCard < 16) {
      setPassEndDate(prevEndDate => {
        const newEndDate = new Date(passStartDate);
        newEndDate.setMonth(
          passStartDate.getMonth() + PurchaseConstants[num].evalDuration,
        );
        return newEndDate;
      });
    }
    setPurchaseDisabled(newDisabled);
  }

  function onCardClick(num) {
    if (authReducer.user_id >= 0) {
      setClickedCard(num);
      checkPurchasable(num);
    } else {
      setClickedCard(0);
    }
  }

  function findNextDayOfLatestPass(groupList) {
    const now = new Date(); // 현재 시간을 Date 객체로 정의
    const today = StringUtils.getFormattedString24h(now); // 현재 시간을 포맷팅된 문자열로 변환
    let latestPassDate = new Date(now.getTime()); // 현재 시간을 기본값으로 설정
    latestPassDate.setDate(now.getDate() - 1); // 기본값을 하루 전으로 설정

    let totalActiveStudents = 0; // ✅ 현재 유효한 이용권의 학생 수 총합
    let maxActiveTeachers = 0; // ✅ 현재 유효한 이용권의 학생 수 총합
    let activPassCount = 0;
    if (groupList.length > 0) {
      groupList
        .filter(a => a.group_pass_student_num > 0) // 학생이 있는 이용권만 고려
        .sort(
          (a, b) =>
            new Date(a.group_pass_start_time) -
            new Date(b.group_pass_start_time),
        )
        .forEach(val => {
          const passStart = new Date(val.group_pass_start_time);
          const passEnd = new Date(val.group_pass_end_time);
          const isActive = now >= passStart && now <= passEnd;
          if (isActive) {
            activPassCount += 1;
            totalActiveStudents += val.group_pass_student_num; // ✅ 현재 유효한 이용권의 학생 수 추가
            maxActiveTeachers = Math.max(
              maxActiveTeachers,
              val.group_pass_teacher_num || 0,
            );
          }
        });
    }
    setGroupPassStudentNumber(totalActiveStudents);
    setGroupPassTeacherNumber(maxActiveTeachers);
    setActivePassCount(activPassCount);

    if (groupList.length > 0) {
      // 미래 시간 필터링
      const futureDates = groupList.filter(
        item => item.group_pass_end_time > today, // 문자열 비교로 필터링
      );

      if (futureDates.length > 0) {
        // 가장 늦은 시간을 가진 항목 찾기
        const latestPass = futureDates.reduce((max, item) =>
          item.group_pass_end_time > max.group_pass_end_time ? item : max,
        );
        latestPassDate = new Date(latestPass.group_pass_end_time); // 가장 늦은 시간 설정
        setLastPassDate(latestPassDate);
      }

      const nextDay = new Date(latestPassDate);
      nextDay.setDate(nextDay.getDate() + 1); // 다음 날 설정
      setPassStartDate(nextDay);
      const newEndDate = new Date(); // 현재 날짜
      newEndDate.setMonth(
        passStartDate.getMonth() + PurchaseConstants[clickedCard].evalDuration,
      );
      setPassEndDate(newEndDate);
    }

    // 다음 달 설정
    const nextMonth = new Date(passStartDate.getTime());
    nextMonth.setMonth(latestPassDate.getMonth() + 1);

    // 구매 가능한 날짜 설정
    setBuyableStartDate(now); // 구매 가능 시작 날짜 설정
    setBuyableEndDate(nextMonth); // 구매 가능 종료 날짜 설정
  }

  async function getGroupName() {
    setGroupInfoLoadingDone(false);
    try {
      const groupRes = await APIUtils.GroupInfo(authReducer.user_id);
      if (groupRes.status === 200 && groupRes.data.ret_code === 1000) {
        findNextDayOfLatestPass(groupRes.data.group_inventory);
        if (groupRes.data.group_name) setGroupName(groupRes.data.group_name);
        else setGroupName('-');
        setGroupInfoLoadingDone(true);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getGroupUsageInfo() {
    if (!groupInfoLoadingDone) return;
    try {
      const res = await APIUtils.AdminView(authReducer.user_id);
      if (res.status === 200 && res.data.ret_code === 1000) {
        setCurrentTeacherCount(res.data.teacher_count);
        setCurrentStudentCount(res.data.student_count);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getPersonalUsageInfo() {
    if (!groupInfoLoadingDone) return;
    try {
      const studentRes = await APIUtils.AccountStudentList(authReducer.user_id);
      if (studentRes.status === 200 && studentRes.data.ret_code === 1000) {
        setCurrentStudentCount(studentRes.data.student_count);
        setGroupInfoLoadingDone(true);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // ✅ `user_id`가 변경될 때만 `getGroupName()` 호출
  useEffect(() => {
    if (authReducer.user_id >= 0) {
      getGroupName();
    }
  }, [authReducer.user_id]); // ✅ `user_id` 변경 시 실행

  // ✅ `groupName`이 변경될 때만 `getGroupUsageInfo()` 실행
  useEffect(() => {
    if (groupName !== '-') {
      getGroupUsageInfo();
    } else {
      getPersonalUsageInfo();
    }
    checkPurchasable(clickedCard);
  }, [groupName]); // ✅ `groupName` 변경 시 실행

  useEffect(() => {
    if (currentStudentCount > 0 || currentTeacherCount > 0) {
      resetclickedCard(currentStudentCount, currentTeacherCount);
      checkPurchasable(clickedCard);
    }
  }, [currentStudentCount, currentTeacherCount]); // `currentStudentCount`, `currentTeacherCount` 변경 시 실행

  useEffect(() => {
    checkPurchasable(clickedCard);
  }, [clickedCard, passStartDate]); // `currentStudentCount`, `currentTeacherCount` 변경 시 실행

  const ExampleCustomInput = React.forwardRef((props, ref) => (
    <button
      style={{
        backgroundColor: 'white',
        border: '1px solid  #898989',
        borderRadius: '0.3rem',
        padding: '0.1rem 0.5rem',
        fontSize: '1rem',
      }}
      className="custom-input"
      onClick={props.onClick}
      ref={ref}
    >
      {'📅  '}
      {props.value}
      {' ⌵ '}
    </button>
  ));
  ExampleCustomInput.displayName = 'CalandarInput';
  ExampleCustomInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
  };

  // ✅ `clickedCard`에 따른 상품 정보 배열 관리
  const purchaseItems = [
    {id: 25001, amount: 19000, name: '[개인] 1명 1개월 권'},
    {id: 25002, amount: 79000, name: '[개인] 5명 1개월 권'},
    {id: 25003, amount: 139000, name: '[개인] 10명 1개월 권'},
    {id: 25004, amount: 299000, name: '[개인] 30명 1개월 권'},
    {id: 25011, amount: 49000, name: '[개인] 1명 3개월 권'},
    {id: 25012, amount: 199000, name: '[개인] 5명 3개월 권'},
    {id: 25013, amount: 299000, name: '[개인] 10명 3개월 권'},
    {id: 25014, amount: 799000, name: '[개인] 30명 3개월 권'},
    {id: 25101, amount: 792000, name: '[그룹] 25명 3개월 권'},
    {id: 25102, amount: 1188000, name: '[그룹] 50명 3개월 권'},
    {id: 25103, amount: 1584000, name: '[그룹] 100명 3개월 권'},
    {id: 25104, amount: 2394000, name: '[그룹] 200명 3개월 권'},
    {id: 25111, amount: 1320000, name: '[그룹] 25명 6개월 권'},
    {id: 25112, amount: 1980000, name: '[그룹] 50명 6개월 권'},
    {id: 25113, amount: 2640000, name: '[그룹] 100명 6개월 권'},
    {id: 25114, amount: 3990000, name: '[그룹] 200명 6개월 권'},
    // 그룹 구매 항목 추가
  ];

  function purchaseItem(radioValue) {
    if (clickedCard >= 0) {
      const selectedItem = purchaseItems[clickedCard];
      if (selectedItem) {
        tossPurchase(
          selectedItem.id,
          selectedItem.amount,
          radioValue,
          selectedItem.name,
        );
      }
    }
  }

  function tossPay(user_id, amount, customerName) {
    const item_id = 50000;
    const timestamp = Date.now();
    const radioValue = '카드';
    const purchaseName = '키위티-키위런 이용권';
    const orderID = `${authReducer.user_id}_${item_id}_${timestamp}`;

    loadTossPayments(clientKey).then(tossPayments => {
      tossPayments.requestPayment('카드', {
        amount,
        orderId: orderID,
        orderName: purchaseName,
        customerName: customerName,
        successUrl: `${tossPaySuccessURL}?itemId=${item_id}&institutionName=${customerName}&user_id=${user_id}`,
        failUrl: tossFailURL,
        ...(radioValue !== '카드' && {flowMode: 'DIRECT', easyPay: radioValue}),
      });
    });
  }
  function tossPurchase(item_id, amount, radioValue, purchaseName) {
    const timestamp = Date.now();
    const orderID = `${authReducer.user_id}_${item_id}_${timestamp}`;
    const passStartDateText = StringUtils.getDateOnly(passStartDate);
    const passEndDateText = StringUtils.getDateOnly(passEndDate);
    loadTossPayments(clientKey).then(tossPayments => {
      tossPayments.requestPayment('카드', {
        amount,
        orderId: orderID,
        orderName: purchaseName,
        customerName: authReducer.export_name,
        successUrl: `${tossPerchaseSuccessURL}?itemId=${item_id}&start_date=${passStartDateText}&end_date=${passEndDateText}`,
        failUrl: tossFailURL,
        ...(radioValue !== '카드' && {flowMode: 'DIRECT', easyPay: radioValue}),
      });
    });
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SideOverlay />
        <HeaderView />

        <Box
          style={{
            marginLeft: '1%',
            marginRight: '1%',
            marginBottom: '5%',
            marginTop: '5%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            style={{
              fontSize: '2.5rem',
              fontWeight: 'bold',
              color: '#717171',
            }}
          >
            이용권 구매
          </Box>
          <Box
            style={{
              backgroundColor: '#E6E8EB',
              height: '1px',
              width: '100%',
              marginTop: '1.5rem',
            }}
          ></Box>
          <Box style={{marginTop: '1rem', color: '#68737D'}}>
            그룹 이용권 : 다수의 선생님이 다수의 학생과 교차연결되어 다양한
            조합이 가능합니다. 고객센터와 연락하여 이용권이 부여됩니다.
          </Box>
          <Box style={{color: '#68737D'}}>
            개인 이용권 : 한 분의 선생님이 다수의 학생과 연결되어 결제 즉시
            사용할 수 있습니다.
          </Box>
          <Box style={{color: '#68737D'}}>
            ※ 키위티 계정은 동시에 한가지 이용권만 사용할 수 있습니다. 이용권은
            최대 연결 가능 학생 수와 기간에 따라 분류됩니다.
          </Box>
          <Box
            style={{
              color: '#68737D',
              display: 'flex',
            }}
          >
            ※ 키위티 도입 전,{' '}
            <div
              style={{
                color: '#279EFF',
                fontWeight: 'bold',
                marginLeft: '0.5rem',
              }}
            >
              {' '}
              무료 체험
            </div>
            해 보고 싶으신가요?{' '}
            <span
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                marginLeft: '0.5rem',
              }}
              onClick={() => {
                window.open('https://forms.gle/vq3jCb1tyQEywvXq6');
              }}
            >
              여기
            </span>
            를 클릭해서 신청해주세요. 1개월 이용권(선생님 1명, 학생 30명)을
            제공합니다.
          </Box>

          <Box style={{display: 'flex', alignItems: 'end', marginTop: '2rem'}}>
            <Box
              style={{
                color: '#68737D',
                minWidth: '8rem',
                fontSize: '1.25rem',
                fontWeight: 'bold',
                borderRadius: '0.25rem',
                backgroundColor: '#E9EBED',
                padding: '0.1rem 0.7rem',
              }}
            >
              ※ 공교육 구매
            </Box>
            <Box
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                marginLeft: '1rem',
              }}
            ></Box>
            <Box
              style={{
                marginLeft: '2rem',
                color: '#68737D',
                fontSize: '0.875rem',
              }}
            >
              공교육 기관 혹은 선생님이 그룹 이용권을 구입하면 여러 선생님이
              함께 사용할 수 있습니다. 학교장터와 고객센터를 통해서 구매할 수
              있습니다.
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '98%',
              gap: '0.5rem',
              marginTop: '2rem',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                borderRadius: '10px',
                width: '30%',
                backgroundColor: 'white',
                marginTop: '1rem',
                boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.25)',
                border: '2px solid #D8DCDE',
              }}
              onClick={() => {
                window.open(
                  'https://s2b.kr/Edutech/product/productDetail.do?estimatecode=202408209320791',
                );
              }}
            >
              <Box
                style={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  textAlign: 'center',
                }}
              >
                <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
                  공교육 기관 전용
                </Box>
                <Box
                  style={{
                    color: '#2F3941',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                  }}
                >
                  1개월권
                </Box>
                <Box
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                    color: '#4DC8B6',
                  }}
                >
                  S2B 학교장터
                  <span style={{color: '#49545C', fontSize: '1rem'}}>
                    {' 로 이동'}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                borderRadius: '10px',
                width: '30%',
                backgroundColor: 'white',
                marginTop: '1rem',
                boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.25)',
                border: '2px solid #D8DCDE',
              }}
              onClick={() => {
                window.open(
                  'https://s2b.kr/Edutech/product/productDetail.do?estimatecode=202408219323093',
                );
              }}
            >
              <Box
                style={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  textAlign: 'center',
                }}
              >
                <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
                  공교육 기관 전용
                </Box>
                <Box
                  style={{
                    color: '#2F3941',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                  }}
                >
                  6개월권
                </Box>
                <Box
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                    color: '#4DC8B6',
                  }}
                >
                  S2B 학교장터
                  <span style={{color: '#49545C', fontSize: '1rem'}}>
                    {' 로 이동'}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                borderRadius: '10px',
                width: '30%',
                backgroundColor: 'white',
                marginTop: '1rem',
                boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.25)',
                border: '2px solid #D8DCDE',
              }}
              onClick={() => {
                window.open(
                  'https://s2b.kr/Edutech/product/productDetail.do?estimatecode=202408219323411',
                );
              }}
            >
              <Box
                style={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  textAlign: 'center',
                }}
              >
                <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
                  공교육 기관 전용
                </Box>
                <Box
                  style={{
                    color: '#2F3941',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                  }}
                >
                  12개월권
                </Box>
                <Box
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                    color: '#4DC8B6',
                  }}
                >
                  S2B 학교장터
                  <span style={{color: '#49545C', fontSize: '1rem'}}>
                    {' 로 이동'}
                  </span>
                </Box>
              </Box>
            </Box>
            <Button
              style={{
                borderRadius: '10px',
                width: '30%',
                backgroundColor: 'white',
                marginTop: '1rem',
                boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.25)',
                border: '2px solid #D8DCDE',
              }}
              onClick={() =>
                authReducer.user_id >= 0
                  ? setDirectPayVisibility(true)
                  : alert('로그인해주세요.')
              }
            >
              <Box
                style={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  textAlign: 'center',
                }}
              >
                <Box style={{color: '#4DC8B6', fontSize: '0.875rem'}}>
                  고객 센터 결제 전용
                </Box>
                <Box
                  style={{
                    color: '#2F3941',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                  }}
                >
                  <img
                    style={{width: '1.5rem', marginRight: '0.5rem'}}
                    src={iconCard}
                    alt="iconCard"
                  />
                  {'견적 금액 입금'}
                </Box>
              </Box>
            </Button>
          </Box>

          <Box
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: '1.5rem',
              flexDirection: 'column',
              display: authReducer.user_id >= 0 ? 'none' : '',
            }}
          >
            <Box
              style={{
                display: 'flex',
                borderRadius: '0.625rem',
                backgroundColor: 'rgba(255,153,0,0.1)',
                color: '#717171',
                padding: '1rem 2rem',
                width: 'calc(100% - 4rem)',
                alignItems: 'start',
              }}
            >
              <img
                style={{marginRight: '0.8rem'}}
                src={iconExclamation}
                alt="iconExclamation"
              />
              온라인으로 이용권 구매를 위해서는 회원가입/로그인하여야 합니다.
            </Box>
          </Box>
          <Box
            style={{
              alignItems: 'end',
              marginTop: '2rem',
              marginBottom: '2rem',
              display: authReducer.user_id >= 0 ? 'flex' : 'none',
            }}
          >
            <Box
              style={{
                color: '#68737D',
                minWidth: '8rem',
                fontSize: '1.25rem',
                fontWeight: 'bold',
                borderRadius: '0.25rem',
                backgroundColor: '#E9EBED',
                padding: '0.1rem 0.7rem',
              }}
            >
              ※ 이용 현황
            </Box>
            <Box
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                marginLeft: '1rem',
              }}
            ></Box>
            <Box
              style={{
                marginLeft: '2rem',
                color: '#68737D',
                fontSize: '0.875rem',
              }}
            >
              이용 기간이 종료되지 않아도 이용권을 추가 구매할 수 있습니다.
            </Box>
          </Box>
          <Box
            style={{
              alignItems: 'center',
              display: authReducer.user_id >= 0 ? '' : 'none',
            }}
          >
            <WhiteBox
              style={{
                alignItems: 'center',
                display: authReducer.user_id >= 0 ? '' : 'none',
              }}
            >
              <>
                {/* 그룹명 */}
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.3rem',
                  }}
                >
                  <Box style={{width: '10rem'}}>소속된 그룹</Box>
                  <Box>{groupName}</Box>
                </Box>

                {/* 이용권 보유 현황 */}
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.3rem',
                  }}
                >
                  <Box style={{width: '10rem'}}>이용권 보유 현황</Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <Box>선생님 최대</Box>
                    <Box>{groupPassTeacherNumber}</Box>
                    <Box>명</Box>
                    <Box style={{margin: '0 0.5rem', color: '#666'}}>|</Box>
                    <Box>학생 최대</Box>
                    <Box>{groupPassStudentNumber}</Box>
                    <Box>명</Box>
                    <Box style={{margin: '0 0.5rem', color: '#666'}}>|</Box>
                    <Box>총</Box>
                    <Box>{activePassCount}</Box>
                    <Box>개</Box>
                  </Box>
                </Box>

                {/* 계정 이용 현황 */}
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.3rem',
                  }}
                >
                  <Box style={{width: '10rem'}}>계정 이용 현황</Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <Box>선생님 현재</Box>
                    <Box
                      style={{
                        color:
                          currentTeacherCount > groupPassTeacherNumber
                            ? '#FF9900'
                            : 'black',
                      }}
                    >
                      {currentTeacherCount}
                    </Box>
                    <Box>명</Box>
                    <Box style={{margin: '0 0.5rem', color: '#666'}}>|</Box>
                    <Box>학생 현재</Box>
                    <Box
                      style={{
                        color:
                          currentStudentCount > groupPassStudentNumber
                            ? '#FF9900'
                            : 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {currentStudentCount}
                    </Box>
                    <Box>명</Box>
                  </Box>
                </Box>

                {/* 현재 이용권 종료 */}
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.3rem',
                  }}
                >
                  <Box style={{width: '10rem'}}>현재 이용권 종료</Box>
                  <Box
                    style={{
                      border: '0px solid black',
                      borderRadius: '0.3rem',
                      padding: '0rem 0.3rem',
                      fontSize: '1rem',
                      color: 'black',
                    }}
                  >
                    {lastPassDate === null
                      ? '-'
                      : StringUtils.getDateOnly(lastPassDate)}
                  </Box>
                </Box>

                {/* 신규 이용권 시작 */}
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.3rem',
                  }}
                >
                  <Box style={{width: '10rem'}}>신규 이용권 시작</Box>
                  <Box style={{backgroundColor: 'rgba(255,153,0,0.1)'}}>
                    <DatePicker
                      locale={ko}
                      dateFormat={'yyyy-MM-dd'}
                      shouldCloseOnSelect
                      minDate={buyableStartDate}
                      maxDate={buyableEndDate}
                      selected={passStartDate}
                      onChange={handleStartDateChange}
                      customInput={<ExampleCustomInput />}
                      todayButton="오늘로 가기" // ✅ 오늘 버튼 추가
                      style={{width: '20rem'}}
                    />
                  </Box>
                  <Box
                    style={{
                      marginLeft: '1rem',
                      color: '#4FD7C3',
                    }}
                  >
                    {passStartDate <= lastPassDate
                      ? '신규 이용권이 추가됩니다.'
                      : '신규 이용권이 시작됩니다.'}
                  </Box>
                </Box>
              </>
            </WhiteBox>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'end',
              marginTop: '2rem',
            }}
          >
            <Box
              style={{
                color: '#68737D',
                minWidth: '8rem',
                fontSize: '1.25rem',
                fontWeight: 'bold',
                borderRadius: '0.25rem',
                backgroundColor: '#E9EBED',
                padding: '0.1rem 0.7rem',
              }}
            >
              ※ 개인 구매
            </Box>
            <Box
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                marginLeft: '1rem',
              }}
            ></Box>
            <Box
              style={{
                marginLeft: '2rem',
                color: '#68737D',
                fontSize: '0.875rem',
              }}
            >
              개인 이용권을 구입하면 선생님 두명이 다수의 학생과
              키위티-키위런에서 즉시 사용할 수 있습니다.
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '98%',
              marginTop: '2rem',
            }}
          >
            <Box
              style={{
                color: '#68737D',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
            >
              1개월 이용권
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              {PurchaseConstant1.map((value, index) => {
                return (
                  <PurchaseCard
                    key={'purchaseCard' + index}
                    index={index}
                    clicked={clickedCard == index}
                    studentCount={value.studentCount}
                    teacherCount={value.teacherCount}
                    classCount={value.classCount}
                    evalCost={value.evalCost}
                    evalDuration={value.evalDuration}
                    clickEvent={onCardClick}
                  />
                );
              })}
            </Box>
            <Box
              style={{
                color: '#68737D',
                fontSize: '1rem',
                fontWeight: 'bold',
                marginTop: '2rem',
              }}
            >
              3개월 이용권
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              {PurchaseConstant2.map((value, index) => {
                const idx = index + 4;
                return (
                  <PurchaseCard
                    key={'purchaseCard' + idx}
                    index={idx}
                    clicked={clickedCard == idx}
                    studentCount={value.studentCount}
                    teacherCount={value.teacherCount}
                    classCount={value.classCount}
                    evalCost={value.evalCost}
                    evalDuration={value.evalDuration}
                    clickEvent={onCardClick}
                  />
                );
              })}
            </Box>

            <Box
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '1.5rem',
                flexDirection: 'column',
                display:
                  authReducer.user_id >= 0 &&
                  clickedCard >= 0 &&
                  clickedCard < 8
                    ? 'flex'
                    : 'none',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  borderRadius: '0.625rem',
                  backgroundColor: 'rgba(255,153,0,0.1)',
                  color: '#717171',
                  padding: '1rem 2rem',
                  width: 'calc(100% - 4rem)',
                  alignItems: 'start',
                }}
              >
                <img
                  style={{marginRight: '0.8rem'}}
                  src={iconExclamation}
                  alt="iconExclamation"
                />
                {purchaseDisabled === 0
                  ? '개인 이용권 결제 방법을 선택해 주세요.'
                  : '현재 학생수를 고려하여 이용권을 선택해 주세요.'}
              </Box>
            </Box>

            <Box
              style={{
                alignItems: 'center',
                marginTop: '1.5rem',
                display:
                  purchaseDisabled === 0 && clickedCard < 8 ? 'flex' : 'none',
              }}
            >
              <Box
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  marginLeft: '1rem',
                }}
              >
                신용카드 결제
              </Box>
              <Box
                style={{
                  marginLeft: '2rem',
                  color: '#68737D',
                  alignItems: 'center',
                  fontSize: '0.875rem',
                }}
              >
                안전한 토스페이먼트로 결제가 진행됩니다.
              </Box>
              <Box>
                <img style={{width: '6rem'}} src={iconToss} alt="iconToss" />
              </Box>
            </Box>
            <PurchaseSelectGroup
              clickedCard={clickedCard}
              clickEvent={purchaseItem}
              purchaseSectionVisibility={
                shouldShowPurchaseSelectGroup1() ? 1 : 0
              }
              studentCount={PurchaseConstants[clickedCard]['studentCount']}
              teacherCount={PurchaseConstants[clickedCard]['teacherCount']}
              newStartDate={passStartDate}
              isDupPass={
                lastPassDate != null && lastPassDate >= passStartDate ? 1 : 0
              }
              defautPayMethod="카드"
            />

            <Box
              style={{display: 'flex', alignItems: 'end', marginTop: '3rem'}}
            >
              <Box
                style={{
                  color: '#68737D',
                  minWidth: '8rem',
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  borderRadius: '0.25rem',
                  backgroundColor: '#E9EBED',
                  padding: '0.1rem 0.7rem',
                }}
              >
                ※ 기관 구매
              </Box>
              <Box
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  marginLeft: '1rem',
                }}
              ></Box>
              <Box
                style={{
                  marginLeft: '2rem',
                  color: '#68737D',
                  fontSize: '0.875rem',
                }}
              >
                다수의 선생님이 다수의 학생과 연결되어 사용할 수 있습니다.
                구입한 선생님이 그룹 관리자로 됩니다. 변경하려면 고객센터에
                알려주세요.
              </Box>
            </Box>
            <Box
              style={{
                color: '#68737D',
                fontSize: '1rem',
                fontWeight: 'bold',
                marginTop: '2rem',
              }}
            >
              3개월 이용권
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              {PurchaseConstant3.map((value, index) => {
                const idx = index + 8;
                return (
                  <PurchaseCard
                    key={'purchaseCard' + idx}
                    index={idx}
                    clicked={clickedCard == idx}
                    studentCount={value.studentCount}
                    teacherCount={value.teacherCount}
                    classCount={value.classCount}
                    evalCost={value.evalCost}
                    evalDuration={value.evalDuration}
                    clickEvent={onCardClick}
                  />
                );
              })}
            </Box>
            <Box
              style={{
                color: '#68737D',
                fontSize: '1rem',
                fontWeight: 'bold',
                marginTop: '2rem',
              }}
            >
              6개월 이용권
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              {PurchaseConstant4.map((value, index) => {
                const idx = index + 12;
                return (
                  <PurchaseCard
                    key={'purchaseCard' + idx}
                    index={idx}
                    clicked={clickedCard == idx}
                    studentCount={value.studentCount}
                    teacherCount={value.teacherCount}
                    classCount={value.classCount}
                    evalCost={value.evalCost}
                    evalDuration={value.evalDuration}
                    clickEvent={onCardClick}
                  />
                );
              })}
            </Box>
          </Box>
          <Box
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: '1.5rem',
              flexDirection: 'column',
              display:
                authReducer.user_id >= 0 && clickedCard < 16 && clickedCard >= 8
                  ? 'flex'
                  : 'none',
            }}
          >
            <Box
              style={{
                display: 'flex',
                borderRadius: '0.625rem',
                backgroundColor: 'rgba(255,153,0,0.1)',
                color: '#717171',
                padding: '1rem 2rem',
                width: 'calc(100% - 4rem)',
                alignItems: 'start',
              }}
            >
              <img
                style={{marginRight: '0.8rem'}}
                src={iconExclamation}
                alt="iconExclamation"
              />
              {purchaseDisabled === 0
                ? '기관 이용권 결제 방법을 선택해 주세요.'
                : '현재 학생수를 고려하여 이용권을 선택해 주세요.'}
            </Box>
          </Box>
          <Box
            style={{
              alignItems: 'center',
              marginTop: '3rem',
              display:
                purchaseDisabled === 0 && clickedCard < 16 && clickedCard >= 8
                  ? 'flex'
                  : 'none',
            }}
          >
            <Box
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                marginLeft: '1rem',
              }}
            >
              신용카드 결제
            </Box>
            <Box
              style={{
                marginLeft: '2rem',
                color: '#68737D',
                alignItems: 'center',
                fontSize: '0.875rem',
              }}
            >
              안전한 토스페이먼트로 결제가 진행됩니다.
            </Box>
            <Box>
              <img style={{width: '6rem'}} src={iconToss} alt="iconToss" />
            </Box>
          </Box>
          <PurchaseSelectGroup
            clickedCard={clickedCard}
            clickEvent={purchaseItem}
            purchaseSectionVisibility={shouldShowPurchaseSelectGroup2() ? 1 : 0}
            studentCount={PurchaseConstants[clickedCard]['studentCount']}
            teacherCount={PurchaseConstants[clickedCard]['teacherCount']}
            newStartDate={passStartDate}
            isDupPass={
              lastPassDate != null && lastPassDate >= passStartDate ? 1 : 0
            }
            defautPayMethod="카드"
          />
        </Box>
      </Box>
      <FooterView />
      <DirectPayPopup
        visibility={directPayVisibility}
        cancelEvent={directPayCancel}
        payEvent={directPayConfirm}
        userName={groupName == '' ? authReducer.export_name : groupName}
      />
    </>
  );
};

export default PurchasePage;
