import React, {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import Main from 'page/main/Main';
import Writing from 'page/writing/Writing';
import WritingStudent from 'page/writing/WritingStudent';
import Result1 from 'page/result/Result1';
import Result2 from 'page/result/Result2';
import Result3 from 'page/result/Result3';
import Login from 'page/login/Login';
import EnterpriseLogin from 'page/login/EnterpriseLogin';
import Mypage from 'page/mypage/Mypage';
import Admin from 'page/admin/Admin';
import Modify from 'page/modify/Modify';
import Modify2 from 'page/modify/Modify2';
import Purchase from 'page/purchase/Purchase';
import TossSuccess from 'page/purchase/TossPurchaseSuccess';
import TossBuySuccess from 'page/purchase/TossGroupPurchaseSuccess';
import TossFail from 'page/purchase/TossPurchaseFail';
import NotFound from 'page/error/NotFound';
import Notice from 'page/notice/Notice';
import FAQ from 'page/notice/FAQ';
import PersonalPurchase from 'page/purchase/PersonalPurchase';
import WritingDone from 'page/writing/WritingDone';
import Patch from 'page/error/ServerPatch';
import RedirectSubmit from 'page/redirect/RedirectSubmit';
import PeerReview from 'page/peer/PeerReview1';
import Term from 'page/term/Term';
import Personal from 'page/personal/Personal';

import MobileWriting from 'page/writing/MobileWriting';
import MobileWritingStudent from 'page/writing/MobileWritingStudent';
import MobileMain from 'page/main/MobileMain';
import MobileMypage from 'page/mypage/MobileMypage';
import MobileTaskPage from 'page/mypage/MobileTaskPage';
import MobileNotice from 'page/notice/MobileNotice';
import MobileLogin from 'page/login/MobileLogin';
import MobileEnterpriseLogin from 'page/login/MobileEnterpriseLogin';
import {isMobileOnly} from 'react-device-detect';

import PrivateWrapper from 'common/router/PrivateWrapper';
import AdminWrapper from 'common/router/AdminWrapper';
import APIUtils from 'common/utils/APIUtils';
import {useContext} from 'react';
import {IPInfoContext} from 'ip-info-react';

const RouterIndex = () => {
  const userInfo = useContext(IPInfoContext);
  const [showPatch, setShowPatch] = useState(false);
  const checkAnnouncement = async () => {
    if (userInfo.ip == '211.109.168.54') {
      return;
    }
    const announcementCheck = async () => {
      try {
        const response = await APIUtils.CheckAnnouncement();
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    announcementCheck()
      .then(r => {
        if (r == undefined) {
          setShowPatch(false);
          return;
        }
        if (r.data.downtime_announce != null) {
          setShowPatch(true);
        } else {
          setShowPatch(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  async function CheckAnnouncement() {
    useEffect(() => {
      checkAnnouncement();

      const handleFocusChange = () => {
        checkAnnouncement();
      };
      window.addEventListener('focus', handleFocusChange);
      return () => {
        window.removeEventListener('focus', handleFocusChange);
      };
    }, []);
  }
  CheckAnnouncement();
  if (showPatch) {
    return (
      <Routes>
        <Route path="/*" element={<Patch />}></Route>
      </Routes>
    );
  }
  if (isMobileOnly) {
    return (
      <Routes>
        <Route path="/" element={<MobileMain />}></Route>
        <Route
          path="/mypage"
          element={
            <PrivateWrapper>
              <MobileMypage />
            </PrivateWrapper>
          }
        ></Route>
        <Route
          path="/mytask"
          element={
            <PrivateWrapper>
              <MobileTaskPage />
            </PrivateWrapper>
          }
        ></Route>
        <Route path="/toss/success" element={<TossSuccess />}></Route>
        <Route path="/toss/buy/success" element={<TossBuySuccess />}></Route>
        <Route path="/toss/fail" element={<TossFail />}></Route>

        <Route
          path="/purchase/personal/:key"
          element={<PersonalPurchase />}
        ></Route>
        <Route
          path="/writing"
          element={
            <PrivateWrapper>
              <MobileWriting />
            </PrivateWrapper>
          }
        ></Route>
        <Route
          path="/writing/student/:key"
          element={<MobileWritingStudent />}
        ></Route>
        <Route path="/writing/done" element={<WritingDone />}></Route>
        <Route path="/login" element={<MobileLogin />}></Route>
        <Route
          path="/login/enterprise"
          element={<MobileEnterpriseLogin />}
        ></Route>
        <Route path="/notice" element={<MobileNotice />}></Route>
        <Route path="/term" element={<Term />}></Route>
        <Route path="/personal" element={<Personal />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Main />}></Route>
      <Route
        path="/writing"
        element={
          <PrivateWrapper>
            <Writing />
          </PrivateWrapper>
        }
      ></Route>
      <Route path="/writing/student/:key" element={<WritingStudent />}></Route>
      <Route path="/writing/done" element={<WritingDone />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/login/enterprise" element={<EnterpriseLogin />}></Route>
      <Route path="/result1" element={<Result1 />}></Route>
      <Route path="/result2" element={<Result2 />}></Route>
      <Route path="/result3" element={<Result3 />}></Route>
      <Route path="/term" element={<Term />}></Route>
      <Route path="/personal" element={<Personal />}></Route>
      <Route path="/peer/review" element={<PeerReview />}></Route>
      <Route
        path="/purchase/personal/:key"
        element={<PersonalPurchase />}
      ></Route>
      <Route
        path="/mypage"
        element={
          <PrivateWrapper>
            <Mypage />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/admin"
        element={
          <AdminWrapper>
            <Admin />
          </AdminWrapper>
        }
      ></Route>
      <Route
        path="/modify"
        element={
          <PrivateWrapper>
            <Modify />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/modify2"
        element={
          <PrivateWrapper>
            <Modify2 />
          </PrivateWrapper>
        }
      ></Route>
      <Route path="/submit" element={<RedirectSubmit />}></Route>
      <Route path="/purchase" element={<Purchase />}></Route>
      <Route path="/toss/success" element={<TossSuccess />}></Route>
      <Route path="/toss/buy/success" element={<TossBuySuccess />}></Route>
      <Route path="/toss/fail" element={<TossFail />}></Route>
      <Route path="/notice" element={<Notice />}></Route>
      <Route path="/faq" element={<FAQ />}></Route>
      <Route path="/*" element={<NotFound />}></Route>
    </Routes>
  );
};
export default RouterIndex;
