import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import TicketView2 from 'view/mypage/TicketView2';
import PropTypes from 'prop-types';
import {StringUtils} from 'common/utils/StringUtils';

export const FragmentView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [purchaseTable, setPurchaseTable] = useState([]);

  function showTicketInfo() {
    if (props.passStatus == 'none') {
      return (
        <Box
          style={{
            color: '#87929d',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            marginTop: '0.5rem',
          }}
        >
          보유하고 있는 이용권이 없습니다.
        </Box>
      );
    }
    let statusText = '그룹';
    if (props.passStatus == 'individual') {
      statusText = '개인';
    } else if (props.passStatus == 'free') {
      statusText = '무료';
    }
    return (
      <Box
        style={{
          color: '#87929d',
          fontSize: '0.875rem',
          fontWeight: 'bold',
          marginTop: '0.5rem',
        }}
      >
        {'[' + statusText + '] ' + props.passStudentCount + '명'}
        <span style={{color: '#ff9900', marginLeft: '0.2rem'}}>
          {'~' + StringUtils.getDateTimeKr(props.passEndTime)}
        </span>
      </Box>
    );
  }

  async function getTossReceipt(pKey) {
    const getTossItemInfo = async pay_key => {
      try {
        const response = await APIUtils.TossItem(pay_key);
        return response.data.receipt.url;
      } catch (err) {
        return '';
      }
    };

    return await getTossItemInfo(pKey);
  }

  useEffect(() => {
    const purchaseHistory = async () => {
      try {
        const response = await APIUtils.PassList(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const tossReceipt = async table => {
      try {
        const response = await table.map(async val => {
          if (val.payment_key == '') {
            val['receipt'] = '';
            return val;
          }
          val['receipt'] = await getTossReceipt(val.payment_key);
          return val;
        });
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    purchaseHistory().then(res => {
      setPurchaseTable(res.data.pass_list);
    });
  }, []);
  return (
    <Box>
      <Box style={{marginTop: '3rem', marginLeft: '3rem'}}>
        키위티의 이용권 구매 페이지에서 직접 결제한 이용권 구매 내역과
        영수증입니다.
      </Box>
      <Box style={{marginTop: '3rem'}}>
        {purchaseTable
          .slice()
          .sort((a, b) => new Date(b.pass_log_time) - new Date(a.pass_log_time))
          .map((val, idx) => {
            return (
              <TicketView2
                key={'pass_view' + idx}
                ticket_name={val.pass_name}
                start_time={val.pass_start_time}
                end_time={val.pass_end_time}
                log_time={val.pass_log_time}
                receipt={val.receipt ?? ''}
              />
            );
          })}
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          display: props.passStatus === 'free' ? '' : 'none',
        }}
      >
        무료이용권을 보유하고 있습니다.
        {showTicketInfo()}
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          display: props.passStatus === 'none' ? '' : 'none',
        }}
      >
        {showTicketInfo()}
      </Box>
    </Box>
  );
};
FragmentView.propTypes = {
  passStatus: PropTypes.string,
  passEndTime: PropTypes.string,
  passStudentCount: PropTypes.number,
};
export default FragmentView;
