import React, {useEffect, useState} from 'react';
import {Box, Grid, Button, TextField} from '@mui/material';
import {useSelector} from 'react-redux';
import HeaderView from 'view/common/MobileHeader';
import APIUtils from 'common/utils/APIUtils';
import {
  mypageBox1,
  mypageBox2,
  mypageWholePage,
} from 'view/mypage/MobileStyles';
import FooterView from 'view/common/MobileFooter';
import TaskFinishPopup from 'view/mobilepopup/TaskFinishConfirm';
import TaskDeletePopup from 'view/mobilepopup/TaskDeleteConfirm';
import {StringUtils} from 'common/utils/StringUtils';
import {TaskTable} from 'view/mypage/MobileTaskTable';

const MyPage = () => {
  const authReducer = useSelector(state => state.authReducer);

  const [taskList, setTaskList] = useState([]);
  const [originalTaskList, setOriginalTaskList] = useState([]);
  const [groupName, setGroupName] = useState('');

  const [passStatus, setPassStatus] = useState('none');
  const [passEndTime, setPassEndTime] = useState('');
  const [passStudentCount, setPassStudentCount] = useState(0);

  const [removingTask, setRemovingTask] = useState(undefined);
  const [taskRemoveVisibility, setTaskRemoveVisibility] = useState(false);
  function taskRemoveCancel() {
    setTaskRemoveVisibility(false);
  }
  function taskRemoveConfirm() {
    const deleteTaskApi = async () => {
      try {
        const response = await APIUtils.TaskDelete(
          authReducer.user_id,
          removingTask.task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    deleteTaskApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getTaskList();
      }
      setTaskRemoveVisibility(false);
    });
  }

  const [finishingTask, setFinishingTask] = useState(undefined);
  const [taskFinishVisibility, setTaskFinishVisibility] = useState(false);
  function taskFinishCancel() {
    setTaskFinishVisibility(false);
  }
  function taskFinishConfirm() {
    const deleteTaskApi = async () => {
      try {
        const response = await APIUtils.TaskEnd(
          authReducer.user_id,
          finishingTask.task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    deleteTaskApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getTaskList();
      }
      setTaskFinishVisibility(false);
    });
  }

  function taskTableFilter() {
    let filteredTask = [...originalTaskList];
    filteredTask.sort(function (a, b) {
      if (a.status == '진행중' && b.status != '진행중') return -1;
      if (a.status != '진행중' && b.status == '진행중') return 1;
      if (a.status == '대기' && b.status != '대기') return -1;
      if (a.status != '대기' && b.status == '대기') return 1;
      return new Date(b.end_time) - new Date(a.end_time);
    });
    setTaskList(filteredTask);
  }

  function taskDelete(delTask) {
    setRemovingTask(delTask);
    setTaskRemoveVisibility(true);
  }

  function taskFinish(finTask) {
    setFinishingTask(finTask);
    setTaskFinishVisibility(true);
  }
  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheckShort(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setPassStatus(response.data.status);
          setPassEndTime(response.data.end_time);
          setPassStudentCount(response.data.max_student_count);
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function showTicketInfo() {
    if (passStatus == 'none') {
      return (
        <Box
          style={{
            color: '#87929d',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            marginTop: '0.2rem',
          }}
        >
          KEEwiT 이용권 미결제
        </Box>
      );
    }
    let statusText = '그룹';
    if (passStatus == 'individual') {
      statusText = '개인';
    } else if (passStatus == 'free') {
      statusText = '무료';
    }
    return (
      <Box
        style={{
          color: '#87929d',
          fontSize: '0.875rem',
          fontWeight: 'bold',
          marginTop: '0.2rem',
        }}
      >
        {'[' + statusText + '] ' + passStudentCount + '명'}
        <span style={{color: '#ff9900', marginLeft: '0.2rem'}}>
          {'~' + StringUtils.getDateKr(passEndTime)}
        </span>
      </Box>
    );
  }
  function getGroupInfo() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.GroupInfo(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setGroupName(res.data.group_name);
      }
    });
  }
  function getTaskList() {
    const getTask = async () => {
      try {
        const response = await APIUtils.TaskView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getTask().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        const tempTaskList = res.data.task_list;
        setOriginalTaskList(tempTaskList);
      }
    });
  }
  useEffect(() => {
    getTaskList();
    getGroupInfo();
    getPassInfo();
  }, [taskFinishVisibility, taskRemoveVisibility]);
  useEffect(() => {
    taskTableFilter();
  }, [originalTaskList]);
  return (
    <Box style={mypageWholePage}>
      <TaskFinishPopup
        visibility={taskFinishVisibility}
        completeEvent={taskFinishConfirm}
        cancelEvent={taskFinishCancel}
      />
      <TaskDeletePopup
        visibility={taskRemoveVisibility}
        completeEvent={taskRemoveConfirm}
        cancelEvent={taskRemoveCancel}
      />
      <HeaderView />
      <Box style={mypageBox1}>
        <Box
          style={{fontSize: '0.8rem', marginTop: '0.3rem', color: '#717171'}}
        >
          {groupName == '' ? '(그룹 없음)' : groupName}
        </Box>
        <Box
          style={{fontSize: '1.7rem', fontWeight: 'bold', marginTop: '0.5rem'}}
        >
          {authReducer.export_name}
          {' 님'}
        </Box>
        <Box
          style={{
            color: '#68737d',
            fontSize: '0.8rem',
            fontWeight: 'normal',
            gap: '0.5rem',
            display: 'flex',
            marginTop: '0.5rem',
          }}
        >
          <Box> {authReducer.user_email}</Box>
          <Box>{'|'}</Box>
          <Box>
            {'인증 번호: '}{' '}
            {authReducer.auth_key == '' || authReducer.auth_key == undefined
              ? '0000'
              : authReducer.auth_key.substring(0, 4)}{' '}
            {'-'}
            {authReducer.auth_key == '' || authReducer.auth_key == undefined
              ? '0000'
              : authReducer.auth_key.substring(4, 8)}
          </Box>
        </Box>
        {showTicketInfo()}
      </Box>
      <Box style={mypageBox2}>
        <Box style={{fontSize: '1.6rem', fontWeight: 'bold', color: '#717171'}}>
          과제 관리
        </Box>
        <Box
          style={{
            color: '#717171',
            fontSize: '0.8rem',
            marginTop: '1rem',
            marginBottom: '2rem',
            whiteSpace: 'pre-wrap',
          }}
        >
          {
            '진행/대기 과제는 삭제하거나 종료할 수 있습니다. 자세한 내용은 PC에서 확인하세요.'
          }
        </Box>
        <TaskTable
          taskList={taskList}
          finishEvent={taskFinish}
          deleteEvent={taskDelete}
        />
      </Box>
      <FooterView />
    </Box>
  );
};

export default MyPage;
