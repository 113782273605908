import PropTypes from 'prop-types';
import {Box, Button, IconButton} from '@mui/material';
import React, {useState} from 'react';
import {StringUtils} from 'common/utils/StringUtils';
import iconArrowDown from 'asset/image/icon_arrow_down.png';

export const TicketView = props => {
  const receiptExist = props.receipt != '';

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center', // ✅ 요소 높이를 일관되게 맞춤
        justifyContent: 'space-between', // ✅ 공간을 균등 분배
        padding: '0.7rem 1rem', // ✅ 적절한 패딩 추가
        borderBottom: '1px solid #E0E0E0', // ✅ 구분선 추가 (더 깔끔하게 보이도록)
      }}
    >
      {/* 구매 날짜 */}
      <Box
        style={{
          width: '10rem',
          textAlign: 'left', // ✅ 텍스트 왼쪽 정렬
          color: '#49545C', // ✅ 색상 조정
          fontSize: '0.9rem', // ✅ 가독성 좋은 크기
        }}
      >
        {StringUtils.getDateTimeString(props.log_time)}
      </Box>

      {/* 티켓 이름 */}
      <Box
        style={{
          flex: 1, // ✅ 남은 공간 차지
          textAlign: 'left', // ✅ 텍스트 왼쪽 정렬
          color: '#333', // ✅ 진한 색상으로 강조
          fontSize: '1rem', // ✅ 가독성 좋은 크기
          fontWeight: 'bold', // ✅ 가독성 향상
          marginLeft: '1rem', // ✅ 좌측 간격 추가
        }}
      >
        {props.ticket_name}
      </Box>

      {/* 다운로드 버튼 */}
      <Box
        style={{
          width: '7rem',
          textAlign: 'right', // ✅ 텍스트 오른쪽 정렬
          color: receiptExist ? '#279EFF' : '#A0A0A0', // ✅ 활성화 여부에 따라 색상 변경
          cursor: receiptExist ? 'pointer' : 'default', // ✅ 클릭 가능 여부
          textDecoration: receiptExist ? 'underline' : 'none', // ✅ 링크 스타일 적용
          fontSize: '0.9rem', // ✅ 크기 조정
        }}
        onClick={() => {
          if (receiptExist) {
            window.open(props.receipt, '_blank');
          }
        }}
      >
        {receiptExist ? '영수증' : '-'}
      </Box>
    </Box>
  );
};
TicketView.propTypes = {
  ticket_name: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  log_time: PropTypes.string,
  available: PropTypes.number,
  receipt: PropTypes.string,
};
export default TicketView;
