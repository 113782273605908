import React, {useState, useRef, useEffect} from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import writingSelectOption from 'asset/json/writing_select_option1.json';
import ageSelectOption from 'asset/json/age_select_option2.json';

const ItemView = props => {
  const [titleText, setTitleText] = useState('');
  const inputRef = useRef(null); // Reference for TextField

  useEffect(() => {
    if (props.visibility && inputRef.current) {
      inputRef.current.focus(); // Automatically focus on the title input field
    }
  }, [props.visibility]);

  const handleTitle = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 25) {
      inputVal = inputVal.substring(0, 25);
    }
    setTitleText(inputVal);
  };

  const [memoText, setMemoText] = useState('');
  const handleMemo = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 1000) {
      inputVal = inputVal.substring(0, 1000);
    }
    setMemoText(inputVal);
  };

  const [writingSelect, setWritingSelect] = useState(0);

  const handleWritingSelectInput = event => {
    setWritingSelect(event.target.value);
  };

  const [ageSelect, setAgeSelect] = useState(0);

  const handleAgeSelectInput = event => {
    setAgeSelect(event.target.value);
  };

  function tryCreateLibrary() {
    props.confirmEvent(
      titleText,
      memoText,
      writingSelectOption[writingSelect],
      ageSelect + 1,
    );
  }

  useEffect(() => {
    setTitleText('');
    setMemoText('');
    setWritingSelect(0);
    setAgeSelect(0);
  }, [props.visibility]);

  if (!props.visibility) {
    return <></>;
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Box style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
            {'라이브러리 과제 만들기'}
          </Box>
          <IconButton onClick={props.cancelEvent}>
            <img src={IconCancel} alt="Cancel" />
          </IconButton>
        </Box>

        <Box>
          <Box
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box style={{fontWeight: '500'}}>대상</Box>
            <Box style={{width: '30%'}}>
              <Select
                disableUnderline
                fullWidth
                labelId="select-writing"
                id="ageSelection"
                variant="filled"
                hiddenLabel
                onChange={handleAgeSelectInput}
                value={ageSelect}
                style={{
                  backgroundColor: '#F7F8FA',
                  border: '0px solid black',
                  borderRadius: '0.625rem',
                  fontSize: '0.875rem',
                }}
              >
                {ageSelectOption.map((value, index) => {
                  return (
                    <MenuItem
                      key={'key' + index}
                      value={index}
                      style={{fontSize: '0.875rem'}}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box style={{fontWeight: '500'}}>평가 장르</Box>
            <Box style={{width: '30%'}}>
              <Select
                disableUnderline
                fullWidth
                labelId="select-writing"
                id="writingType"
                variant="filled"
                hiddenLabel
                onChange={handleWritingSelectInput}
                value={writingSelect}
                style={{
                  backgroundColor: '#F7F8FA',
                  border: '0px solid black',
                  borderRadius: '0.625rem',
                  fontSize: '0.875rem',
                }}
              >
                {writingSelectOption.map((value, index) => {
                  return (
                    <MenuItem
                      key={'key' + index}
                      value={index}
                      style={{fontSize: '0.875rem'}}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>

          <Box style={{fontWeight: '500', marginTop: '1rem'}}>과제 제목</Box>
          <Box
            style={{
              marginTop: '0.5rem',
              marginLeft: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TextField
              autoComplete="off"
              variant="filled"
              inputRef={inputRef} // Assign ref to TextField
              sx={{
                width: '30rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderBottom: '1px solid black',
                  },
                  '&:hover fieldset': {
                    borderBottom: '1px solid black',
                  },
                  '&.Mui-focused fieldset': {
                    borderBottom: '1px solid black',
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                  color: '#A8B1B7',
                  position: 'absolute',
                  top: '1.0rem',
                  left: '0.1rem',
                  backgroundColor: 'white',
                  padding: '0 0.25rem',
                },
                shrink: true,
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontWeight: 'normal',
                  color: 'black',
                  backgroundColor: 'white',
                  fontSize: '1rem',
                  borderBottom: '1px solid black',
                  display: 'flex',
                },
              }}
              value={titleText}
              onChange={handleTitle}
            ></TextField>
          </Box>

          <Box
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box style={{fontWeight: '500'}}>과제 내용(문항, 전달 사항)</Box>
            <Box>
              <span style={{color: '#35D2BB'}}>{memoText.length}</span>
              /1000
            </Box>
          </Box>
          <Box
            style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}
          >
            <TextField
              autoComplete="off"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                  '&:hover fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                },
              }}
              variant="outlined"
              fullWidth
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  fontWeight: 500,
                },
              }}
              rows={10}
              multiline
              value={memoText}
              onChange={handleMemo}
            ></TextField>
          </Box>

          <Box style={{marginTop: '2rem', display: 'flex'}}>
            <Button
              disabled={titleText === '' || memoText === ''}
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                color: 'white',
                backgroundColor:
                  titleText === '' || memoText === '' ? '#A8B1B7' : '#35D2BB',
                fontSize: '1.125rem',
                fontWeight: 'bold',
                padding: '0.9rem 0rem',
                marginLeft: '1rem',
                borderRadius: '6.25rem',
              }}
              onClick={() => tryCreateLibrary()}
            >
              {'라이브러리에 저장하기'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};

export default ItemView;
