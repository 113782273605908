import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';

const ItemView = props => {
  const [inputState, setInputState] = useState(false);
  const [payAmount, setPayAmount] = useState('');
  const [customerName, setCustomerName] = useState(props.userName || '');

  const changeName = event => {
    let inputVal = event.target.value.trim(); // 공백 제거
    setCustomerName(inputVal.substring(0, 15)); // 최대 15자 제한
  };

  const changeAmount = event => {
    let inputValue = event.target.value.replace(/\D/g, ''); // 숫자만 남김
    setPayAmount(inputValue || ''); // 빈 값일 경우 빈 문자열 유지
  };

  // ✅ 고객명과 결제금액이 모두 충족되었을 때 버튼 활성화
  useEffect(() => {
    const isValid = customerName.length >= 1 && Number(payAmount) >= 1000;
    setInputState(isValid);
  }, [customerName, payAmount]);

  if (!props.visibility) {
    return null;
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          padding: '2rem 3rem',
          color: 'black',
        }}
      >
        <Box style={{display: 'flex', justifyContent: 'end'}}>
          <IconButton onClick={props.cancelEvent}>
            <img src={IconCancel} alt="Cancel" />
          </IconButton>
        </Box>
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
          }}
        >
          견적 금액 입금하기
        </Box>
        <Box style={{color: '#68737D', fontSize: '0.875rem'}}>
          매출 전표에 표시될 고객명과 받은 견적서의 금액을 입력해 주세요.
        </Box>

        {/* 고객명 입력 */}
        <Box style={{display: 'flex', alignItems: 'center', marginTop: '3rem'}}>
          <Box
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              width: '5rem',
              textAlign: 'right',
            }}
          >
            고객명
          </Box>
          <TextField
            autoComplete="off"
            variant="outlined"
            value={customerName}
            onChange={changeName}
            placeholder="매출전표의 고객명에 노출됩니다."
            inputProps={{maxLength: 15}}
            style={{
              marginLeft: '1rem',
              width: '100%',
              backgroundColor: '#F7F8FA',
              borderRadius: '0.5rem',
            }}
          />
        </Box>

        <Box style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
          <Box
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              width: '5rem',
              textAlign: 'right',
            }}
          >
            금액
          </Box>
          <TextField
            autoComplete="off"
            variant="outlined"
            value={payAmount}
            onChange={changeAmount}
            placeholder="고객센터에서 받은 견적 금액을 입력해주세요."
            style={{
              marginLeft: '1rem',
              width: '100%',
              backgroundColor: '#F7F8FA',
              borderRadius: '0.5rem',
            }}
          />
        </Box>

        {/* 결제 버튼 */}
        <Box style={{marginTop: '3rem'}}>
          <Button
            disabled={!inputState}
            style={{
              width: '100%',
              color: 'white',
              backgroundColor: inputState ? '#35D2BB' : '#A8B1B7',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0',
              borderRadius: '6.25rem',
            }}
            onClick={() => props.payEvent(Number(payAmount), customerName)}
          >
            결제하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ItemView.propTypes = {
  visibility: PropTypes.bool,
  userName: PropTypes.string,
  payEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
};

export default ItemView;
