import React, {useState} from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import {styled} from '@mui/material/styles';

const CustomSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  width: '2.875rem',
  height: '1.25rem',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(1.5rem)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#35D2BB',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: '1.1rem',
    height: '1.1rem',
    marginTop: '0.1rem',
    marginLeft: '0.1rem',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#A7A7A7',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ItemView = props => {
  const [selectedValue, setSelectedValue] = useState(props.maxTryCount || 0);

  const handleChange = event => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    props.handleMaxTryCount(newValue); // 부모 컴포넌트에 값 전달
  };
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '1rem',
          gap: '5rem',
        }}
      >
        <Box
          style={{
            flex: 1,
            fontSize: '1.5rem',
            fontWeight: 'bold',
            borderBottom: '2px solid #A7A7A7',
            paddingRight: '1rem',
          }}
        >
          {props.titleText}
        </Box>
        <IconButton onClick={props.cancelEvent}>
          <img src={IconCancel} />
        </IconButton>
      </Box>
      {/* 공통 스타일 적용 */}
      <Box
        style={{
          marginTop: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        {/* AI 피드백 */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            fontWeight: 'bold',
            height: '2rem',
            fontSize: '0.875rem',
          }}
        >
          <Box style={{width: '4rem', fontWeight: '500'}}>AI 피드백</Box>
          <CustomSwitch
            checked={props.feedbackChecked}
            onChange={props.handleFeedbackChange}
          />
          <Box style={{fontSize: '0.75rem', color: '#68737D', flex: 1}}>
            {props.feedbackChecked
              ? '※ AI 점수와 피드백을 제공합니다.'
              : '※ 시험 및 대회 목적에 적합합니다.'}
          </Box>
          <Box
            sx={{
              display: props.feedbackChecked ? 'flex' : 'none',
              minWidth: 120,
              alignItems: 'center',
            }}
          >
            <FormControl
              fullWidth
              size="small"
              sx={{height: '36px', backgroundColor: 'white'}}
            >
              <InputLabel
                id="attempt-select-label"
                sx={{
                  align: 'center',
                  height: '1rem',
                  transform: 'translate(25px, -15px)',
                  fontSize: '0.65rem',
                }}
              >
                최대 수정 횟수
              </InputLabel>
              <Select
                labelId="attempt-select-label"
                id="attempt-select"
                value={selectedValue}
                onChange={handleChange}
                sx={{
                  width: '7rem',
                  fontSize: '0.875rem', // ✅ 글자 크기 작게
                  padding: '5px 5px', // ✅ 내부 패딩 조정
                }}
                MenuProps={{
                  disableScrollLock: true, // ✅ 스크롤 락 방지 (오른쪽 스크롤바 문제 해결)
                  PaperProps: {
                    sx: {
                      maxHeight: 200, // ✅ 리스트가 너무 길어지지 않도록 제한
                      fontSize: '0.8rem', // ✅ 드롭다운 폰트 크기 조정
                    },
                  },
                }}
              >
                <MenuItem value={0} sx={{fontSize: '0.8rem'}}>
                  제한없음
                </MenuItem>
                <MenuItem value={3} sx={{fontSize: '0.8rem'}}>
                  3회
                </MenuItem>
                <MenuItem value={5} sx={{fontSize: '0.8rem'}}>
                  5회
                </MenuItem>
                <MenuItem value={10} sx={{fontSize: '0.8rem'}}>
                  10회
                </MenuItem>
                <MenuItem value={20} sx={{fontSize: '0.8rem'}}>
                  20회
                </MenuItem>
                <MenuItem value={30} sx={{fontSize: '0.8rem'}}>
                  30회
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* 키위챗 */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            fontWeight: 'bold',
            height: '2rem',
            fontSize: '0.875rem',
          }}
        >
          <Box style={{width: '4rem', fontWeight: '500'}}>키위챗</Box>
          <CustomSwitch
            checked={props.keewichatChecked}
            onChange={props.handleKeewichatChange}
          />
          <Box style={{fontSize: '0.75rem', color: '#68737D', flex: 1}}>
            {props.keewichatChecked
              ? '※ 아이디어 구상에 도움을 줍니다.'
              : '※ 챗봇 없이 과제를 수행합니다.'}
          </Box>
        </Box>

        {/* 동료 평가 */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            fontWeight: 'bold',
            height: '2rem',
            fontSize: '0.875rem',
          }}
        >
          <Box style={{width: '4rem', fontWeight: '500'}}>동료 평가</Box>
          <CustomSwitch
            checked={props.peerReviewChecked}
            onChange={props.handlePeerReviewChange}
          />
          <Box style={{fontSize: '0.75rem', color: '#68737D', flex: 1}}>
            {props.peerReviewChecked
              ? '※ 글을 서로 공유·평가할 수 있습니다.'
              : ''}
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0.5rem',
          fontWeight: 'bold',
        }}
      ></Box>

      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        <Box style={{fontWeight: '500'}}>필수 글자 수(공백 포함)</Box>
        <CustomSwitch
          checked={props.textCountChecked}
          onChange={props.handleTextCountChange}
        />
      </Box>
      <Box style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
        <TextField
          disabled={!props.textCountChecked}
          autoComplete="off"
          sx={{
            width: '7rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          value={props.textCountMin}
          onChange={props.handleTextCountMin}
        ></TextField>
        <Box
          style={{
            whiteSpace: 'nowrap',
            marginLeft: '1rem',
            fontWeight: 'bold',
          }}
        >
          {'자'}
        </Box>
        <Box
          style={{
            whiteSpace: 'nowrap',
            marginLeft: '2rem',
            marginRight: '2rem',
            fontWeight: 'bold',
          }}
        >
          {'~'}
        </Box>
        <TextField
          disabled={!props.textCountChecked}
          autoComplete="off"
          sx={{
            width: '7rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          value={props.textCountMax}
          onChange={props.handleTextCountMax}
        ></TextField>
        <Box
          style={{whiteSpace: 'nowrap', marginLeft: '1rem', fontWeight: 'bold'}}
        >
          자
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        <Box style={{fontWeight: '500'}}>필수 키워드(최대 5개)</Box>
        <CustomSwitch
          checked={props.requiredWordChecked}
          onChange={props.handleRequiredWordChange}
        />
      </Box>
      <Box
        style={{
          marginTop: '1rem',
        }}
      >
        <TextField
          disabled={!props.requiredWordChecked}
          autoComplete="off"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          fullWidth
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          label={
            props.requiredWord == ''
              ? '쉼표로 단어를 구분해 주세요. (예) 감사, 혐오, 핵가족 등 최대 5개'
              : ''
          }
          InputLabelProps={{
            style: {
              color: 'rgba(108, 108, 108, 0.721)',
              fontSize: '0.875rem',
            },
            shrink: false,
          }}
          value={props.requiredWord}
          onChange={props.handelRequiredWord}
        ></TextField>
      </Box>
      <Box style={{marginTop: '4rem', display: 'flex'}}>
        <Button
          style={{
            flex: '1',
            whiteSpace: 'nowrap',
            color: '#35D2BB',
            backgroundColor: 'white',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            borderRadius: '6.25rem',
            border: '1px solid #35D2BB',
          }}
          onClick={() => props.previousEvent()}
        >
          {'< 이전'}
        </Button>
        <Button
          style={{
            flex: '3',
            whiteSpace: 'nowrap',
            color: 'white',
            backgroundColor: '#35D2BB',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            marginLeft: '1rem',
            borderRadius: '6.25rem',
          }}
          onClick={() => props.nextEvent()}
        >
          {'step 03. 학생 선택하기 '}
        </Button>
      </Box>
      <Box
        style={{
          fontSize: '0.75rem',
          color: '#68737D',
          textAlign: 'end',
          marginTop: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        과제를 수행할 학생을 선택합니다. class별/개인별로 리스트를 확인할 수
        있습니다.
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  titleText: PropTypes.string,
  feedbackChecked: PropTypes.bool,
  handleFeedbackChange: PropTypes.func,
  keewichatChecked: PropTypes.bool,
  handleKeewichatChange: PropTypes.func,
  peerReviewChecked: PropTypes.bool,
  handlePeerReviewChange: PropTypes.func,
  textCountChecked: PropTypes.bool,
  handleTextCountChange: PropTypes.func,
  textCountMin: PropTypes.string,
  handleTextCountMin: PropTypes.func,
  textCountMax: PropTypes.string,
  handleTextCountMax: PropTypes.func,
  requiredWordChecked: PropTypes.bool,
  handleRequiredWordChange: PropTypes.func,
  requiredWord: PropTypes.string,
  handelRequiredWord: PropTypes.func,
  previousEvent: PropTypes.func,
  nextEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  maxTryCount: PropTypes.number,
  handleMaxTryCount: PropTypes.func,
};
export default ItemView;
