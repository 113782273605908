import {Box, TextField, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import IconPdf from 'asset/imageV2/icon_pdf.png';
import IconDownload from 'asset/imageV2/icon_download.png';
import ImageAdmin1 from 'asset/imageV2/image_admin1.png';
import ImageAdmin2 from 'asset/imageV2/image_admin2.png';
import TicketView from './TicketView1';

import emojiRegex from 'emoji-regex';
import CodeResetPopup from './GroupCodeResetConfirm';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const countPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [groupName, setGroupName] = useState('');
  const [groupID, setGroupID] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [groupAddress, setGroupAddress] = useState('');
  const [groupAdminEmail, setGroupAdminEmail] = useState('');
  const [teacherCount, setTeacherCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [passTecherCount, setPassTeacherCount] = useState(0);
  const [passStudentCount, setPassStudentCount] = useState(0);
  const [groupTicket, setGroupTicket] = useState([]);
  const [codeResetVisibility, SetCodeResetVisibility] = useState(false);
  const [teacherColor, setTeacherColor] = useState('#279EFF');
  const [studentColor, setStudentColor] = useState('#279EFF');

  useEffect(() => {
    // ✅ teacherCount와 passTecherCount 값이 변할 때마다 color 업데이트
    if (passTecherCount !== undefined && teacherCount !== undefined) {
      setTeacherColor(passTecherCount > teacherCount ? '#279EFF' : '#FF9900 ');
    }
  }, [passTecherCount, teacherCount]); // ✅ 상태가 변경될 때마다 실행

  useEffect(() => {
    // ✅ teacherCount와 passTecherCount 값이 변할 때마다 color 업데이트
    if (passStudentCount !== undefined && studentCount !== undefined) {
      setStudentColor(passStudentCount > studentCount ? '#279EFF' : '#FF9900 ');
    }
  }, [passStudentCount, studentCount]); // ✅ 상태가 변경될 때마다 실행

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function saveGroupInfo() {
    const modifyGroupInfo = async (user_id, group_name, group_address) => {
      try {
        const response = await APIUtils.GroupInfoModify(
          user_id,
          group_name,
          group_address,
        );
        if (response.data.ret_code == 1000) {
          alert('저장되었습니다.');
        }
      } catch (err) {
        console.log(err);
      }
    };
    modifyGroupInfo(authReducer.user_id, groupName, groupAddress);
  }
  function codeReset() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.AdminCodeReset(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getAdminInfo();
      }
    });
    SetCodeResetVisibility(false);
  }
  function codeResetCancel() {
    SetCodeResetVisibility(false);
  }
  function getAdminInfo() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.AdminView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setGroupCode(res.data.group_code);
        setTeacherCount(res.data.teacher_count);
        setStudentCount(res.data.student_count);
        setGroupAddress(res.data.group_location);
      }
    });
  }
  function getGroupInfo() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.GroupInfo(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        if (res.data.group_name) setGroupName(res.data.group_name);
        else setGroupName('');
        setGroupAdminEmail(res.data.group_admin_email);
        setGroupID(res.data.group_id);

        setTotalPage(
          Math.floor((res.data.group_inventory.length - 1) / countPerPage + 1),
        );
        setGroupTicket(
          res.data.group_inventory.sort(
            (a, b) =>
              new Date(b.group_pass_start_time) -
              new Date(a.group_pass_start_time),
          ),
        );

        const now = new Date();

        // 사용 가능한 항목 필터링 (현재 날짜가 이용 가능 기간 내에 있는 항목만)
        const activeGroupTickets = res.data.group_inventory.filter(item => {
          return (
            new Date(item.group_pass_start_time) <= now && // 이용 시작일이 오늘보다 같거나 이전
            new Date(item.group_pass_end_time) >= now // 이용 종료일이 오늘보다 같거나 이후
          );
        });

        // 현재 사용 가능한 학생 수 계산
        const totalStudents = activeGroupTickets.reduce(
          (sum, item) => sum + (item.group_pass_student_num || 0), // ✅ undefined 방지
          0,
        );
        setPassStudentCount(totalStudents);

        // 현재 사용 가능한 선생님 수 계산
        const totalTeachers = activeGroupTickets.reduce(
          (max, item) => Math.max(max, item.group_pass_teacher_num || 0),
          0,
        );
        setPassTeacherCount(totalTeachers);
      }
    });
  }
  const changeGroupName = event => {
    let inputVal = event.target.value;

    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 15) {
        inputVal = inputVal.substring(0, 15);
      }
      setGroupName(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const changeGroupAddress = event => {
    let inputVal = event.target.value;

    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setGroupAddress(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  useEffect(() => {
    getAdminInfo();
    getGroupInfo();
  }, []);
  return (
    <Box style={{width: '95%', marginLeft: '2rem', maxWidth: '120rem'}}>
      <CodeResetPopup
        visibility={codeResetVisibility}
        codeReset={codeReset}
        cancelEvent={codeResetCancel}
      />

      <Box style={{fontWeight: 'bold', fontSize: '2rem', color: '#4b4d4c'}}>
        {groupName} <font color="#68737d"> 관리자</font>
      </Box>
      <Box
        style={{
          marginTop: '0.5rem',
          color: '#87929D',
          fontSize: '0.875rem',
          flex: 1,
          overflow: 'hidden',
          maxHeight: '3rem',
          marginBottom: '0.5rem',
        }}
      >
        그룹에 소속된 선생님을 내보내기 하거나{' '}
        <font color="#ff9900">그룹코드를 변경</font>할 수 있습니다. 그룹코드가
        변경되어도 이전에 소속된 선생님은 그대로 유지됩니다.
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          gap: '2rem',
        }}
      >
        <Box style={{flex: 3}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                margin: '1rem 0rem 0rem 2rem',
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              그룹 정보
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: '1rem',
                marginLeft: '5rem',
              }}
            >
              <Box
                style={{
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  width: '5rem',
                  marginTop: '0.5rem',
                }}
              >
                기관명
              </Box>
              <Box
                style={{
                  position: 'relative',
                  width: '400px',
                }}
              >
                {/* 입력 필드 */}
                <TextField
                  autoComplete="off"
                  variant="outlined"
                  InputProps={{
                    style: {
                      backgroundColor: '#F7F8FA',
                      border: '1px solid #E9EBED',
                      fontSize: '0.875rem',
                      borderRadius: '0.5rem',
                      height: '2rem',
                      paddingLeft: '0rem',
                    },
                  }}
                  value={groupName}
                  onChange={changeGroupName}
                  style={{
                    width: '100%',
                  }}
                />

                {/* 입력이 비어 있을 때 중앙에 라벨 표시 */}
                {groupName === '' && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '1rem',
                      transform: 'translateY(-50%)',
                      fontSize: '0.875rem',
                      color: '#ADB4BA',
                      pointerEvents: 'none', // 클릭 방지
                    }}
                  >
                    그룹에 소속된 선생님에게 보여집니다.
                  </Box>
                )}

                {/* 입력 글자 수 표시 */}
                <Box
                  style={{
                    position: 'absolute',
                    bottom: '0.5rem',
                    right: '0.5rem',
                    fontSize: '0.75rem',
                    color: '#ADB4BA',
                  }}
                >
                  {groupName.length}/15
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: '1rem',
                marginTop: '0.5rem',
                marginLeft: '5rem',
              }}
            >
              <Box
                style={{
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  width: '5rem',
                }}
              >
                소재지
              </Box>
              <Box style={{position: 'relative', width: '400px'}}>
                {/* 입력 필드 */}
                <TextField
                  autoComplete="off"
                  variant="outlined"
                  InputProps={{
                    style: {
                      backgroundColor: '#F7F8FA',
                      border: '1px solid #E9EBED',
                      fontSize: '0.875rem',
                      borderRadius: '0.5rem',
                      height: '2rem',
                      paddingLeft: '0rem',
                    },
                  }}
                  value={groupAddress}
                  onChange={changeGroupAddress}
                  style={{
                    width: '100%',
                  }}
                />

                {/* 입력이 비어 있을 때 중앙에 라벨 표시 */}
                {groupAddress === '' && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '1rem',
                      transform: 'translateY(-50%)',
                      fontSize: '0.875rem',
                      color: '#ADB4BA',
                      pointerEvents: 'none', // 클릭 방지
                    }}
                  >
                    고객센터에서 연락하기 위한 주소입니다.
                  </Box>
                )}

                {/* 입력 글자 수 표시 */}
                <Box
                  style={{
                    position: 'absolute',
                    bottom: '0.5rem',
                    right: '0.5rem',
                    fontSize: '0.75rem',
                    color: '#ADB4BA',
                  }}
                >
                  {groupAddress.length}/50
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                display: 'flex',
                alignItems: 'center', // 수직 중앙 정렬
                justifyContent: 'start', // 요소 간 간격 조정
                gap: '1rem', // 요소 간 간격
                marginLeft: '5rem',
                marginTop: '0.5rem',
              }}
            >
              <Box
                style={{
                  fontSize: '0.875rem', // 원래 폰트 크기로 복구
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지
                  width: '5rem',
                }}
              >
                관리자
              </Box>

              <Box>
                {authReducer.export_name + ' '} /{' ' + groupAdminEmail}
              </Box>
            </Box>
            <Button
              style={{
                color: 'black',
                fontSize: '0.875rem',
                border: '1.5px solid #ff9900',
                borderRadius: '1.925rem',
                marginTop: '1rem',
                marginLeft: '5rem',
                marginBottom: '1.5rem',
                width: '8rem',
                padding: '0.5rem 1.5rem',
                backgroundColor: '#ff9900',
                whiteSpace: 'nowrap',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                justifyContent: 'center',
              }}
              onClick={saveGroupInfo}
            >
              그룹 정보 변경
            </Button>
          </Box>
        </Box>
        <Box style={{flex: 1}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start',
            }}
          >
            <Box
              style={{
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                marginLeft: '2rem',
              }}
            >
              그룹 코드
            </Box>
            <Box
              style={{
                width: '100%',
                marginTop: '1.5rem',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '2rem',
                color: '#30C0AB',
              }}
            >
              {groupCode}
            </Box>
            <Box
              style={{
                marginTop: '1.5rem',
                display: 'flex',
                gap: '0.5rem',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  color: 'black',
                  fontSize: '0.875rem',
                  border: '1.5px solid #ff9900',
                  borderRadius: '1.925rem',
                  marginTop: '0.5rem',
                  padding: '0.5rem 1.5rem',
                  backgroundColor: '#ff9900',
                  whiteSpace: 'nowrap',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  SetCodeResetVisibility(true);
                }}
              >
                변경
              </Button>
              {/* <Button
                style={{
                  backgroundColor: '#30C0AB',
                  borderRadius: '1.5rem',
                  color: 'white',
                  padding: 0,
                }}
              >
                복사
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          display: 'flex',
          gap: '2rem',
        }}
      >
        <Box style={{flex: 1}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                margin: '1rem 0rem 0rem 1rem',
              }}
            >
              소속된 선생님 (키위티)
            </Box>

            <Box
              style={{
                fontSize: '1rem',
                margin: '1rem 0rem 0rem 1rem',
                textAlign: 'center',
              }}
            >
              <span
                style={{
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  marginRight: '1rem',
                  color: teacherColor,
                }}
              >
                {teacherCount !== undefined && passTecherCount !== undefined
                  ? `${teacherCount} / ${passTecherCount}` // 정상 값 표시
                  : '-'}
              </span>
              명
            </Box>
            <Box
              style={{
                display: 'flex',
                color: '#666972',
                fontSize: '0.875rem',
                justifyContent: 'center',
              }}
            >
              이용권의 최대 선생님수
            </Box>
            <Box style={{display: 'flex', justifyContent: 'end'}}>
              <img
                src={ImageAdmin1}
                alt="ImageAdmin1"
                style={{height: '7rem'}}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{flex: 1}}>
          <Box
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 5px 5px 0px #cdcdcd',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                color: '#666972',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                margin: '1rem 0rem 0rem 1rem',
              }}
            >
              소속된 학생 (키위런)
            </Box>
            <Box
              style={{
                fontSize: '1rem',
                margin: '1rem 0rem 0rem 1rem',
                textAlign: 'center',
              }}
            >
              <span
                style={{
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  marginRight: '1rem',
                  color: studentColor,
                }}
              >
                {studentCount} / {passStudentCount}
              </span>
              명
            </Box>
            <Box
              style={{
                display: 'flex',
                color: '#666972',
                fontSize: '0.875rem',
                justifyContent: 'center',
              }}
            >
              이용권의 계정수 합계
            </Box>
            <Box style={{display: 'flex', justifyContent: 'start'}}>
              <img
                src={ImageAdmin2}
                alt="ImageAdmin2"
                style={{height: '7rem'}}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          margin: '3rem 0rem 1rem 0rem',
          backgroundColor: 'white',
          boxShadow: '0px 5px 5px 0px #cdcdcd',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          paddingBottom: '2rem',
        }}
      >
        <Box
          style={{
            color: '#666972',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            margin: '2rem 2rem 0rem 2rem',
          }}
        >
          이용권 보유 내역
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingBottom: '0.7rem',
            textAlign: 'center',
            fontWeight: 'bold',
            width: '80%',
          }}
        >
          <Box style={{flex: 1}}>결제일</Box>
          <Box style={{flex: 2}}>사용 기간</Box>
          <Box style={{flex: 1}}>키위런 계정</Box>
          <Box style={{flex: 1}}>키위티 계정</Box>
          <Box style={{flex: 1}}>상태</Box>
        </Box>
        {groupTicket
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return <TicketView key={'ticket' + index} ticket_info={value} />;
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};

export default FragmentView;
