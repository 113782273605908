import React from 'react';
import {Box, Button} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import PDFTooltip from 'view/common/PDFTooltip';
import smile from 'asset/image/icon_smile.png';
import PropTypes from 'prop-types';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{textAlign: 'right'}}>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{color: '#717171', fontSize: '1.5rem'}}
          >
            ✖
          </Button>
        </Box>
        <Box>{<img src={smile} alt={'smile1'} style={{height: '30px'}} />}</Box>
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          저장을 완료했습니다.
        </Box>
        <Box style={{fontSize: '1.1rem'}}>
          {
            "저장한 평가지는 [마이페이지 > 나의 평가]에서 확인할 수 있습니다. 평가지를 PDF로 소장·배포하시려면 '발행하기'를 이용해주세요."
          }
        </Box>
        <Box
          style={{fontSize: '1.1rem', color: '#717171', marginTop: '0.5rem'}}
        >
          완성된 평가지는 훌륭한 리포트/포트폴리오가 됩니다.
        </Box>
        <Box style={{marginTop: '0.5rem'}}>
          <PDFTooltip color={'#ff6928'} />
        </Box>
        <Box
          style={{
            borderTop: '2px dotted #717171',
            marginTop: '1.5rem',
          }}
        ></Box>
        <Button
          style={{
            marginTop: '1rem',
            backgroundColor: Palette.green8,
            width: '100%',
            color: 'white',
            fontSize: 'calc(1rem + 0.2vw)',
            fontWeight: 'bold',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            borderRadius: '10px',
          }}
          onClick={() => {
            props.completeEvent();
          }}
        >
          마이페이지로 이동
        </Button>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  completeEvent: PropTypes.func,
};
export default ItemView;
