import React, {useState} from 'react';
import {Box, Button} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {removeLogin, savePrevPage} from 'common/reducer/auth';
import logo from 'asset/image/logo_keewit.png';
import logoSoluny from 'asset/imageV2/logo_keewit_soluny.png';
import {headerBox2, headerBox3} from 'view/login/Styles';
import {useNavigate, useLocation} from 'react-router-dom';
import {saveText} from 'common/reducer/auth';
import {TrackGoogleAnalyticsEvent} from 'common/utils/GoogleAnlytics';
import iconArrowDown from 'asset/imageV2/icon_arrow_down.svg';

const HeaderView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const dispatch = useDispatch();
  const isLogin = authReducer.isLogin;
  const [showExtension, setShowExtension] = useState(false);
  const isSoluny =
    authReducer.group_name != null && authReducer.group_name.includes('솔루니');
  function onLogin() {
    if (location.pathname != '/login') {
      dispatch(savePrevPage({prevPage: location.pathname}));
    }
    if (location.state != null) {
      dispatch(
        saveText({
          title: location.state.title,
          text: location.state.text,
          student_age: location.state.student_age,
          student_name: location.state.student_name,
        }),
      );
    }

    navigate('/login');
  }

  if (isLogin) {
    return (
      <Box style={headerBox2}>
        <Box style={headerBox3}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <img
                src={isSoluny ? logoSoluny : logo}
                alt={'logoT'}
                style={{height: '60px'}}
                onClick={() => {
                  TrackGoogleAnalyticsEvent(
                    'User_Action',
                    'Click_Home',
                    'Header',
                  );
                  navigate('/', {replace: true});
                }}
              />
            </Box>
          </Box>
          <Box style={{display: 'flex'}}>
            <Button
              style={{marginLeft: '1rem', color: '#2f3941'}}
              onClick={() => {
                if (location.pathname == '/purchase') {
                  navigate(0);
                } else {
                  navigate('/purchase');
                }
              }}
            >
              이용권 구매
            </Button>
            <Button
              style={{
                color: '#2f3941',
              }}
              onClick={() => {
                window.open(
                  'https://keewi-t.notion.site/KEEwi-T-137c9fc669128071b5ddeb7e23237770',
                );
              }}
            >
              사용법
            </Button>
            <Button
              style={{
                color: '#2f3941',
              }}
              onClick={() => {
                window.open('https://blog.naver.com/twoblockai');
              }}
            >
              블로그
            </Button>
            <Button
              style={{
                marginLeft: '1rem',
                color: '#2f3941',
              }}
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'User_Action',
                  'Click_Notice',
                  'Header',
                );
                if (location.pathname == '/notice') {
                  navigate(0);
                } else {
                  navigate('/notice');
                }
              }}
            >
              공지사항
            </Button>
            <Box style={{position: 'relative'}}>
              <Button
                style={{
                  marginLeft: '1rem',
                  color: '#000000',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  setShowExtension(!showExtension);
                }}
              >
                {authReducer.export_name}
                <img
                  src={iconArrowDown}
                  alt="iconArrowDown"
                  style={{marginLeft: '1rem'}}
                />
              </Button>
              <Box
                style={{
                  display: showExtension ? 'flex' : 'none',
                  flexDirection: 'column',
                  alignItems: 'start',
                  position: 'absolute',
                  backgroundColor: 'white',
                  boxShadow: '4px 10px 15px 9px rgba(173, 173, 173, 0.10)',
                  border: '1px solid var(--01-gray-07, #E9EBED)',
                  borderRadius: '0.625rem',
                  whiteSpace: 'nowrap',
                  padding: '0.5rem 1rem',
                  zIndex: 2,
                }}
              >
                <Button
                  style={{color: '#49545C'}}
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      'User_Action',
                      'Click_Mypage',
                      'Header',
                    );
                    if (location.pathname == '/mypage') {
                      navigate(0);
                    } else {
                      navigate('/mypage');
                    }
                  }}
                >
                  마이페이지
                </Button>
                <Button
                  style={{
                    display: authReducer.is_group_admin ? '' : 'none',
                    color: '#49545C',
                  }}
                  onClick={() => {
                    navigate('/admin');
                  }}
                >
                  관리자페이지
                </Button>
                <Button
                  style={{color: '#49545C'}}
                  onClick={() => {
                    dispatch(removeLogin());
                    navigate('/');
                  }}
                >
                  로그아웃
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box style={headerBox2}>
        <Box style={headerBox3}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <img
                src={logo}
                alt={'logoT'}
                style={{height: '60px'}}
                onClick={() => {
                  TrackGoogleAnalyticsEvent(
                    'User_Action',
                    'Click_Home',
                    'Header',
                  );
                  navigate('/', {replace: true});
                }}
              />
            </Box>
          </Box>
          <Box>
            <Button
              style={{marginLeft: '1rem', color: '#2f3941'}}
              onClick={() => {
                if (location.pathname == '/purchase') {
                  navigate(0);
                } else {
                  navigate('/purchase');
                }
              }}
            >
              이용권 구매
            </Button>
            <Button
              style={{
                color: '#2f3941',
              }}
              onClick={() => {
                window.open(
                  'https://keewi-t.notion.site/KEEwi-T-137c9fc669128071b5ddeb7e23237770',
                );
              }}
            >
              사용법
            </Button>
            <Button
              style={{
                color: '#2f3941',
              }}
              onClick={() => {
                window.open('https://blog.naver.com/twoblockai');
              }}
            >
              블로그
            </Button>
            <Button
              style={{
                marginLeft: '1rem',
                color: '#2f3941',
              }}
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'User_Action',
                  'Click_Notice',
                  'Header',
                );
                if (location.pathname == '/notice') {
                  navigate(0);
                } else {
                  navigate('/notice');
                }
              }}
            >
              공지사항
            </Button>
            <Button
              style={{
                marginLeft: '2rem',
                backgroundColor: '#35d2bb',
                color: 'white',
                borderRadius: '6.25rem',
                padding: '0.875rem 1.15rem',
              }}
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'User_Action',
                  'Click_Login',
                  'Header',
                );
                onLogin();
              }}
            >
              들어가기
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
};
export default HeaderView;
