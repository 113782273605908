import React, {useEffect, useState} from 'react';
import {Box, Select, MenuItem} from '@mui/material';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import APIUtils from 'common/utils/APIUtils';
import {NoticeItem} from 'view/notice/NoticeItem';
import Pagination from 'view/mypage/Pagination';
import faqList from 'asset/json/faq_text.json';

const NoticePage = () => {
  const [filteredFaqList, setFilteredFaqList] = useState([]);
  const categoryList = [
    '전체',
    '이용 방법',
    '서비스 문의',
    '평가 문의',
    '요금 문의',
    '기타 문의',
  ];
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const countPerPage = 15;
  function clickEvent(page) {
    setCurrentPage(page);
  }
  function handleSelectCategory(event) {
    setSelectedCategory(event.target.value);
    setCurrentPage(1);
  }

  useEffect(() => {
    let filteringList = [...faqList];

    if (selectedCategory != 0) {
      filteringList = filteringList.filter(
        item => item.category == categoryList[selectedCategory],
      );
    }
    setFilteredFaqList(filteringList);
    setTotalPage(Math.floor((filteringList.length - 1) / countPerPage + 1));
  }, [selectedCategory]);
  return (
    <Box
      style={{
        backgroundColor: 'white',
        minHeight: '100%',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderView />
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          style={{
            maxWidth: '70rem',
            textAlign: 'start',
            width: '80%',
            fontSize: 'calc(2rem + 0.5vw)',
            fontWeight: 'bold',
            color: '#717171',
            marginTop: '5rem',
            marginBottom: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          FAQ
          <Select
            disableUnderline
            onChange={handleSelectCategory}
            value={selectedCategory}
            variant="standard"
            // renderValue={() => 'class 배정'}
            // displayEmpty
            style={{
              border: '1px solid #E9EBED',
              height: '2.5rem',
              borderRadius: '0.9rem',
              color: 'black',
              fontSize: '0.875rem',
              whiteSpace: 'nowrap',
              padding: '0rem 1rem',
            }}
          >
            {categoryList.map((value, index) => (
              <MenuItem key={index} value={index}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          style={{
            backgroundColor: '#f3f3f3',
            height: '3px',
            width: '70%',
            marginTop: '3rem',
            marginBottom: '3rem',
          }}
        />
        <Box
          style={{
            maxWidth: '70rem',
            width: '80%',
            minHeight: '50rem',
            marginBottom: '5rem',
          }}
        >
          {filteredFaqList
            .filter((value, index) => {
              if (Math.floor(index / countPerPage) + 1 == currentPage) {
                return true;
              }
              return false;
            })
            .map((value, index) => {
              return (
                <NoticeItem
                  key={'faqItem' + index}
                  title={'[' + value.category + '] ' + value.question}
                  content={value.answer}
                  // time={value.notice_time}
                />
              );
            })}
        </Box>
        <Box
          style={{
            marginTop: '2rem',
            fontWeight: 'bold',
            fontSize: 'calc(0.8rem + 0.5vw)',
          }}
        >
          <Pagination
            totalPage={totalPage}
            currentPage={currentPage}
            clickEvent={clickEvent}
          />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default NoticePage;
