import React from 'react';
import {Box, Button} from '@mui/material';
import logoKakao from 'asset/imageV2/katalk.png';
import {useSelector} from 'react-redux';

const OverlayView = () => {
  const authReducer = useSelector(state => state.authReducer);
  return (
    <Box
      style={{
        paddingBottom: '2rem',
        position: 'fixed',
        bottom: '30%',
        right: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'end',
        zIndex: 3,
      }}
    >
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FBDD4C',
          color: 'black',
          marginTop: '1rem',
          fontSize: '0.6rem',
          fontWeight: 'bold',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
        }}
        onClick={() => {
          window.open('http://pf.kakao.com/_XxbmzG', '_blank');
        }}
      >
        <img src={logoKakao} alt="logoKakao" style={{width: '2rem'}} />
        <Box>{'카톡 상담'}</Box>
      </Button>
    </Box>
  );
};
export default OverlayView;
