import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TaskEvalView = props => {
  function textCopy(taskDB) {
    const text = taskDB.eval_result.originalText || ''; // currentText가 정의되지 않으면 빈 문자열 사용
    const title = taskDB.eval_result.title || '';
    const taskName = taskDB.task_name || '';
    const submitDate = new Date(taskDB.submit_time);
    const formattedDate =
      submitDate.getFullYear() +
      '년 ' +
      String(submitDate.getMonth() + 1).padStart(2, '0') +
      '월 ' +
      String(submitDate.getDate()).padStart(2, '0') +
      '일';
    const copyText =
      '과제 : ' +
      taskName +
      '\n날짜 : ' +
      formattedDate +
      '\n제목 : ' +
      title +
      '\n본문 : \n' +
      text +
      '\n';
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          alert('글이 복사되었습니다.'); // props 대신 alert로 결과 알림
        })
        .catch(() => {
          alert('복사를 다시 시도해주세요.');
        });
    } else {
      // 대체 흐름: execCommand 사용
      if (!document.queryCommandSupported('copy')) {
        alert('복사하기가 지원되지 않는 브라우저입니다.');
        return;
      }
      const textarea = document.createElement('textarea');
      textarea.value = copyText;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        alert('글이 복사되었습니다.');
      } catch (err) {
        alert('복사를 다시 시도해주세요.');
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
        margin: '0.5rem 0',
        border: '1px solid #B1B1B1',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        flex: '1 1 calc(30% - 1rem)', // 부모의 가로폭에 따라 카드 크기 조정
        maxWidth: 'calc(30% - 1rem)', // 카드가 한 줄에 3개씩 표시
        minWidth: '15rem', // 최소 크기 제한
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        {/* 날짜 및 제목 */}
        <Box
          style={{
            color: '#898989',
            fontSize: '0.8rem',
            fontWeight: 'bold',
          }}
        >
          {StringUtils.getDateTimeKr(props.submitTask.submit_time)}
        </Box>
        <Box
          style={{
            color: '#262C31',
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          {props.submitTask.title}
        </Box>

        {/* 평가 결과 */}
        <Box
          style={{
            fontSize: '0.9rem',
            color: '#555555',
          }}
        >
          <div>{props.submitTask.task_name}</div>
          <div>
            {props.submitTask.score + '점 | '}
            {props.submitTask.eval_try - 1 + '번 수정 | '}
            {props.submitTask.eval_result.전체글자수 + '자'}
          </div>
        </Box>
      </Box>
      {/* 오른쪽 버튼 */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Button
          style={{
            color: '#4FD7C3',
            fontSize: '0.875rem',
            border: '1px solid #4FD7C3',
            borderRadius: '1.925rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          }}
          onClick={() => {
            props.resultEvent(
              props.submitTask.student_id,
              props.submitTask.task_id,
            );
          }}
        >
          보기
        </Button>
        <Button
          style={{
            color: '#898989',
            fontSize: '0.875rem',
            border: '1px solid #898989',
            borderRadius: '1.925rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          }}
          onClick={() => {
            textCopy(props.submitTask);
          }}
        >
          복사
        </Button>
      </Box>
    </Box>
  );
};

TaskEvalView.propTypes = {
  index: PropTypes.number,
  submitTask: PropTypes.object,
  resultEvent: PropTypes.func,
};

export default TaskEvalView;
