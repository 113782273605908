import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';

export const SubmitView = props => {
  return (
    <Box style={{width: '95%', marginLeft: '2rem', maxWidth: '120rem'}}>
      <Box
        style={{
          display: 'flex',
          background: 'white',
          fontSize: '0.875rem',
          alignItems: 'center',
          padding: '0.5rem 0',
          width: '100%',
          borderBottom: '1px solid #ddd',
        }}
      >
        <Box style={{flex: 1, textAlign: 'center'}}>{props.index}</Box>
        <Box style={{flex: 2, textAlign: 'left', fontWeight: '500'}}>
          {props.submit_info.teacher_name}
        </Box>
        <Box style={{flex: 2, textAlign: 'left', fontWeight: '500'}}>
          {props.submit_info.student_name}
        </Box>
        <Box style={{flex: 4, textAlign: 'left'}}>
          {props.submit_info.task_name}
        </Box>
        <Button
          style={{
            flex: 4,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textAlign: 'left',
          }}
          onClick={() => {
            props.clickEvent(
              props.submit_info.student_id,
              props.submit_info.task_id,
            );
          }}
        >
          {props.submit_info.title}
        </Button>
        <Box style={{flex: 2, textAlign: 'left'}}>
          {props.submit_info.submit_time.slice(2, 10)}
        </Box>
        <Box style={{flex: 1, textAlign: 'left'}}>
          {props.submit_info.score}
        </Box>
        <Box style={{flex: 1, textAlign: 'left'}}>
          {props.submit_info.length}
        </Box>
      </Box>
    </Box>
  );
};

SubmitView.propTypes = {
  index: PropTypes.number,
  submit_info: PropTypes.object.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  clickEvent: PropTypes.func,
};

export default SubmitView;
