import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {NumberUtils} from 'common/utils/NumberUtils';

const ItemView = props => {
  return (
    <Box
      style={{
        borderRadius: '10px',
        width: '30%',
        backgroundColor: props.clicked ? 'rgba(53, 210, 187, 0.1)' : 'white',
        marginTop: '1rem',
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.25)',
        border: '2px solid #D8DCDE',
      }}
      onClick={() => {
        props.clickEvent(props.index);
      }}
    >
      <Box
        style={{
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
        }}
      >
        <Box
          style={{
            color: '#2F3941',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginTop: '0.5rem',
          }}
        >
          {'키위런 학생 계정 ' + props.studentCount}명
        </Box>

        <Box
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            marginTop: '0.5rem',
            color: '#4DC8B6',
          }}
        >
          {NumberUtils.getThousandComma(props.evalCost)}
          <span style={{color: '#49545C', fontSize: '1rem'}}>원</span>

          <span style={{color: '#49545C', fontSize: '0.5rem'}}>
            {' '}
            (
            {NumberUtils.getThousandCommaWon(
              props.evalCost / (props.studentCount * props.evalDuration),
            )}
            원)
          </span>
        </Box>
        <Box
          style={{
            color: '#B1B1B1',
            fontSize: '0.85rem',
            fontWeight: 'bold',
          }}
        >
          {'키위티 선생님 계정 ' + props.teacherCount}명
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  index: PropTypes.number,
  clicked: PropTypes.bool,
  clickEvent: PropTypes.func,
  studentCount: PropTypes.number,
  teacherCount: PropTypes.number,
  classCount: PropTypes.number,
  evalCost: PropTypes.number,
  evalDuration: PropTypes.number,
};
export default ItemView;
