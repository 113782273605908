import React, {useState} from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import APIUtils from 'common/utils/APIUtils';
import IconRefresh from 'asset/imageV2/rotatecw.svg';

export const BatchAccountCreation = props => {
  const [nameInput, setNameInput] = useState('');
  const [idPrefix, setIdPrefix] = useState('');
  const [password, setPassword] = useState('');
  const [creationStage, setCreationStage] = useState(0);
  const [studentList, setStudentList] = useState([]);
  const [failedIDs, setFailedIDs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [passMaxStudentCount, setPassMaxStudentCount] = useState(0);
  const [passCurrentStudentCount, setPassCurrentStudentCount] = useState(0);
  const [passSpareStudentCount, setPassSpareCurrentStudentCount] = useState(0);
  const [dupIDs, setDupIDS] = useState([]);
  const [isChecking, setIsChecking] = useState(false);
  const [createFailedIDs, setCreateFailedIDs] = useState([]);
  const [connectFailedIDs, setConnectFailIDs] = useState([]);

  const handleID = event => {
    const inputValue = event.target.value.replace(' ', '');
    const filteredValue = inputValue
      .replace(/[^a-zA-Z0-9._-]/g, '')
      .substring(0, 15); // 영문자와 숫자만 남기기
    setIdPrefix(filteredValue);
  };

  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheckShort(props.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setPassMaxStudentCount(response.data.max_student_count);
          setPassCurrentStudentCount(response.data.student_count);
          setPassSpareCurrentStudentCount(
            response.data.max_student_count - response.data.student_count,
          );
          return response.data;
        }
      } catch (err) {
        console.log(err);
      }
    };
    return passResult();
  }

  const resetForm = () => {
    setNameInput('');
    setIdPrefix('');
    setPassword('');
    setStudentList([]);
    setDupIDS([]);
    setCreateFailedIDs([]);
    setConnectFailIDs([]);
    setFailedIDs([]);
    setCreationStage(0);
  };

  const handlePreview = () => {
    if (!nameInput || !idPrefix) {
      alert('사용자 이름과 ID 앞부분을 입력해주세요.');
      return;
    }

    const names = nameInput
      .replace(/\r\n|\r|\n/g, ',')
      .replace(/\s+/g, ' ')
      .split(/,\s*/)
      .map(name => name.trim())
      .filter(name => name.length > 0)
      .slice(0, passSpareStudentCount);

    const students = names.map((name, index) => {
      const generatedId = `${idPrefix}${(index + 1)
        .toString()
        .padStart(2, '0')}`;
      return {
        name,
        id: generatedId,
        password: generatedId, // ID와 동일한 비밀번호 설정
      };
    });
    setStudentList(students);
    setCreationStage(1);
  };

  const handleCheckingAccounts = async () => {
    if (studentList.length === 0) {
      alert('먼저 미리보기를 실행해주세요.');
      return;
    }
    setIsChecking(true);
    let failed = [];
    const ids = studentList.map(student => student.id);

    try {
      const response = await APIUtils.checkBatchKEEwiLearnAccountApi(ids);
      if (response.data.ret_code == 1000) {
        setDupIDS(response.data.fail_ids);
        failed = response.data.fail_ids;
        if (failed.length === 0) {
          setCreationStage(2);
        }
      }
    } catch (error) {
      setDupIDS(ids);
    }
    setIsChecking(false);
    alert(
      failed.length === 0
        ? '모든 계정이 성공적으로 생성될 수 있습니다!'
        : `이미 있는 ID: ${failed.join(', ')}`,
    );
  };

  const handleCreateAccounts = async () => {
    if (studentList.length === 0) {
      alert('먼저 미리보기를 실행해주세요.');
      return;
    }
    if (dupIDs.length > 0) {
      alert('중복된 ID를 처리해주세요.');
      return;
    }

    setIsLoading(true);
    let failed = [];
    const names = studentList.map(student => student.name);
    const ids = studentList.map(student => student.id);
    const passwords = studentList.map(student => student.password);

    try {
      const response = await APIUtils.makeBatchKEEwiLearnAccountApi(
        props.user_id,
        props.group_name,
        ids,
        passwords,
        names,
      );
      if (!response.success) {
        setCreateFailedIDs(response.data.create_fail_ids);
        setConnectFailIDs(response.data.connect_fail_ids);
        failed = [
          ...response.data.create_fail_ids,
          ...response.data.connect_fail_ids,
        ];
      } else {
        failed = ids;
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    setCreationStage(0);
    alert(
      failed.length === 0
        ? '모든 계정이 성공적으로 생성되고 선생님과 연결되었습니다!'
        : `생성에 실패한 ID: ${failed.join(', ')}`,
    );
  };

  getPassInfo();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Box
        style={{
          marginLeft: '0rem',
          fontSize: '1.125rem', // 원래 폰트 크기로 복구
          fontWeight: 'bold',
          marginRight: '2rem',
          marginTop: '2rem',
          width: '10rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
          }}
        >
          계정 일괄 생성
          <IconButton
            style={{
              fontSize: '0.875rem',
              fontWeight: 'bold',
            }}
            onClick={() => {
              getPassInfo();
            }}
          >
            <img
              src={IconRefresh}
              style={{
                marginLeft: '0.5rem',
                width: '1rem',
                height: '1rem',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            marginTop: '0.3rem',
            marginLeft: '1.5rem',
            color: '#279EFF',
            fontSize: '0.875rem',
          }}
        >
          ※ 보유 이용권 수 : {passMaxStudentCount}개
          {passMaxStudentCount < 1000 && (
            <>
              {' '}
              | 잔여 이용권 수 : {passMaxStudentCount - passCurrentStudentCount}
              개
            </>
          )}
        </Box>

        <Box sx={{width: '100%', color: 'black', padding: '1.5rem'}}>
          <Box
            style={{
              display: 'flex',
              marginBottom: '1rem',
            }}
          >
            <Box
              style={{
                fontSize: '0.875rem',
                marginRight: '2rem',
                width: '10rem',
              }}
            >
              사용자 이름
            </Box>
            <Box>
              <TextField
                label={
                  nameInput == ''
                    ? '학생들 이름 입력 (공백 가능, 엔터 또는 쉼표 ,로 구분)'
                    : ''
                }
                multiline
                minRows={4}
                value={nameInput}
                onChange={e => setNameInput(e.target.value)}
                sx={{marginBottom: '0.3rem', width: '30rem', fontSize: '1rem'}}
                InputProps={{
                  style: {
                    backgroundColor: '#F7F8FA',
                    border: '1px solid #E9EBED',
                    fontSize: '1rem', // 입력 필드 글씨 크기 유지
                    borderRadius: '0.5rem', // 테두리 둥글기 조정
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: '0.875rem', // 라벨 글씨 크기 유지
                  },
                  shrink: false,
                }}
              />
              <Box
                style={{
                  display: passSpareStudentCount == 0 ? 'None' : 'block',
                  marginLeft: '0rem',
                  color: '#279EFF',
                  fontSize: '0.7rem',
                }}
              >
                {' '}
                ※ 최대 {passSpareStudentCount}명까지 생성 가능
              </Box>
            </Box>
          </Box>

          <Box sx={{display: 'flex', alignItems: 'center', gap: '0rem'}}>
            <Box
              style={{
                display: 'flex',
                marginBottom: '1rem',
              }}
            >
              <Box
                style={{
                  marginLeft: '0rem',
                  fontSize: '0.875rem',
                  marginRight: '2rem',
                  width: '10rem',
                }}
              >
                ID 앞부분
              </Box>
              <Box>
                <TextField
                  label={idPrefix == '' ? '(예) 25_abc_' : ''}
                  value={idPrefix}
                  onChange={handleID}
                  sx={{
                    width: '10rem',
                    fontSize: '0.875rem',
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: '#F7F8FA',
                      border: '1px solid #E9EBED',
                      fontSize: '1rem',
                      borderRadius: '0.5rem',
                      height: '2.5rem', // 필드 높이
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      position: 'absolute',
                      top: '50%',
                      fontSize: '0.875rem',
                      marginLeft: '1rem',
                      transform: 'translateY(-50%)',
                    },
                    shrink: false,
                  }}
                />
                <Box
                  style={{
                    marginTop: '0.3rem',
                    marginLeft: '0rem',
                    color: '#279EFF',
                    fontSize: '0.7rem',
                  }}
                >
                  {' '}
                  ※ 알파벳, 숫자, 기호 (- _ .)를 사용할 수 있습니다.
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            marginLeft: '1.5rem',
            gap: '1rem',
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#4FD7C3',
              color: 'white ',
              fontSize: '0.875rem',
              border: '1px solid #4FD7C3',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={handlePreview}
          >
            미리보기
          </Button>
          <Button
            onClick={handleCheckingAccounts}
            disabled={isChecking || creationStage == 0}
            sx={{
              backgroundColor: 'white',
              color: isChecking ? '#FF9900' : '#279EFF',
              fontSize: '0.875rem',
              border: isChecking ? '1px solid #FF9900' : '1px solid #279EFF',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: isChecking ? '' : '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            {isChecking ? '중복 검사 중' : '중복 검사'}
          </Button>
          <Button
            onClick={handleCreateAccounts}
            disabled={isLoading || creationStage !== 2}
            sx={{
              backgroundColor: 'white',
              color: isLoading ? '#FF9900' : '#4FD7C3',
              fontSize: '0.875rem',
              border: isLoading ? '1px solid #FF9900' : '1px solid #4FD7C3',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: isLoading ? '' : '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            {isLoading ? '계정 생성 중' : '계정 생성'}
          </Button>
          <Button
            onClick={resetForm}
            sx={{
              backgroundColor: 'white',
              color: '#B1B1B1',
              fontSize: '0.875rem',
              border: '1px solid #B1B1B1',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            초기화
          </Button>
        </Box>
      </Box>
      {/* 미리보기 테이블 */}
      {studentList.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>이름</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>비밀번호</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList.map((student, index) => {
                const isDuplicate = dupIDs.includes(student.id); // 중복 여부 확인
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <TextField
                        value={student.name}
                        onChange={e => {
                          let updatedList = [...studentList];
                          updatedList[index].name = e.target.value;
                          setStudentList(updatedList);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: isDuplicate ? '#FFDDDD' : 'inherit',
                      }}
                    >
                      <TextField
                        value={student.id}
                        onChange={e => {
                          let updatedList = [...studentList];
                          updatedList[index].id = e.target.value;
                          setStudentList(updatedList);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={student.password}
                        onChange={e => {
                          let updatedList = [...studentList];
                          updatedList[index].password = e.target.value;
                          setStudentList(updatedList);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* 실패한 ID 목록 표시 */}
      {failedIDs.length > 0 && (
        <>
          <Box sx={{color: 'red', marginTop: '1rem'}}>
            <strong>생성 실패한 ID:</strong> {createFailedIDs.join(', ')}
          </Box>
          <Box sx={{color: 'red', marginTop: '1rem'}}>
            <strong>연결 실패한 ID:</strong> {connectFailedIDs.join(', ')}
          </Box>
        </>
      )}
    </Box>
  );
};

BatchAccountCreation.propTypes = {
  passStatus: PropTypes.string,
  group_name: PropTypes.string,
  user_id: PropTypes.number,
};

export default BatchAccountCreation;
