import React, {useState, useEffect} from 'react';
import {Box, Button, IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import {StringUtils} from 'common/utils/StringUtils';

const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box>
          <Box style={{display: 'flex', justifyContent: 'space-between'}}>
            <Box
              style={{
                padding: '1rem 0rem',
                fontSize: '1.375rem',
                fontWeight: 'bold',
                flex: 1,
                borderBottom: '1px solid #7d7d7d',
              }}
            >
              과제 제출자
            </Box>
            <IconButton onClick={props.cancelEvent}>
              <img src={IconCancel} />
            </IconButton>
          </Box>
          <Box
            style={{
              display: 'flex',
              fontWeight: 'bold',
              marginTop: '1rem',
              marginBottom: '2rem',
              fontSize: '1.125rem',
            }}
          >
            <Box>과제명 :</Box>

            <Box
              style={{
                marginLeft: '1rem',
                backgroundColor: '#EBFBF9',
              }}
            >
              {props.titleText}
            </Box>
          </Box>
          <Box style={{color: '#68737D', fontSize: '0.875rem'}}>
            {StringUtils.getDateTimeKrFromDate(props.startTime) +
              ' ~ ' +
              StringUtils.getDateTimeNoYearKrFromDate(props.endTime)}
          </Box>

          <Box
            style={{
              textAlign: 'end',
              marginTop: '1rem',
              fontSize: '0.875rem',
              color: '#4b4d4c',
            }}
          >
            {'총 '}
            <span style={{color: '#ff9900', fontWeight: '500'}}>
              {props.studentList.length + '명'}
            </span>
            이 제출했습니다.
          </Box>

          <Box
            style={{
              border: '2px solid #35D2BB',
              marginTop: '0.5rem',
              borderRadius: '0.43rem',
              padding: '1rem',
              maxHeight: '20rem',
              overflowY: 'auto',
            }}
          >
            <Box
              style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}
            >
              {props.studentList.map((val, index) => {
                return (
                  <Box
                    key={'student' + index}
                    style={{
                      display: 'flex',
                      fontSize: '0.875rem',
                      fontWeight: '500',
                      alignItems: 'center',
                    }}
                  >
                    <Box>{val}</Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box style={{marginTop: '2rem', display: 'flex'}}>
            <Button
              style={{
                flex: '3',
                whiteSpace: 'nowrap',
                color: 'white',
                backgroundColor: '#35D2BB',
                fontSize: '1.125rem',
                fontWeight: 'bold',
                padding: '0.9rem 0rem',
                marginLeft: '1rem',
                borderRadius: '6.25rem',
              }}
              onClick={() => props.cancelEvent()}
            >
              닫기
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  studentList: PropTypes.array,
  titleText: PropTypes.string,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  cancelEvent: PropTypes.func,
};
export default ItemView;
