import {
  Box,
  Button,
  Select,
  MenuItem,
  IconButton,
  Typography,
  TextField,
} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import PropTypes, {func} from 'prop-types';
import {StringUtils} from 'common/utils/StringUtils';
import BarGraph from 'view/common/BarGraph';
import BarChart from 'view/common/BarChart2';
import TaskEvalView from 'view/mypage/TaskEvalView2';
import {useNavigate} from 'react-router-dom';
import TaskUnsubmitPopup from 'view/popup/TaskUnsubmitPopup';
import TaskSubmitPopup from 'view/popup/TaskSubmitPopup';
import TaskPeerReviewUnsubmitPopup from 'view/popup/TaskPeerReviewUnsubmitPopup';
import TaskPeerReviewSubmitPopup from 'view/popup/TaskPeerReviewSubmitPopup';
import iconUnsubmit from 'asset/imageV2/icon_unsubmit.svg';
import TaskModifyPopup from 'view/popup/TaskModifyPopup';
import TaskEndPopup from 'view/popup/TaskEndPopup';
import Linkify from 'linkify-react';
import ApiLoading from 'view/common/ApiLoading2';
import ClipboardCopy from 'view/common/ClipboardCopy';
import ToastPopup from 'view/popup/ToastPopup';
import PeerEvalComponent from 'page/peer/EvalItems2';
import IconRefresh from 'asset/imageV2/rotatecw.svg';
import iconDataWhite from 'asset/imageV2/icon_data_white.svg';
import {DownloadExcelFile} from 'common/utils/FileUtils';
import iconArrowDown from 'asset/image/icon_arrow_down.png';

const ExampleCustomInput = React.forwardRef((props, ref) => (
  <button
    style={{
      backgroundColor: '#F7F8FA',
      border: '0px solid black',
      borderRadius: '0.625rem',
      padding: '0.5rem 1rem',
    }}
    className="custom-input"
    onClick={props.onClick}
    ref={ref}
  >
    {props.value}
  </button>
));
ExampleCustomInput.displayName = 'CalandarInput';
ExampleCustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};
export const FragmentView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [loading, setLoading] = useState(true);
  const [memoText, setMemoText] = useState('');
  const [taskName, setTaskName] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [taskClass, setTaskClass] = useState([]);
  const [originalSubmitList, setOriginalSubmitList] = useState([]);
  const [submitList, setSubmitList] = useState([]);
  const [submitRate, setSubmitRate] = useState(0);
  const [submitStudentCount, setsubmitStudentCount] = useState(0);
  const [taksStudentCount, settaksStudentCount] = useState(1);

  const [textCountChecked, setTextCountChecked] = useState(false);
  const [textCountMin, setTextCountMin] = useState('');
  const [textCountMax, setTextCountMax] = useState('');

  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);
  const [peerReviewStatus, setPeerReviewStatus] = useState(0);
  const [peerReviewChecked, setPeerReviewChecked] = useState(false);
  const [peerCommentChecked, setPeerCommentChecked] = useState(false);
  const [peerEvalChecked, setPeerEvalChecked] = useState(false);
  const [peerEvalTeacherMsg, setPeerEvalTeacherMsg] = useState('');
  const [peerEvalItems, setPeerEvalItems] = useState([]);
  const [peerEvalMaxScore, setPeerEvalMaxScore] = useState(3);
  const [peerEvalTimeReserved, setPeerEvalTimeReserved] = useState(false);
  const [peerEvalStartTime, setPeerEvalStartTime] = useState(new Date());
  const [peerEvalEndTime, setPeerEvalEndTime] = useState(new Date());
  const [evalSubmitRate, setEvalSubmitRate] = useState(0);
  const [evalSubmitStudentCount, setEvalSubmitStudentCount] = useState(0);
  const [evalSubmitStudentList, setEvalSubmitStudentList] = useState([]);
  const [peerEvalList, setPeerEvalList] = useState([]);
  const [maxTryCount, setMaxTryCount] = useState(0);
  const [infoPart, setInfoPart] = useState('open');
  const [tz, setTZ] = useState('Asia/Seoul');

  const defaultTeacherComment =
    '동료 평가를 합니다.\n객관적인 평가를 해보세요.';

  function reportResult(text) {
    setToastText(text);
    setToastVisibility(true);

    setTimeout(() => {
      setToastVisibility(false);
    }, 2500);
  }
  function handleInfoShow() {
    if (infoPart == 'open') setInfoPart('close');
    else setInfoPart('open');
  }

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const [requiredWord, setRequiredWord] = useState('');

  const [classList, setClassList] = useState(['전체']);
  const [selectClass, setSelectClass] = useState(0);
  function handleSelectClass(event) {
    setSelectClass(event.target.value);
    setCurrentPage(1);
  }
  const sortingList = ['제출 순', '이름 순', '제목 순', '점수 순'];
  const [sortingDesc, setSortingDesc] = useState(false);
  const [selectSorting, setSelectSorting] = useState(0);
  function handleSelectSorting(event) {
    if (event.target.dataset.value == undefined) {
      return;
    }
    let newValue = Number(event.target.dataset.value);
    if (selectSorting == newValue) {
      setSortingDesc(prev => !prev);
    } else {
      setSortingDesc(true);
    }
    setSelectSorting(newValue);
    setCurrentPage(1);
  }

  const [taskSubmitVisibility, setTaskSubmitVisibility] = useState(false);
  function taskSubmitCancel() {
    setTaskSubmitVisibility(false);
  }

  const [taskUnsubmitVisibility, setTaskUnsubmitVisibility] = useState(false);
  function taskUnsubmitCancel() {
    setTaskUnsubmitVisibility(false);
  }

  const [taskPeerReviewSubmitVisibility, setTaskPeerReviewSubmitVisibility] =
    useState(false);
  function taskPeerReviewSubmitCancel() {
    setTaskPeerReviewSubmitVisibility(false);
  }

  const [
    taskPeerReviewUnsubmitVisibility,
    setTaskPeerReviewUnsubmitVisibility,
  ] = useState(false);
  function taskPeerReviewUnsubmitCancel() {
    setTaskPeerReviewUnsubmitVisibility(false);
  }

  const [taskModifyVisibility, setTaskModifyVisibility] = useState(false);
  function taskModifyCancel() {
    setTaskModifyVisibility(false);
  }
  function taskModifyConfirm(
    task_name,
    task_message,
    task_janre,
    task_start_time,
    task_end_time,
    task_type,
    include_class_id_list,
    exclude_class_id_list,
    include_student_id_list,
    exclude_student_id_list,
    length_check,
    min_length,
    max_length,
    keyword_check,
    keyword,
    pre_keewichat,
    peerReviewChecked,
    peerCommentChecked,
    peerEvalChecked,
    peerEvalTeacherMsg,
    peerEvalItems,
    peerEvalMaxScore,
    peerEvalTimeReserved,
    peerEvalStartTime,
    peerEvalEndTime,
    maxTryCount,
  ) {
    let status = 0;
    if (peerReviewChecked && peerEvalStartTime < new Date()) status = 1; // 시작전
    if (
      peerReviewChecked &&
      peerEvalStartTime <= new Date() &&
      peerEvalEndTime > new Date()
    )
      status = 2; // 진행중
    if (peerReviewChecked && peerEvalEndTime < new Date()) status = 4;
    setPeerReviewStatus(status);
    const modifyTaskApi = async inputTaskID => {
      try {
        const response = await APIUtils.TaskModify(
          authReducer.user_id,
          inputTaskID,
          task_name,
          task_message,
          task_janre,
          StringUtils.getFormattedString(task_start_time),
          StringUtils.getFormattedString(task_end_time),
          task_type,
          include_class_id_list,
          exclude_class_id_list,
          include_student_id_list,
          exclude_student_id_list,
          length_check,
          min_length,
          max_length,
          keyword_check,
          keyword,
          pre_keewichat,
          peerReviewChecked == true ? 1 : 0,
          peerCommentChecked == true ? 1 : 0,
          peerEvalChecked == true ? 1 : 0,
          peerEvalTeacherMsg,
          peerEvalItems,
          peerEvalMaxScore,
          peerEvalTimeReserved == true ? 1 : 0,
          StringUtils.getFormattedString(peerEvalStartTime),
          StringUtils.getFormattedString(peerEvalEndTime),
          maxTryCount,
          status,
        );
        return response;
      } catch (err) {
        console.log(err);
        return null;
      }
    };
    modifyTaskApi(props.taskID).then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        reportResult('과제 내용 변경이 완료되었습니다.');
        getTaskResult();
      } else {
        reportResult('과제 내용 변경을 실패하였습니다.');
      }
      setTaskModifyVisibility(false);
    });
    if (!peerReviewChecked) setPeerReviewStatus(0);
    setPeerReviewChecked(peerReviewChecked);
    setPeerCommentChecked(peerCommentChecked);
    setPeerEvalChecked(peerEvalChecked);
    setPeerEvalTeacherMsg(peerEvalTeacherMsg);
    if (peerEvalChecked) setPeerEvalItems(peerEvalItems);
    setPeerEvalMaxScore(peerEvalMaxScore);
    setPeerEvalTimeReserved(peerEvalTimeReserved);
    setPeerEvalStartTime(peerEvalStartTime);
    setPeerEvalEndTime(peerEvalEndTime);
  }

  const renderTextWithYoutubeAndLinks = text => {
    const [answerText, setAnswerText] = useState('');
    const [answerShow, setAnswerShow] = useState(false);
    const handleAnswerShow = () => {
      setAnswerShow(prev => !prev);
    };

    const youtubeRegex =
      /(https?:\/\/(?:www\.)?(youtube\.com\/watch\?v=([\w-]+)|youtu\.be\/([\w-]+)|youtube\.com\/shorts\/([\w-]+)))/g;

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const videoIds = [];

    const cleanTaskMessage = message => {
      const lines = message.split('\n');

      const filename =
        lines
          .find(line => line.replace(/\s+/g, '').startsWith('###문제지:'))
          ?.replace(/###\s*문제지\s*:\s*/, '') || 'XXXX';

      let answerfn =
        lines
          .find(line => line.replace(/\s+/g, '').startsWith('###모범답안:'))
          ?.replace(/###\s*모범답안\s*:\s*/, '') || 'XXXX';

      if (answerfn !== 'XXXX') {
        const fetchAnswerText = async () => {
          try {
            const response = await fetch(
              `https://keewichat.s3.ap-northeast-2.amazonaws.com/essay/answer/${answerfn}`,
              {
                cache: 'no-store',
              },
            );

            // ✅ 파일 존재 여부 확인
            if (!response.ok) {
              throw new Error(`파일이 존재하지 않음: ${response.status}`);
            }

            const buffer = await response.arrayBuffer();
            const decoder = new TextDecoder('euc-kr');
            const text = decoder.decode(buffer).replace(/\r\n|\r/g, '\n');
            setAnswerText(text);
          } catch (err) {
            setAnswerText('');
            answerfn = 'XXXX';
          }
        };

        fetchAnswerText();
      }

      const problemSheetLink =
        filename !== 'XXXX' ? (
          <div style={{marginBottom: '1rem'}}>
            <a
              href={`https://keewichat.s3.ap-northeast-2.amazonaws.com/essay/exam/${filename}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: '1.2rem',
                textDecoration: 'none',
                fontWeight: 'bold',
                color: '#35D2BB',
              }}
            >
              📄 문제지 보기
            </a>
          </div>
        ) : null;
      const AnswerPart =
        answerfn !== 'XXXX' ? (
          <div
            style={{
              display: answerText ? 'block' : 'none',
              marginBottom: '1rem',
            }}
          >
            <Box style={{display: 'flex', alignItems: 'center'}}>
              <a
                href={`https://keewichat.s3.ap-northeast-2.amazonaws.com/essay/answer/${answerfn}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: '1.2rem',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  color: '#35D2BB',
                }}
              >
                📄 모범답안 보기
              </a>
              <Button
                style={{
                  marginLeft: '0.3rem',
                  width: '1.5rem',
                  color: '#717171',
                  fontWeight: 'bold',
                }}
                onClick={handleAnswerShow}
              >
                <img
                  src={iconArrowDown}
                  alt="toggle"
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    transform: answerShow ? 'rotate(0deg)' : 'rotate(180deg)',
                    transition: 'transform 0.2s',
                  }}
                />
              </Button>
            </Box>

            <Box style={{display: answerShow ? 'block' : 'none'}}>
              {answerText &&
              answerText === '모범답안을 불러오는 중 오류가 발생했습니다.' ? (
                <div style={{color: 'red', marginTop: '1rem'}}>
                  {answerText}
                </div>
              ) : (
                <pre
                  style={{
                    marginTop: '1rem',
                    whiteSpace: 'pre-wrap',
                    fontFamily: 'inherit',
                    fontSize: '0.875rem',
                    lineHeight: 1.5,
                    background: '#f6f6f6',
                    padding: '1rem',
                    borderRadius: '8px',
                  }}
                >
                  {answerText}
                </pre>
              )}
            </Box>
          </div>
        ) : null;

      const filteredMessage = lines.filter(
        line => !line.trim().startsWith('###'),
      );

      return {filteredMessage, problemSheetLink, AnswerPart};
    };

    const {filteredMessage, problemSheetLink, AnswerPart} =
      cleanTaskMessage(text);

    return (
      <div>
        {problemSheetLink}
        {filteredMessage.map((line, lineIndex) => {
          if (!line) return <br key={lineIndex} />;

          // 🔹 유튜브 링크 감지 및 변환
          if (youtubeRegex.test(line)) {
            const videoPart = line.match(youtubeRegex);
            if (!videoPart)
              return (
                <span key={lineIndex}>
                  {line}
                  <br />
                </span>
              );

            let videoId = '';
            const videoUrl = videoPart[0];

            try {
              if (videoUrl.includes('youtu.be')) {
                videoId = videoUrl.split('/').pop().split('?')[0];
              } else if (videoUrl.includes('youtube.com/watch?v=')) {
                videoId = new URL(videoUrl).searchParams.get('v');
              } else if (videoUrl.includes('youtube.com/shorts/')) {
                videoId = videoUrl.split('/').pop().split('?')[0];
              }

              if (!videoId || videoIds.includes(videoId))
                return (
                  <span key={lineIndex}>
                    {line}
                    <br />
                  </span>
                );
              videoIds.push(videoId);

              return (
                <div
                  key={lineIndex}
                  style={{margin: '1rem 0', textAlign: 'center'}}
                >
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title="YouTube video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      borderRadius: '8px',
                      display: 'inline-block',
                    }}
                  ></iframe>
                </div>
              );
            } catch (error) {
              console.error('Invalid YouTube URL:', videoUrl);
              return (
                <span key={lineIndex}>
                  {line}
                  <br />
                </span>
              );
            }
          }

          const parts = line.split(urlRegex);
          const linkedText = parts.map((part, index) => {
            if (urlRegex.test(part)) {
              return (
                <a
                  key={index}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#007AFF',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  {part}
                </a>
              );
            }
            return part;
          });

          return (
            <span key={lineIndex}>
              {linkedText}
              <br />
            </span>
          );
        })}
        {AnswerPart}
      </div>
    );
  };

  const TaskMessage = ({message}) => {
    return <div>{renderTextWithYoutubeAndLinks(message)}</div>;
  };

  TaskMessage.propTypes = {
    message: PropTypes.string.isRequired, // ✅ message prop 검증 추가
  };

  const [taskEndVisibility, setTaskEndVisibility] = useState(false);
  function taskEndCancel() {
    setTaskEndVisibility(false);
  }
  function taskEndConfirm() {
    const endTaskApi = async inputTaskID => {
      try {
        const response = await APIUtils.TaskEnd(
          authReducer.user_id,
          inputTaskID,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    endTaskApi(props.taskID).then(res => {
      setTaskEndVisibility(false);
      props.taskEndEvent();
    });
  }
  const [avgScore, setAvgScore] = useState(0);
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [],
  });
  const [labelData, setLabelData] = useState([]);

  const [evalAvgScore, setEvalAvgScore] = useState(0);
  const [bar2Data, setBar2Data] = useState({
    labels: [],
    datasets: [],
  });
  const [label2Data, setLabel2Data] = useState([]);

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
  };

  function clickResult(studentID, submitList) {
    navigate('/result2', {
      state: {
        task_id: props.taskID,
        student_id: studentID,
        submitList: submitList,
        from: 'running',
      },
    });
  }

  function modifyTaskPeerReviewStatus(status) {
    const now = StringUtils.getFormattedString(new Date());
    try {
      const response = APIUtils.TaskModifyPeerStatus(props.taskID, status, now);
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  function getTaskResult() {
    const getTaskResultApi = async () => {
      try {
        const response = await APIUtils.TaskResult(
          authReducer.user_id,
          props.taskID,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };

    getTaskResultApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setTaskName(res.data.task_name);
        setStartTime(new Date(res.data.task_start_time));
        setEndTime(new Date(res.data.task_end_time));
        setTaskClass(res.data.task_class);
        setTZ(res.data.tz);
        setClassList([
          '전체',
          ...res.data.task_class.map(item => item.class_name),
        ]);
        setMemoText(res.data.task_message);
        setTextCountChecked(res.data.length_check == 1);
        setTextCountMin(res.data.min_length);
        setTextCountMax(res.data.max_length);
        setRequiredWordChecked(res.data.keyword_check == 1);
        setRequiredWord(res.data.keyword);
        setMaxTryCount(res.data.maxTryCount);

        const submitedList = res.data.submit_list;
        setOriginalSubmitList(submitedList);
        const totalStudentCount =
          res.data.overall_submission_rate.submit +
          res.data.overall_submission_rate.non_submit;
        setSubmitRate(
          totalStudentCount == 0
            ? 0
            : (
                (res.data.overall_submission_rate.submit / totalStudentCount) *
                100
              ).toFixed(0),
        );
        setsubmitStudentCount(submitedList.length);
        settaksStudentCount(totalStudentCount);
        setAvgScore(
          submitedList.length == 0
            ? 0
            : (
                submitedList.reduce((prev, curr) => prev + curr.score, 0) /
                submitedList.length
              ).toFixed(1),
        );
        const barData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const labelData = [[], [], [], [], [], [], [], [], [], [], []];
        for (let i = 0; i < submitedList.length; i++) {
          const counterIdx = Math.floor(submitedList[i].score / 10);
          barData[counterIdx]++;
          labelData[counterIdx].push(submitedList[i].student_name);
        }
        const data = {
          labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          datasets: [
            {
              label: '',
              data: barData,
              // borderColor: '#FF9900',
              backgroundColor: ({chart: {ctx}}) => {
                const bg = ctx.createLinearGradient(0, 0, 0, 250);
                bg.addColorStop(0, 'rgba(191, 240, 233, 0.7)');
                bg.addColorStop(0.5, 'rgba(48, 211, 187, 1)');
                return bg;
              },
              // 'linear-gradient(180deg, #BFF0E9 0%, #30D3BB 100%)',
              borderWidth: 0,
              borderRadius: {
                topLeft: 30,
                topRight: 30,
                bottomLeft: 0,
                bottomRight: 0,
              },
              borderSkipped: false,
            },
          ],
        };
        setBarData(data);
        setLabelData(labelData);
        if ([1, 2].includes(res.data.peer_review_status)) {
          let status = res.data.peer_review_status;
          if (
            res.data.task_peer_review.peerEvalChecked === 1 &&
            res.data.task_peer_review.peerEvalTimeReserved === 1 &&
            new Date(res.data.task_peer_review.peerEvalStartTime) < new Date()
          )
            status = 1; // 시작전
          if (
            res.data.task_peer_review.peerEvalChecked === 1 &&
            res.data.task_peer_review.peerEvalTimeReserved === 1 &&
            new Date(res.data.task_peer_review.peerEvalStartTime) <
              new Date() &&
            new Date(res.data.task_peer_review.peerEvalEndTime) > new Date()
          )
            status = 2; // 진행중
          if (
            res.data.task_peer_review.peerEvalChecked === 1 &&
            res.data.task_peer_review.peerEvalTimeReserved === 1 &&
            new Date(res.data.task_peer_review.peerEvalEndTime) < new Date()
          )
            status = 4;
          setPeerReviewStatus(status);
          if (res.data.peer_review_status != status)
            modifyTaskPeerReviewStatus(status);
        } else {
          setPeerReviewStatus(res.data.peer_review_status);
        }

        setPeerReviewChecked(
          res.data.task_peer_review.peerReviewChecked === 0 ? false : true,
        );
        setPeerCommentChecked(
          res.data.task_peer_review.peerCommentChecked === 0 ? false : true,
        );
        setPeerEvalChecked(
          res.data.task_peer_review.peerEvalChecked === 0 ? false : true,
        );
        setPeerEvalTeacherMsg(res.data.task_peer_review.peerEvalTeacherMsg);
        if (res.data.task_peer_review.peerEvalChecked === 1)
          setPeerEvalItems(res.data.task_peer_review.peerEvalItems);
        setPeerEvalMaxScore(res.data.task_peer_review.peerEvalMaxScore);
        setPeerEvalTimeReserved(
          res.data.task_peer_review.peerEvalTimeReserved === 0 ? false : true,
        );
        setPeerEvalStartTime(
          new Date(res.data.task_peer_review.peerEvalStartTime),
        );
        setPeerEvalEndTime(new Date(res.data.task_peer_review.peerEvalEndTime));
        setPeerEvalList(res.data.peer_eval_list);
        setEvalSubmitStudentList(res.data.peer_eval_submit_list);
        const evalList = res.data.peer_eval_list;
        const evalSubmitList = res.data.peer_eval_submit_list;
        if (evalSubmitList.length > 0) {
          setEvalSubmitRate(
            totalStudentCount == 0
              ? 0
              : ((evalSubmitList.length / totalStudentCount) * 100).toFixed(0),
          );
          setEvalSubmitStudentCount(evalSubmitList.length);
          setEvalAvgScore(
            evalList.length == 0
              ? 0
              : (
                  evalList.reduce(
                    (prev, curr) => prev + Math.min(curr.score, 100),
                    0,
                  ) / evalList.length
                ).toFixed(1),
          );
        }
        const bar2Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const label2Data = [[], [], [], [], [], [], [], [], [], [], []];
        for (let i = 0; i < evalList.length; i++) {
          const counterIdx = Math.min(10, Math.floor(evalList[i].score / 10));
          bar2Data[counterIdx]++;
          label2Data[counterIdx].push(evalList[i].student_name);
        }
        const data2 = {
          labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          datasets: [
            {
              label: '',
              data: bar2Data,
              backgroundColor: ({chart: {ctx}}) => {
                const bg = ctx.createLinearGradient(0, 0, 0, 250);
                bg.addColorStop(0, 'rgba(158, 204, 255, 0.7)');
                bg.addColorStop(0.5, 'rgba(39, 158, 255, 1)');
                return bg;
              },
              borderWidth: 0,
              borderRadius: {
                topLeft: 30,
                topRight: 30,
                bottomLeft: 0,
                bottomRight: 0,
              },
              borderSkipped: false,
            },
          ],
        };
        setBar2Data(data2);
        setLabel2Data(label2Data);
      }
      setLoading(false);
    });
  }
  function navigateTaskFragment() {
    props.previousPageEvent();
  }
  function handleCopyResult(text) {
    setToastText(text);
    setToastVisibility(true);
    setTimeout(() => {
      setToastVisibility(false);
    }, 2500);
  }
  useEffect(() => {
    if (props.taskID != 0) {
      getTaskResult();
    }
    const backspace = e => {
      if (
        e.keyIdentifier == 'U+0008' ||
        e.keyIdentifier == 'Backspace' ||
        e.keyCode == 8
      ) {
        if (e.target == document.body) {
          e.preventDefault();
          navigateTaskFragment();
          return false;
        }
      }
    };
    window.addEventListener('keydown', backspace, true);
    const preventGoBack = () => {
      navigateTaskFragment();
      history.pushState(null, '', location.href);
    };
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);

    return () => {
      window.removeEventListener('keydown', backspace, true);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, [props.taskID]);
  useEffect(() => {
    let filteredList = [...originalSubmitList];
    if (searchText.trim() != '') {
      filteredList = filteredList.filter(
        item =>
          item.title.includes(searchText) ||
          item.student_name.includes(searchText),
      );
    }
    if (selectClass != 0) {
      filteredList = filteredList.filter(item =>
        item.class_name_list.includes(classList[selectClass]),
      );
    }
    if (selectSorting == 0) {
      filteredList = filteredList.sort((a, b) => {
        return (
          (new Date(a.submit_time) - new Date(b.submit_time)) *
          (sortingDesc ? 1 : -1)
        );
      });
    } else if (selectSorting == 1) {
      filteredList = filteredList.sort((a, b) => {
        if (a.student_name > b.student_name) {
          return 1 * (sortingDesc ? 1 : -1);
        }
        if (a.student_name < b.student_name) {
          return -1 * (sortingDesc ? 1 : -1);
        }
        return 0;
      });
    } else if (selectSorting == 2) {
      filteredList = filteredList.sort((a, b) => {
        if (a.title > b.title) {
          return 1 * (sortingDesc ? 1 : -1);
        }
        if (a.title < b.title) {
          return -1 * (sortingDesc ? 1 : -1);
        }
        return 0;
      });
    } else if (selectSorting == 3) {
      filteredList = filteredList.sort((a, b) => {
        if (a.score > b.score) {
          return -1 * (sortingDesc ? 1 : -1);
        }
        if (a.score < b.score) {
          return 1 * (sortingDesc ? 1 : -1);
        }
        return 0;
      });
    }

    setSubmitList(filteredList);
    setTotalPage(Math.floor((filteredList.length - 1) / countPerPage + 1));
  }, [originalSubmitList, selectClass, selectSorting, sortingDesc]);

  function createOverallComment() {
    return (
      <>
        <Box
          style={{
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          <Box
            style={{
              fontWeight: 'bold',
              color: '#4dc8b6',
              fontSize: '1.2rem',
              marginBottom: '1rem',
            }}
          >
            {'[동료 평가 내용]'}
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Box
              style={{
                display: peerEvalTimeReserved ? 'block' : 'none',
                color: '#68737D',
                fontWeight: 'bold',
                marginLeft: '1rem',
                marginTop: '1rem',
                marginBottom: '2rem',
                fontSize: '1rem',
              }}
            >
              기간 :{' '}
              {StringUtils.getDateTimeKrFromDate(peerEvalStartTime) +
                ' ~ ' +
                StringUtils.getDateTimeNoYearKrFromDate(peerEvalEndTime)}
              {peerReviewStatus == 1 && ' (대기) '}
              {peerReviewStatus == 2 && ' (진행중) '}
              {peerReviewStatus == 3 && ' (중단) '}
              {peerReviewStatus == 4 && ' (종료) '}
              {peerReviewStatus == 5 && ' (공유완료) '}
            </Box>
            <Box
              style={{
                display: !peerEvalTimeReserved ? 'block' : 'none',
                color: '#68737D',
                fontWeight: 'bold',
                marginTop: '1rem',
                marginBottom: '2rem',
                fontSize: '1rem',
              }}
            >
              기간 : 직접 설정
              {peerReviewStatus == 1 && ' (대기) '}
              {peerReviewStatus == 2 && ' (진행중) '}
              {peerReviewStatus == 3 && ' (중단) '}
              {peerReviewStatus == 4 && ' (종료) '}
              {peerReviewStatus == 5 && ' (공유완료) '}
            </Box>
            <Button
              style={{
                display: peerReviewStatus == 1 ? 'block' : 'none',
                color: '#EBFBF9',
                fontSize: '0.875rem',
                border: '1.5px solid #35D2BB',
                borderRadius: '1.925rem',
                marginLeft: '5rem',
                marginBottom: '0.5rem',
                width: '6rem',
                padding: '0.5rem 0.5rem',
                backgroundColor: '#35D2BB',
                whiteSpace: 'nowrap',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                justifyContent: 'center',
              }}
              onClick={() => {
                modifyTaskPeerReviewStatus(2);
                setPeerReviewStatus(2);
              }}
            >
              시작하기
            </Button>
            <Button
              style={{
                display: peerReviewStatus == 2 ? 'block' : 'none',
                color: '#EBFBF9',
                fontSize: '0.875rem',
                border: '1.5px solid #35D2BB',
                borderRadius: '1.925rem',
                marginLeft: '5rem',
                marginBottom: '0.5rem',
                width: '6rem',
                padding: '0.5rem 0.5rem',
                backgroundColor: '#35D2BB',
                whiteSpace: 'nowrap',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                justifyContent: 'center',
              }}
              onClick={() => {
                modifyTaskPeerReviewStatus(3);
                setPeerReviewStatus(3);
              }}
            >
              중단하기
            </Button>
            <Button
              style={{
                display: [3, 4].includes(peerReviewStatus) ? 'block' : 'none',
                color: '#EBFBF9',
                fontSize: '0.875rem',
                border: '0.5px solid #35D2BB',
                borderRadius: '1.925rem',
                marginLeft: '4rem',
                marginBottom: '0.5rem',
                width: '8rem',
                padding: '0.5rem 0.5rem',
                backgroundColor: '#35D2BB',
                whiteSpace: 'nowrap',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                justifyContent: 'center',
              }}
              onClick={() => {
                modifyTaskPeerReviewStatus(2);
                setPeerReviewStatus(2);
                setPeerEvalEndTime(null);
              }}
            >
              다시 시작하기
            </Button>
            <Button
              style={{
                display: [2, 3].includes(peerReviewStatus) ? 'block' : 'none',
                color: '#EBFBF9',
                fontSize: '0.875rem',
                border: '1.5px solid #35D2BB',
                borderRadius: '1.925rem',
                marginLeft: '1rem',
                marginBottom: '0.5rem',
                width: '6rem',
                padding: '0.5rem 0.5rem',
                backgroundColor: '#35D2BB',
                whiteSpace: 'nowrap',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                justifyContent: 'center',
              }}
              onClick={() => {
                modifyTaskPeerReviewStatus(4);
                setPeerReviewStatus(4);
                setPeerEvalEndTime(new Date());
                setPeerEvalTimeReserved(true);
              }}
            >
              종료하기
            </Button>
            <Button
              style={{
                display: [4].includes(peerReviewStatus) ? 'block' : 'none',
                color: '#EBFBF9',
                fontSize: '0.875rem',
                border: '1.5px solid #35D2BB',
                borderRadius: '1.925rem',
                marginLeft: '1rem',
                marginBottom: '0.5rem',
                width: '6rem',
                padding: '0.5rem 0.5rem',
                backgroundColor: '#35D2BB',
                whiteSpace: 'nowrap',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                justifyContent: 'center',
              }}
              onClick={() => {
                modifyTaskPeerReviewStatus(5);
                setPeerReviewStatus(5);
              }}
            >
              공유하기
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            marginLeft: '1rem',
            marginRight: '1rem',
            whiteSpace: 'pre-line',
            backgroundColor: '#EBFBF9',
            fontSize: '0.875rem',
            padding: '1rem',
            borderRadius: '6px',
            lineHeight: '1.5',
            marginBottom: '1rem',
          }}
        >
          {peerEvalTeacherMsg}
        </Box>
      </>
    );
  }
  function downloadExcel() {
    const excelCreateApi = async () => {
      try {
        const response = await APIUtils.CreateTaskExcel(props.taskID);

        return response;
      } catch (err) {
        console.log(err);
      }
    };
    excelCreateApi().then(res => {
      if (res.status != 200) return;
      const now = new Date();
      const formatTwo = n => n.toString().padStart(2, '0');

      const year = now.getFullYear().toString().slice(2); // 연도 2자리
      const month = formatTwo(now.getMonth() + 1);
      const date = formatTwo(now.getDate());
      const hour = formatTwo(now.getHours());
      const minute = formatTwo(now.getMinutes());

      const timestamp = `${year}${month}${date}_${hour}_${minute}`;
      const finalTaskName = `${taskName}_${timestamp}` + '_진행중';
      DownloadExcelFile(res.data, finalTaskName);
    });
  }
  function showExcelButton() {
    return (
      <Button
        style={{
          whiteSpace: 'nowrap',
          color: 'white',
          fontSize: '0.875rem',
          border: '1px solid #4FD7C3',
          borderRadius: '0.625rem',
          fontWeight: 'bold',
          backgroundColor: '#4FD7C3',
          padding: '0.5rem 0.5rem',
        }}
        onClick={() => {
          downloadExcel();
        }}
      >
        <img
          src={iconDataWhite}
          alt={'iconData'}
          style={{height: '1rem', marginRight: '0.2rem'}}
        />
        엑셀 다운로드
      </Button>
    );
  }
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <TaskUnsubmitPopup
        visibility={taskUnsubmitVisibility}
        cancelEvent={taskUnsubmitCancel}
        taskID={props.taskID}
        titleText={taskName}
        startTime={startTime}
        endTime={endTime}
      />
      <TaskSubmitPopup
        visibility={taskSubmitVisibility}
        cancelEvent={taskSubmitCancel}
        studentList={submitList
          .map(val => val.student_name)
          .sort((a, b) => {
            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 0;
          })}
        titleText={taskName}
        startTime={startTime}
        endTime={endTime}
      />
      <TaskPeerReviewUnsubmitPopup
        visibility={taskPeerReviewUnsubmitVisibility}
        cancelEvent={taskPeerReviewUnsubmitCancel}
        taskID={props.taskID}
        titleText={taskName}
        startTime={peerEvalStartTime}
        endTime={peerEvalEndTime}
        timeReserved={peerEvalTimeReserved == 0 ? false : true}
      />
      <TaskPeerReviewSubmitPopup
        visibility={taskPeerReviewSubmitVisibility}
        cancelEvent={taskPeerReviewSubmitCancel}
        studentList={evalSubmitStudentList
          .map(val => val.student_name)
          .sort((a, b) => {
            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 0;
          })}
        titleText={taskName}
        startTime={peerEvalStartTime}
        endTime={peerEvalEndTime}
        timeReserved={peerEvalTimeReserved == 0 ? false : true}
      />
      <TaskModifyPopup
        visibility={taskModifyVisibility}
        task_id={props.taskID}
        startEditable={false}
        cancelEvent={taskModifyCancel}
        confirmEvent={taskModifyConfirm}
      />
      <TaskEndPopup
        visibility={taskEndVisibility}
        cancelEvent={taskEndCancel}
        confirmEvent={taskEndConfirm}
      />{' '}
      <ToastPopup text={toastText} visibility={toastVisibility} />
      <Box
        style={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <Box
            style={{
              fontWeight: 'bold',
              fontSize: '1.375rem',
              width: '100%',
              display: 'flex',
              gap: '0.7rem',
              whiteSpace: 'nowrap',
            }}
          >
            {taskName} <Box style={{flex: 1}}></Box>
            <ClipboardCopy
              text={'과제 링크 복사'}
              link={
                process.env.REACT_APP_LEARN_BASE_URI +
                '/redirect/task/' +
                props.taskID
              }
              resultEvent={handleCopyResult}
              buttonType={1}
              style={{
                whiteSpace: 'nowrap',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: '#ff9900',
                backgroundColor: 'white',
                borderRadius: '0.625rem',
                padding: '0rem 1rem',
                height: '3rem',
                border: '1px solid var(--01-gray-06, #ff9900)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            />
            <Button
              style={{
                whiteSpace: 'nowrap',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: '#ff9900',
                backgroundColor: 'white',
                borderRadius: '0.625rem',
                padding: '0rem 1rem',
                height: '3rem',
                border: '1px solid var(--01-gray-06, #ff9900)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                setTaskModifyVisibility(true);
              }}
            >
              과제 내용 변경
            </Button>
            <Button
              style={{
                whiteSpace: 'nowrap',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#49545C',
                borderRadius: '0.625rem',
                padding: '0rem 1rem',
                height: '3rem',
                border: '1px solid var(--01-gray-06, #898989)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                setTaskEndVisibility(true);
              }}
            >
              과제 바로 종료
            </Button>
          </Box>
          <Box
            style={{
              color: '#68737D',
              fontSize: '0.875rem',
              marginTop: '0.5rem',
            }}
          >
            {StringUtils.getDateTimeKr(startTime) +
              ' ~ ' +
              StringUtils.getDateTimeKr(endTime)}{' '}
            {tz && tz !== 'Asia/Seoul'
              ? ' (' + tz.split('/')[1].replace('_', ' ') + ') '
              : ''}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.3rem',
        }}
      />
      <Box
        style={{
          width: '100%',
          display: 'flex',
        }}
      >
        <Button
          style={{
            color: '#68737D',
            fontSize: '0.875rem',
            border: '1px solid var(--gray-2, #E6E8EB)',
            borderRadius: '0.625rem',
            marginTop: '-0.5rem',
            marginBottom: '0.5rem',
            width: '10rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            handleInfoShow();
          }}
        >
          {infoPart == 'open' ? '리스트 보기' : '전체 보기'}
        </Button>
      </Box>
      <Box
        style={{
          display: infoPart == 'open' ? 'block' : 'none',
          border: '2px solid #35d2bb',
          marginBottom: '1rem',
          padding: '1rem',
          borderRadius: '0.625rem',
          fontSize: '0.875rem',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: '#4dc8b6',
            fontSize: '1.2rem',
            marginBottom: '2rem',
          }}
        >
          {'[과제 내용]'}
        </Box>
        <Box
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: '0.875rem',
          }}
        >
          <Linkify
            options={{
              target: '-blank',
            }}
          >
            <TaskMessage message={memoText} />
          </Linkify>
        </Box>
        <Box
          style={{
            display: 'block',
            marginLeft: '0rem',
            marginTop: '1.2rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          <Box
            style={{
              display: textCountChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 분량 : ' +
              textCountMin +
              ' 자 ~ ' +
              textCountMax +
              ' 자'}
          </Box>
          <Box
            style={{
              display: requiredWordChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 키워드 : ' + requiredWord.replace(',', ', ')}
          </Box>
          <Box>
            {'\u2022 최대 수정 횟수 : ' +
              (maxTryCount === 0 ? '무제한' : maxTryCount + '회')}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: infoPart == 'open' ? 'flex' : 'none',
          padding: '2rem',
          border: '1px solid #D8DCDE',
          borderRadius: '1.25rem',
          maxHeight: '6rem',
          minHeight: '2rem',
          overflowY: 'auto',
          gap: '1rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row', // 전체를 가로 정렬
            alignItems: 'center',
            gap: '3rem', // 텍스트와 버튼 간 간격
          }}
        >
          <Box>
            {/* 전체 제출률 텍스트 */}
            <Box style={{whiteSpace: 'nowrap', fontWeight: '500'}}>
              제출률 : {submitRate + ' %'} ( {submitStudentCount} /{' '}
              {taksStudentCount} )
            </Box>

            <Box
              style={{
                display: peerReviewStatus > 1 ? 'block' : 'none',
                whiteSpace: 'nowrap',
                fontWeight: '500',
                marginTop: '0.5rem',
              }}
            >
              참여율 : {evalSubmitRate + ' %'} ( {evalSubmitStudentCount} /{' '}
              {taksStudentCount} )
            </Box>
          </Box>
          <Box>
            {/* 버튼 그룹 */}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row', // 버튼 가로 정렬
                gap: '1rem', // 버튼 간 간격
              }}
            >
              <Button
                style={{
                  border: '1px solid #D8DCDE',
                  borderRadius: '0.625rem',
                  color: '#49545C',
                  whiteSpace: 'nowrap',
                  fontSize: '0.875rem',
                }}
                onClick={() => setTaskUnsubmitVisibility(true)}
              >
                <img
                  src={iconUnsubmit}
                  alt="iconUnsubmit"
                  style={{marginRight: '0.5rem'}}
                />
                미제출자
              </Button>
              <Button
                style={{
                  border: '1px solid #D8DCDE',
                  borderRadius: '0.625rem',
                  color: '#49545C',
                  whiteSpace: 'nowrap',
                  fontSize: '0.875rem',
                }}
                onClick={() => setTaskSubmitVisibility(true)}
              >
                <img
                  src={iconUnsubmit}
                  alt="iconUnsubmit"
                  style={{marginRight: '0.5rem'}}
                />
                제출자
              </Button>
            </Box>
            <Box
              style={{
                display: peerReviewStatus != 0 ? 'flex' : 'none',
                flexDirection: 'row', // 버튼 가로 정렬
                gap: '1rem', // 버튼 간 간격
                marginTop: '0.5rem',
              }}
            >
              <Button
                style={{
                  border: '1px solid #D8DCDE',
                  borderRadius: '0.625rem',
                  color: '#49545C',
                  whiteSpace: 'nowrap',
                  fontSize: '0.875rem',
                }}
                onClick={() => setTaskPeerReviewUnsubmitVisibility(true)}
              >
                <img
                  src={iconUnsubmit}
                  alt="iconUnsubmit"
                  style={{marginRight: '0.5rem'}}
                />
                평가 미참여자
              </Button>
              <Button
                style={{
                  border: '1px solid #D8DCDE',
                  borderRadius: '0.625rem',
                  color: '#49545C',
                  whiteSpace: 'nowrap',
                  fontSize: '0.875rem',
                }}
                onClick={() => setTaskPeerReviewSubmitVisibility(true)}
              >
                <img
                  src={iconUnsubmit}
                  alt="iconUnsubmit"
                  style={{marginRight: '0.5rem'}}
                />
                평가 참여자
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          style={{display: infoPart == 'open' ? 'flex' : 'none', width: '100%'}}
        >
          <BarGraph taskClass={taskClass} maxCount={15} />
        </Box>
      </Box>
      {(peerEvalTeacherMsg != '' || peerEvalChecked == 1) &&
        infoPart == 'open' && (
          <Box sx={{justifyContent: 'center'}}>
            <Box
              sx={{
                padding: '2rem',
                border: '1px solid #D8DCDE',
                borderRadius: '1.25rem',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem',
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  alignSelf: 'flex-start',
                  position: 'relative',
                  marginBottom: '1rem',
                }}
              >
                {createOverallComment()}

                {peerReviewStatus >= 1 && (
                  <Box sx={{width: '100%', marginTop: '2rem'}}>
                    <Box
                      style={{
                        display: 'flex',
                        width: '80%',
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: '1rem',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                        }}
                      >
                        평가 항목
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        width: '80%',
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: '1rem',
                          marginTop: '1rem',
                          fontSize: '0.875rem',
                          color: '#4FD7C3',
                        }}
                      >
                        ※ 동료 평가 예시 화면으로, 평가 방식을 미리 확인할 수
                        있습니다.
                      </Typography>
                    </Box>
                    <PeerEvalComponent
                      peerEvalItems={peerEvalItems}
                      peerEvalMaxScore={peerEvalMaxScore}
                      peerEvalChecked={
                        peerEvalChecked == 0
                          ? false
                          : peerReviewChecked == 1
                          ? true
                          : peerReviewChecked
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      <Box
        style={{
          display:
            infoPart == 'open' &&
            submitStudentCount + evalSubmitStudentCount > 0
              ? 'flex'
              : 'none',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '3rem',
        }}
      >
        <Box
          style={{
            width: evalSubmitStudentCount > 0 ? '50%' : '100%',
            marginTop: '0.5rem',
            padding: '1rem',
            border: '1px solid #D8DCDE',
            borderRadius: '1.25rem',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              height: '2rem',
            }}
          >
            <Box
              style={{color: '#49545C', fontWeight: 'bold', fontSize: '1rem'}}
            >
              전체 평균
              <span
                style={{
                  color: '#35D2BB',
                  marginLeft: '0.5rem',
                  fontWeight: 'bold',
                }}
              >
                {avgScore + '점'}
              </span>
            </Box>
            {showExcelButton()}
          </Box>
          <Box
            style={{
              height: '20rem',
              width: '80%',
              marginLeft: '10%',
              marginTop: '2rem',
            }}
          >
            <BarChart id="선생님평가" barData={barData} labelData={labelData} />
          </Box>
        </Box>

        <Box
          style={{
            display: evalSubmitStudentCount > 0 ? 'block' : 'none',
            width: '50%',
            marginTop: '0.5rem',
            padding: '1rem',
            border: '1px solid #D8DCDE',
            borderRadius: '1.25rem',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              height: '2rem',
            }}
          >
            <Box
              style={{color: '#49545C', fontWeight: 'bold', fontSize: '1rem'}}
            >
              동료 평가 평균
              <span
                style={{
                  color: '#279EFF',
                  marginLeft: '0.5rem',
                  fontWeight: 'bold',
                }}
              >
                {evalAvgScore + '점'}
              </span>
            </Box>
          </Box>
          <Box
            style={{
              height: '20rem',
              width: '80%',
              marginLeft: '10%',
              marginTop: '2rem',
            }}
          >
            <BarChart id="동료평가" barData={bar2Data} labelData={label2Data} />
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: infoPart == 'open' ? '3rem' : '0rem',
          gap: '1rem',
        }}
      >
        <IconButton
          style={{
            fontSize: '0.875rem',
            fontWeight: 'bold',
          }}
          onClick={() => {
            getTaskResult();
          }}
        >
          새로고침
          <img
            src={IconRefresh}
            style={{
              marginLeft: '0.5rem',
              width: '1rem',
              height: '1rem',
            }}
          />
        </IconButton>
        <Select
          disableUnderline
          // onChange={handleSelectSorting}
          onClick={handleSelectSorting}
          value={selectSorting}
          variant="standard"
          style={{
            border: '1px solid #E9EBED',
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: 'black',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
          }}
        >
          {sortingList.map((value, index) => (
            <MenuItem key={index} value={index}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <Select
          disableUnderline
          onChange={handleSelectClass}
          value={selectClass}
          variant="standard"
          style={{
            border: '1px solid #E9EBED',
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: 'black',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
          }}
        >
          {classList.map((value, index) => (
            <MenuItem key={index} value={index}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <Box>
          <TextField
            autoComplete="off"
            size="small"
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid black',
                },
                '&:hover fieldset': {
                  border: '1px solid black',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid black',
                },
              },
            }}
            label={searchText == '' ? '🔍 검색(제목/이름)' : ''}
            InputLabelProps={{
              style: {
                color: 'rgba(108, 108, 108, 0.721)',
                fontSize: 'calc(0.3rem + 0.5vw)',
              },
              shrink: false,
            }}
            InputProps={{style: {fontSize: 'calc(0.3rem + 0.5vw)'}}}
            value={searchText}
            onChange={handleSearchText}
          ></TextField>
        </Box>
      </Box>
      <Box style={{marginTop: '1rem'}}>
        {submitList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <TaskEvalView
                key={'taskEval' + index}
                peerReviewChecked={
                  peerReviewChecked == 0
                    ? false
                    : peerReviewChecked == 1
                    ? true
                    : peerReviewChecked
                }
                allTasks={submitList}
                submitTask={value}
                endTime={endTime.toString()}
                viewEvent={clickResult}
                resultEvent={clickResult}
                task_id={props.taskID}
              />
            );
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};
FragmentView.propTypes = {
  taskID: PropTypes.number,
  previousPageEvent: PropTypes.func,
  taskEndEvent: PropTypes.func,
};
export default FragmentView;
